import {
  Grid,
  Box,
  TextField,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { toTitleCase } from "../../utils/utils";
import BasicDatePicker from "../Forms/Elements/datePicker";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

function CategoriesPreview() {
  var myHeaders = new Headers({ authorization: process.env.REACT_APP_API_KEY });
  myHeaders.append("Content-Type", "application/json");
  //   const date = props.date;
  const currDate = new Date();
  let currDateIso = currDate.toISOString().slice(0, 10);

  const [alert, setAlert] = useState({
    open: false,
    message: "ola",
    serverity: "success",
  });
  const handleAlertClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const [date, setDate] = useState({
    Timing: new Date().toISOString().slice(0,10),
  });

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/power_consumption/Power_ConsumptionLastUpdDate`,
      {
        method: "POST",
        body: JSON.stringify({
          date: currDateIso,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log("last update date");
        let value = data.response[0].previous_last_updated_date + "";
        // setLastUpdDate(value);
        // console.log(data.response[0].previous_last_updated_date);
        setDate({
          ...data,
          Timing: value,
        });
      })
      .catch((err) => {
        setAlert({
          open: true,
          serverity: "error",
          message: "Error fetching data",
        });
        // console.log(err);
      });
  }, []);

  let dataObject = {
    TIME: "00:00:00",
    PF: 0,
    KWH: 0,
    KVAH: 0,
    R_voltage_Rv: 0,
    R_voltage_RA: 0,
    Y_voltage_yv: 0,
    Y_voltage_yA: 0,
    B_voltage_BV: 0,
    B_voltage_BA: 0,
    KVAH_Day: 0,
    KVAH_Night: 0,
    Remarks: 0,
    Signature: 0,
  };
  const [data, setdata] = useState(dataObject);
  const DateChange = (e) => {
    setDate({
      ...date,
      Timing: new Date(e).toISOString(),
    });
  };
  // fetching categories data
  const fetchFilling = () => {
    // myHeaders.append("Content-Type", "application/json");
    var options = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        date: date.Timing.slice(0,10),
      }),
    };
    // console.log(date);
    // console.log(date.toISOString().slice(0,10))
    fetch(`${process.env.REACT_APP_BASE_API_URL}/power_consumption`, options)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.response[0]) {
          setdata(result.response);
        } else {
          setAlert({
            open: true,
            message: "no data found",
            serverity: "error",
          });
          setdata(dataObject);
        }
        // console.log(result.response[0],category)
      })
      .catch((error) => {
        setAlert({
          open: true,
          message: "Error while fetching data",
          serverity: "error",
        });
        // console.log("error", error);
      });
  };
  useEffect(fetchFilling, [date]);
  // fetchFilling();
  return (
    <>
      <Box sx={{ py: 2, px: 2 }}>
        <Grid container sx={{ py: 3 }}>
          <Grid>
            <BasicDatePicker
              onChange={DateChange}
              label="Date"
              renderInput={(params) => <TextField {...params} />}
              value={date.Timing}
            />
          </Grid>
        </Grid>

        {data.length
          ? data.map((item) => {
              return (
                <TableContainer component={Paper} sx={{ px: 2, py: 2, my: 4 }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                      <>
                        <TableRow
                          key="TIME"
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            TIME
                          </TableCell>
                          <TableCell align="right">{item.TIME}</TableCell>
                        </TableRow>
                        ​{" "}
                        <TableRow
                          key="PF"
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            PF
                          </TableCell>
                          <TableCell align="right">{item.PF}</TableCell>
                        </TableRow>
                        ​{" "}
                        <TableRow
                          key="KWH"
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            KWH
                          </TableCell>
                          <TableCell align="right">{item.KWH}</TableCell>
                        </TableRow>
                        ​{" "}
                        <TableRow
                          key="KVAH"
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            KVAH
                          </TableCell>
                          <TableCell align="right">{item.KVAH}</TableCell>
                        </TableRow>
                        ​{" "}
                        <TableRow
                          key="R_voltage_Rv"
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            R_voltage_ Rv
                          </TableCell>
                          <TableCell align="right">
                            {item.R_voltage_Rv}
                          </TableCell>
                        </TableRow>
                        ​{" "}
                        <TableRow
                          key="R_voltage_RA"
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            R_voltage_ R A
                          </TableCell>
                          <TableCell align="right">
                            {item.R_voltage_RA}
                          </TableCell>
                        </TableRow>
                        ​{" "}
                        <TableRow
                          key="Y_voltage_yv"
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Y_voltage_yv
                          </TableCell>
                          <TableCell align="right">
                            {item.Y_voltage_yv}
                          </TableCell>
                        </TableRow>
                        ​{" "}
                        <TableRow
                          key="Y_voltage_yA"
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Y_voltage_y A
                          </TableCell>
                          <TableCell align="right">
                            {item.Y_voltage_yA}
                          </TableCell>
                        </TableRow>
                        ​{" "}
                        <TableRow
                          key="B_voltage_BV"
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            B_voltage_ B V
                          </TableCell>
                          <TableCell align="right">
                            {item.B_voltage_BV}
                          </TableCell>
                        </TableRow>
                        ​{" "}
                        <TableRow
                          key="B_voltage_BA"
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            B_voltage_ B A
                          </TableCell>
                          <TableCell align="right">
                            {item.B_voltage_BA}
                          </TableCell>
                        </TableRow>
                        ​{" "}
                        <TableRow
                          key="KVAH_Day"
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            K V A H_ Day
                          </TableCell>
                          <TableCell align="right">{item.KVAH_Day}</TableCell>
                        </TableRow>
                        ​{" "}
                        <TableRow
                          key="KVAH_Night"
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            K V A H_ Night
                          </TableCell>
                          <TableCell align="right">{item.KVAH_Night}</TableCell>
                        </TableRow>
                        ​{" "}
                        <TableRow
                          key="Remarks"
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Remarks
                          </TableCell>
                          <TableCell align="right">{item.Remarks}</TableCell>
                        </TableRow>
                        ​{" "}
                        <TableRow
                          key="Signature"
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Signature
                          </TableCell>
                          <TableCell align="right">{item.Signature}</TableCell>
                        </TableRow>
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
              );
            })
          : ""}
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={alert.serverity}
            sx={{ width: "100%" }}
            variant="filled"
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
}

export default CategoriesPreview;
