import React, { useState } from "react";
import { Button, Grid, TextField, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import "../styling/LoginNavbar.css";
import CategoriesGph from "./GraphComponent/CategoriesGph";
import logo from "../Images/logo.png";
import ConsumablesGph from "./GraphComponent/ConsumablesGph";
import OthersGph from "./GraphComponent/OthersGph";
import HomeDashboard from "./GraphComponent/HomeDashboard";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import BasicDatePicker from "./Forms/Elements/datePicker";
import { useNavigate } from "react-router-dom";
import PreviewPage from "./PreviewComponents/Preview";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { isAdmin, userEmail } from "../utils/utils";
import profileDummy from "../Images/dummy-profile.png";

function DashBoard() {
  const [categories, setcategories] = useState(false);
  const [Home, setHome] = useState(true);
  const [DateWise, setDateWise] = useState(false);
  const [consumables, setconsumables] = useState(false);
  const [Others, setOthers] = useState(false);
  const [preview, setPreview] = useState(false);
  const [downloadDailog, setDownloadDailog] = useState(false);
  const [date, setDate] = useState({
    Timing: "2022-05-25T00:00:00.000Z",
  });
  const [downloadLink, setDownloadLink] = useState(null);
  const [linkLoading, setLinkLoading] = useState(false);
  const [sideBarValues, setSidebarValues] = useState({
    firstTruckIn: "00:00:00",
    lastTruckOut: "00:00:00",
  });

  // mobile rendering stated below
  const [mblHome, setmblHome] = useState(true);
  const [mblProduction, setmblProduction] = useState(false);
  const [mblConsumables, setmblConsumables] = useState(false);
  const [mblOthers, setmblOthers] = useState(false);
  const [mblPreview, setmblPreview] = useState(false);

  const [dateError, setDateError] = useState(null);
  //get truck timings
  var myHeaders = new Headers({ authorization: process.env.REACT_APP_API_KEY });
  myHeaders.append("Content-Type", "application/json");
  const navigate = useNavigate();
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_API_URL}/bulk/lastDate`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        result = result.results;
        setSidebarValues({
          firstTruckIn: result.firstPackedTruckOut,
          lastTruckOut: result.lastPackedTruckOut,
        });
      })
      .catch((error) =>{ /*console.log("error", error)*/});
  }, []);
  const hadleDownloadDailogOpen = () => {
    setDownloadDailog(true);
    setLinkLoading(false);
    setDownloadLink(false);
  };

  const handleDownloadDailogClose = () => {
    setDownloadDailog(false);
  };
  const handleDateChange = (e) => {
    setDate(e);
    setLinkLoading(true);
    setDownloadLink(null);
    // console.log(e.toISOString().slice(0, 10));
    // fetch download link
    var myHeaders = new Headers({
      authorization: process.env.REACT_APP_API_KEY,
    });
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      date: e.toISOString().slice(0, 10),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_API_URL}/download`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.file) {
          setDownloadLink(result.file.Location);
          setLinkLoading(false);
          setDateError(null);
        } else {
          setDateError("An error occured. Try again");
          setLinkLoading(false);
        }
      })
      .catch((error) => {
        setDateError("An error occured. Try again");
        // console.log("error", error);
      });
  };
  const Showcategories = (e) => {
    e.preventDefault();
    setcategories(true);
    setHome(false);
    setconsumables(false);
    setOthers(false);
    setPreview(false);
  };
  const Showconsumable = (e) => {
    e.preventDefault();
    setcategories(false);
    setHome(false);
    setconsumables(true);
    setDateWise(false);
    setOthers(false);
    setPreview(false);
  };
  const showPreivew = (e) => {
    e.preventDefault();
    setcategories(false);
    setHome(false);
    setconsumables(false);
    setDateWise(false);
    setOthers(false);
    setPreview(true);
  };
  const logout = () => {
    // console.log(result);

    var result = window.confirm("Are you Sure You wanna logout !");
    if (result === true) {
      localStorage.clear();
      navigate("/login");
    } else {
      // console.log("cancel was pressed");
    }
  };
  const ShowOthers = (e) => {
    e.preventDefault();
    setcategories(false);
    setconsumables(false);
    setHome(false);
    setOthers(true);
    setPreview(false);
  };
  const ShowHome = (e) => {
    e.preventDefault();
    setHome(true);
    setcategories(false);
    setconsumables(false);
    setOthers(false);
    setPreview(false);
  };
  const MobileHome = (e) => {
    setmblHome(true);
    setmblConsumables(false);
    setmblProduction(false);
    setmblOthers(false);
    setmblPreview(false);
    // closing side navbar on clicking on the element ..
    document.querySelector("#menuToggle input").checked = false;
  };
  const MobileProduction = (e) => {
    setmblHome(false);
    setmblConsumables(false);
    setmblProduction(true);
    setmblOthers(false);
    setmblPreview(false);
    // closing side navbar on clicking on the element ..
    document.querySelector("#menuToggle input").checked = false;
  };
  const MobileConsumables = (e) => {
    setmblHome(false);
    setmblConsumables(true);
    setmblProduction(false);
    setmblOthers(false);
    setmblPreview(false);
    // closing side navbar on clicking on the element ..
    document.querySelector("#menuToggle input").checked = false;
  };
  const MobileOthers = (e) => {
    setmblHome(false);
    setmblConsumables(false);
    setmblProduction(false);
    setmblOthers(true);
    setmblPreview(false);
    // closing side navbar on clicking on the element ..
    document.querySelector("#menuToggle input").checked = false;
  };
  const MobilePreview = (e) => {
    setmblHome(false);
    setmblConsumables(false);
    setmblProduction(false);
    setmblOthers(false);
    setmblPreview(true);
    // closing side navbar on clicking on the element ..
    document.querySelector("#menuToggle input").checked = false;
  };

  // code for closing side nav bar on clicking anywhere
  document.addEventListener("click", function (event) {
    var ignoreElement = document.querySelector("#menuToggle");
    // console.log(ignoreElement)
    if (ignoreElement !== null) {
      var clickElement = ignoreElement.contains(event.target);
      if (!clickElement) {
        document.querySelector("#menuToggle input").checked = false;
      }
    }
  });

  const DateChange = (e) => {
    // setDate(e);
    setDate({
      ...date,
      Timing: new Date(e).toISOString(),
    });
    setLinkLoading(true);
    setDownloadLink(null);
    // console.log(e.toISOString().slice(0, 10));
    // fetch download link
    var myHeaders = new Headers({
      authorization: process.env.REACT_APP_API_KEY,
    });
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      date: e.toISOString().slice(0, 10),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_API_URL}/download`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (!result.file) {
          setAlert({
            open: true,
            serverity: "error",
            message: "sheet not available",
          });
          setDateError("An error occured. Try again");
          setLinkLoading(false);
        } else {
          setDownloadLink(result.file.Location);
          setLinkLoading(false);
          setDateError(null);
        }

        // if (result.file) {
        //   setDownloadLink(result.file.Location);
        //   setLinkLoading(false);
        //   setDateError(null);
        // } else {
        //   setDateError("An error occured. Try again");
        //   setLinkLoading(false);
        // }
      })
      .catch((error) => {
        setDateError("An error occured. Try again");
        // console.log("error", error);
      });
  };
  const [alert, setAlert] = useState({
    open: false,
    message: "ola",
    serverity: "success",
  });

  useEffect(() => {
    // DateChange();
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/filling/ProductionLastUpdDate`,
      {
        method: "POST",
        body: JSON.stringify({
          date: `${currDateIso}T00:00:00.000Z`,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        let value = data.response[0].previous_last_updated_date + "";
        // console.log(data.response[0].previous_last_updated_date);
        setDate({
          ...data,
          Timing: value,
        });
      })
      .catch((err) => {
        setAlert({
          open: true,
          serverity: "error",
          message: "Error fetching last upadted date",
        });
        // console.log(err);
      });
  }, []);

  const [tankertansist, setTankertansist] = useState(0);
  const [tankerplant, settankerplant] = useState(0);
  const [consumedValue, setConsumedValue] = useState(0);
  const [deiselUsed, setdeiselUsed] = useState(0);

  const currDate = new Date();
  let currDateIso = currDate.toISOString().slice(0, 10);

  const dayBefore = new Date(currDate.getTime() - 24 * 60 * 60 * 1000);
  const beforeDateStr = dayBefore.toISOString().slice(0, 10);

  // console.log(`${beforeDateStr}T00:00:00.000Z`);

  const [data, setData] = useState({
    Timing: `${beforeDateStr}T00:00:00.000Z`,
  });

  // set date

  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_BASE_API_URL}/bulk/TankersLastUpdDate`, {
  //     method: "POST",
  //     body: JSON.stringify({
  //       date: `${currDateIso}T00:00:00.000Z`,
  //     }),
  //     headers: myHeaders,
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       // console.log("last update date");
  //       let value = data.response[0].previous_last_updated_date + "";
  //       // setLastUpdDate(value);
  //       // console.log(data.response[0].previous_last_updated_date);
  //       setData({
  //         ...data,
  //         Timing: value,
  //       });
  //     })
  //     .catch((err) => {
  //       window.alert("error fetching in  last update date");
  //       console.log(err);
  //     });
  // }, []);

  const FetchValues = async (e) => {
    e.preventDefault();

    // const lastTankersUpdateDate = await fetch(
    //   `${process.env.REACT_APP_BASE_API_URL}/bulk/TankersLastUpdDate`,
    //   {
    //     method: "POST",
    //     body: JSON.stringify({
    //       date: data.Timing,
    //     }),
    //     headers: myHeaders,
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((data) => {
    //     console.log("last update data : ", data);
    //   })
    //   .catch((err) => {
    //     window.alert("error fetching in  last update date");
    //     console.log(err);
    //   });

    await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/bulk/tankersAndTransist`,
      {
        method: "POST",
        body: JSON.stringify({
          date: data.Timing,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.response[0]) {
          let response = data.response[0];
          let tankerTransist = response.tankersInTransit;
          let tankerPlant = response.tankersWaitingAtPlant;
          setTankertansist(tankerTransist);
          settankerplant(tankerPlant);
        } else {
          setAlert({
            open: true,
            serverity: "warning",
            message: "Unable to fetch data",
          });
        }
      })
      .catch((err) => {
        setAlert({
          open: true,
          serverity: "error",
          message: "Error while fetching data",
        });
      });
    await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/power_consumption/consumedValue`,
      {
        method: "POST",
        body: JSON.stringify({
          date: data.Timing,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.response[0]) {
          // console.log(data.response[0]);
          let Value = Object.values(data.response[0]);
          setConsumedValue(Value);
        } else {
          setAlert({
            open: true,
            serverity: "warninig",
            message: "Unable to fetch data",
          });
        }
        return data;
      })
      .catch((err) => {
        setAlert({
          open: true,
          serverity: "error",
          message: "Error while fetching data",
        });
      });

    await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/battery_diesel/dieselUsed`,
      {
        method: "POST",
        body: JSON.stringify({
          date: data.Timing,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.response[0]) {
          // console.log(data.response[0]);
          let Value = Object.values(data.response[0]);
          // console.log(Value);
          setdeiselUsed(Value);
        } else {
          setAlert({
            open: true,
            serverity: "warning",
            message: "Unable to fetch data",
          });
        }
        return data;
      })
      .catch((err) => {
        setAlert({
          open: true,
          serverity: "error",
          message: "Error while fetching data",
        });
      });
    // console.log(response);
  };

  return (
    <>
      <section className="DashboardNavbar">
        <div className="HomeNavbar">
          <div className="profile">
            <p>
              <img src={profileDummy} alt="" />
              {userEmail()}
            </p>
          </div>
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <div className="logOutBtn">
            <Button
              color="primary"
              onClick={logout}
              startIcon={<PowerSettingsNewIcon />}
              title="Log Out"
            ></Button>
          </div>
        </div>
      </section>

      <div className="dashboard">
        <section className="ComponentContainer">
          <div className="selectionbox">
            <div
              className={"Hover " + (Home ? "active" : "")}
              onClick={ShowHome}
            >
              <h1>Home</h1>
            </div>
            <div
              className={"Hover " + (categories ? "active" : "")}
              onClick={Showcategories}
            >
              <h1>Production</h1>
            </div>
            <div
              className={"Hover " + (consumables ? "active" : "")}
              onClick={Showconsumable}
            >
              <h1>Consumables</h1>
            </div>

            <div
              className={"Hover " + (Others ? "active" : "")}
              style={{ borderBottom: "none" }}
              onClick={ShowOthers}
            >
              <h1>Others</h1>
            </div>
            <div
              className={"Hover " + (preview ? "active" : "")}
              style={{ borderBottom: "none" }}
              onClick={showPreivew}
            >
              <h1>Preview</h1>
            </div>
            <Grid container>
              <div
                className="formDownload"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <section>
                  {isAdmin() && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => window.open("/forms", "_blank")}
                      sx={{ mr: 2 }}
                      className="formBTN"
                    >
                      Forms
                    </Button>
                  )}
                </section>

                <section>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={hadleDownloadDailogOpen}
                    startIcon={<CloudDownloadIcon />}
                    sx={{ mr: 2 }}
                  >
                    Download
                  </Button>
                </section>
              </div>
              <Dialog
                open={downloadDailog}
                onClose={handleDownloadDailogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  Download WA Sheet
                </DialogTitle>
                <DialogContent>
                  <Box mt={3}>
                    <Grid
                      container
                      spacing={3}
                      alignItems="center"
                      flexDirection="column"
                    >
                      <Grid item xs={12} sm={6}>
                        <BasicDatePicker
                          onChange={DateChange}
                          label="Date"
                          renderInput={(params) => <TextField {...params} />}
                          value={date.Timing}
                        />
                      </Grid>
                      <Grid item alignItems="center">
                        {downloadLink && (
                          <Button
                            href={downloadLink}
                            variant="outlined"
                            startIcon={<CloudDownloadIcon />}
                          >
                            Download
                          </Button>
                        )}

                        {linkLoading && <CircularProgress />}
                        {dateError ? (
                          <span className="error">{dateError}</span>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </DialogContent>
                <DialogActions></DialogActions>
              </Dialog>
            </Grid>
            <div className="MainEle"></div>
          </div>

          <div className="components">
            <div> {Home && <HomeDashboard />}</div>
            <div> {categories && <CategoriesGph />}</div>
            <div> {consumables && <ConsumablesGph />}</div>
            <div> {Others && <OthersGph />}</div>
            <div> {preview && <PreviewPage />} </div>
          </div>
        </section>
      </div>

      <main className="mobileView">
        <header>
          <nav>
            <div id="menuToggle">
              <input type="checkbox" />
              <span></span>
              <span></span>
              <span></span>
              <ul id="menu">
                <a href="#">
                  <li onClick={MobileHome}>Home</li>
                </a>
                <Link to="#" duration={500} offset={-70}>
                  <li onClick={MobileProduction}>Production</li>
                </Link>
                <a href="#">
                  <li onClick={MobileConsumables}>Consumables</li>
                </a>
                <a href="#">
                  <li onClick={MobileOthers}>Others</li>
                </a>
                <a href="#">
                  <li onClick={MobilePreview}>Preview</li>
                </a>
                <div className="downloadBtn">
                  {isAdmin() && (
                    <button onClick={() => window.open("/forms", "_blank")}>
                      Forms
                    </button>
                  )}
                  <button onClick={hadleDownloadDailogOpen}>Download</button>
                  {/* <div className="profile">
                    <p>
                      <img src={profileDummy} alt="" />
                      {userEmail()}
                    </p>
                  </div> */}
                </div>
              </ul>
            </div>
            <div className="logo-title">
              <div className="logo-mobile">
                <div
                  style={{
                    display: "flex",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <img src={logo} alt="not found" onClick={MobileHome} />
                  <h2 className="title">
                    {/* <a onClick={MobileHome}>Energon</a> */}
                  </h2>
                </div>

                <div className="logOutBtn">
                  <Button
                    color="primary"
                    onClick={logout}
                    startIcon={<PowerSettingsNewIcon />}
                    title="Log Out"
                  ></Button>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <div className="container">
          {/* Home */}
          {mblHome && (
            <>
              <section id="Home">
                <div className="mobileContainer">
                  <section className="insights">
                    <BasicDatePicker
                      onChange={handleDateChange}
                      label="Timing"
                      renderInput={(params) => <TextField {...params} />}
                      value={data.Timing}
                    />
                    <Button
                      className="GoBTN"
                      sx={{ ml: 2 }}
                      color="primary"
                      variant="contained"
                      onClick={FetchValues}
                    >
                      Go
                    </Button>
                  </section>
                  <header className="DashHeading">
                    <div>
                      <h1>
                        Welcome To <span>LPG Bottling</span> DashBoard{" "}
                      </h1>
                    </div>
                  </header>

                  <section className="TankersData">
                    <section className="CardHolder">
                      <div className="DashCards">
                        <h1>Tankers In Transit</h1>
                        <div className="Value">
                          <p>
                            <CheckCircleOutlinedIcon className="icon" />
                          </p>{" "}
                          <h1>{tankertansist}</h1>
                          {/* <h1>92</h1> */}
                        </div>
                      </div>
                      <div className="DashCards">
                        <h1>Tankers In Plant</h1>
                        <div className="Value">
                          <p>
                            <CheckCircleOutlinedIcon className="icon" />
                          </p>{" "}
                          <h1>{tankerplant}</h1>
                        </div>
                      </div>
                    </section>
                    <section className="CardHolder">
                      <div className="DashCards">
                        <h1>Power Consumed</h1>
                        <div className="Value">
                          <p>
                            <CheckCircleOutlinedIcon className="icon" />
                          </p>{" "}
                          <h1>{consumedValue}</h1>
                        </div>
                      </div>
                      <div className="DashCards">
                        <h1>Diesel Used</h1>
                        <div className="Value">
                          <p>
                            <CheckCircleOutlinedIcon className="icon" />
                          </p>{" "}
                          <h1>{deiselUsed}</h1>
                        </div>
                      </div>
                    </section>
                  </section>
                </div>
              </section>
            </>
          )}
          {/* production mobile */}
          {mblProduction && (
            <>
              <section id="Production">
                <div className="sectionHeading">
                  <h1>Production</h1>
                </div>
                <div className="mobileContainer">
                  <CategoriesGph />
                </div>
              </section>
            </>
          )}

          {/* consumables mobile */}
          {mblConsumables && (
            <>
              <section id="Consumables">
                <div className="sectionHeading">
                  <h1>Consumables</h1>
                </div>
                <div className="mobileContainer">
                  <ConsumablesGph />
                </div>
              </section>
            </>
          )}

          {/*   others mobile */}
          {mblOthers && (
            <>
              <section id="Others">
                <div className="sectionHeading">
                  <h1>Others</h1>
                </div>
                <div className="mobileContainer">
                  <OthersGph />
                </div>
              </section>
            </>
          )}

          {/* preview mobile */}
          {mblPreview && (
            <>
              <section id="Preview">
                <div className="sectionHeading">
                  <h1>Preview</h1>
                </div>
                <div className="mobileContainer">
                  <PreviewPage />
                </div>
              </section>
            </>
          )}
        </div>
      </main>
    </>
  );
}

export default DashBoard;
