import { IconButton, Stack, TextField } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

export const InputRow = ({
  index,
  item,
  handleChange,
  handleRemove,
  handleAdd,
  values,
  inputFields,
}) => {
  return (
    <Stack
      spacing={1}
      style={{
        alignItems: "left",
        display: "flex",
        justifyContent: "flex-start",

      }}
    >
      {/* <TextField
        name="date"
        // required
        fullWidth
        // label="date"
        type="date"
        onChange={(event) => handleChange(event, index)}
        value={item.title}
      /> */}

      <TextField
        name="Start"
        // required
        fullWidth
        label="startTime"
        type="time"
        onChange={(event) => handleChange(event, index)}
        value={item.startTime}
      />

      {/* <span>End Time</span> */}
      <TextField
        name="End"
        // multiline
        // rows={6}
        fullWidth
        label="endTime"
        type="time"
        onChange={(event) => handleChange(event, index)}
        value={item.endTime}
      />
      <TextField
        name="Description"
        multiline
        rows={4}
        fullWidth
        label="Description"
        type="text"
        onChange={(event) => handleChange(event, index)}
        value={item.Description}
      />
      <TextField
        name="authorityName"
        multiline
        rows={2}
        fullWidth
        label="sign"
        type="text"
        onChange={(event) => handleChange(event, index)}
        value={item.sign}
      />

      <div>
        <IconButton onClick={handleRemove}>
          <RemoveIcon />
        </IconButton>
        <IconButton onClick={handleAdd}>
          <AddIcon />
        </IconButton>
      </div>
    </Stack>
  );
};
