import {
  Grid,
  Box,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { toTitleCase } from "../../utils/utils";
import BasicDatePicker from "../Forms/Elements/datePicker";
import { BrowserRouter } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

function CategoriesPreview() {
  var myHeaders = new Headers({ authorization: process.env.REACT_APP_API_KEY });
  myHeaders.append("Content-Type", "application/json");
  //   const date = props.date;

  const currDate = new Date();
  let currDateIso = currDate.toISOString().slice(0, 10);

  const [alert, setAlert] = useState({
    open: false,
    message: "ola",
    serverity: "success",
  });
  const handleAlertClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const [date, setDate] = useState({
    Timing: new Date().toISOString().slice(0,10),
  });

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/battery_diesel/batteryDeiselLastUpdDate`,
      {
        method: "POST",
        body: JSON.stringify({
          date: currDateIso,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log("last update date");
        let value = data.response[0].previous_last_updated_date + "";
        // setLastUpdDate(value);
        // console.log(data.response[0].previous_last_updated_date);
        setDate({
          ...data,
          Timing: value,
        });
      })
      .catch((err) => {
        setAlert({
          open: true,
          message: "Error while fetching data",
          serverity: "error",
        });
        // console.log(err);
      });
  }, []);

  let dataObject = {
    engineTempC: 0,
    engineTempF: 0,
    engineOilLevel: "min/max",
    rpm: 0,
    frequencyH2: 0,
    coollantOilLevel: "min/max",
    batteryCondition: "OK",
    dieselLevel: "0%",
    engineTimeStart: "00:00AM ",
    engineTimeStop: "00:00PM ",
    outputVoltageRY: 0,
    outputVoltageYB: 0,
    outputVoltageBR: 0,
    runningMins: 0,
    remarks: "",
  };
  const [data, setdata] = useState(dataObject);

  const DateChange = (e) => {
    setDate({
      ...date,
      Timing: new Date(e).toISOString(),
    });
  };
  // fetching categories data
  const fetchFilling = () => {
    // myHeaders.append("Content-Type", "application/json");
    var options = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        date: date.Timing.slice(0,10),
      }),
    };
    fetch(`${process.env.REACT_APP_BASE_API_URL}/battery_diesel`, options)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.response[0]) {
          setdata(result.response);
        } else {
          setAlert({
            open: true,
            message: "no data found",
            serverity: "error",
          });
          setdata(dataObject);
        }
        // console.log(result.response[0],category)
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };
  useEffect(fetchFilling, [date]);
  // fetchFilling();
  return (
    <>
      <Box sx={{ py: 2, px: 2 }}>
        <Grid container sx={{ py: 3 }}>
          <Grid>
            <BasicDatePicker
              onChange={DateChange}
              label="Date"
              renderInput={(params) => (
                <TextField
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  {...params}
                />
              )}
              value={date.Timing}
            />
          </Grid>
        </Grid>
        {data.length
          ? data.map((item) => {
              return (
                <TableContainer component={Paper} sx={{ px: 2, py: 2, mb: 4 }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                      <TableRow
                        key="engineTempC"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Engine Temp C
                        </TableCell>
                        <TableCell align="right">
                          {item.engineTempC}
                          <sup>o </sup> c
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key="engineTempF"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Engine Temp F
                        </TableCell>
                        <TableCell align="right">
                          {item.engineTempF}
                          <sup>o </sup> f
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key="engineOilLevel"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Engine Oil Level
                        </TableCell>
                        <TableCell align="right">
                          {item.engineOilLevel}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key="rpm"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Rpm
                        </TableCell>
                        <TableCell align="right">{item.rpm}</TableCell>
                      </TableRow>
                      <TableRow
                        key="frequencyH2"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Frequency H2
                        </TableCell>
                        <TableCell align="right">{item.frequencyH2} </TableCell>
                      </TableRow>
                      <TableRow
                        key="coollantOilLevel"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Coollant Oil Level
                        </TableCell>
                        <TableCell align="right">
                          {item.coollantOilLevel}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key="batteryCondition"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Battery Condition
                        </TableCell>
                        <TableCell align="right">
                          {item.batteryCondition}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key="dieselLevel"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Diesel Level
                        </TableCell>
                        <TableCell align="right">{item.dieselLevel}</TableCell>
                      </TableRow>
                      <TableRow
                        key="engineTimeStart"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Engine Time Start
                        </TableCell>
                        <TableCell align="right">
                          {item.engineTimeStart}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key="engineTimeStop"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Engine Time Stop
                        </TableCell>
                        <TableCell align="right">
                          {item.engineTimeStop}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key="outputVoltageRY"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Output Voltage R Y
                        </TableCell>
                        <TableCell align="right">
                          {item.outputVoltageRY}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key="outputVoltageYB"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Output Voltage Y B
                        </TableCell>
                        <TableCell align="right">
                          {item.outputVoltageYB}
                        </TableCell>
                      </TableRow>

                      <TableRow
                        key="outputVoltageBR"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Output Voltage B R
                        </TableCell>
                        <TableCell align="right">
                          {item.outputVoltageBR}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key="runningMins"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Running Mins
                        </TableCell>
                        <TableCell align="right">{item.runningMins}</TableCell>
                      </TableRow>
                      <TableRow
                        key="remarks"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Remarks
                        </TableCell>
                        <TableCell align="right">{item.remarks}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              );
            })
          : ""}
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={alert.serverity}
            sx={{ width: "100%" }}
            variant="filled"
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
}

export default CategoriesPreview;
