import React from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import InputField from "./Elements/InputField";
import { useState } from "react";
import { isSuperAdmin, toTitleCase } from "../../utils/utils";
import BasicDatePicker from "./Elements/datePicker";
import { Link } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

function TankersTruck() {
  var myHeaders = new Headers({ authorization: process.env.REACT_APP_API_KEY });
  myHeaders.append("Content-Type", "application/json");
  const fillingCaution = `
    Fill all categories of cylinder filling before entering  data
  `;
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "ola",
    serverity: "success",
  });

  const handleAlertClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function handleKeyPress(event) {
    if (event.key === "-" || event.key === "+") {
      event.preventDefault(); // prevent the minus symbol from being entered
    }
  }
  const currDate = new Date();
  const beforeDateStr = currDate.toISOString().slice(0, 10);

  const [firstTruckout, setfirstTruckout] = useState("");
  const [lastTruckout, setlastTruckout] = useState("");

  // console.log(`${beforeDateStr}T00:00:00.000Z`);

  const [data, setData] = useState({
    date: `${beforeDateStr}T00:00:00.000Z`,
    nlsaSalesExEpplDom: 0,
    nlsaSalesExEpplNd: 0,
    bulkReceivedDom: 0,
    bulkReceivedNd: 0,
    tankersInTransit: 0,
    tankersWaitingAtPlant: 0,
    tankersWaitingIntransit: 0,
    bulkOpeningDom: 0,
    bulkOpeningNd: 0,
    bulkClosingDom: 0,
    bulkClosingNd: 0,
    gainLoss: 0,
    bulkPhysicalClosing: 0,
    trucksInsidePlant: 0,
    totalIndentsExecuted: 0,
    pendingIndentsInIms: 0,
    Residuval_PRCN_for_425: 0,
  });
  const [previewData, setPreviewData] = useState({});

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    background: "#fff",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
    height: "80%",
    padding: "2rem",
    borderRadius: ".75rem",
  };

  // data insertion is from here...
  
  // data insertion is from here...
  const Submit = async (e) => {
    e.preventDefault();
    const insertData = await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/bulk`,
      {
        method: "PUT",
        body: JSON.stringify({
          ...previewData,
          // ...data,
          firstPackedTruckOut: firstTruckout,
          lastPackedTruckOut: lastTruckout,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.response) {
          setAlert({
            open: true,
            message: "Inserted data successfully!",
            severity: "success",
          });
          handleClose();
        } else if (data.err.code === "ER_DUP_ENTRY") {
          setAlert({
            open: true,
            message: "Duplicate Entry",
            serverity: "warning",
          });
        }
      })
      .catch((err) => {
        if (err) {
          window.alert("date already taken...");
        }
      });
    // console.log(insertData);
  };

  // sending data to backend SQL..
  const preview = async (e) => {
    // console.log(data);
    e.preventDefault();
    // get preview data
    try {
      const result = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/bulk/preview`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: myHeaders,
        }
      ).then((result) => result.json());
      // console.log(result)
      if (result.result) {
        setPreviewData({
          ...result.result.data,
        });
        setAlert({
          open: true,
          message: "Preview fetched successfully",
          serverity: "success",
        });
        handleOpen();
      } else {
        setAlert({
          open: true,
          message: "data not available",
          serverity: "error",
        });
      }
      // console.log(result);
    } catch (err) {
      setAlert({
        open: true,
        message: "An error occured while fetching preview",
        serverity: "error",
      });
      // console.log(err);
    }
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({
      ...data,
      [name]: value,
    });
    // console.log(e.target.value);
  };

  const setDate = (e) => {
    setData({
      ...data,
      date: new Date(e).toISOString(),
    });
  };

  const changeFirstTruck = (e) => {
    setfirstTruckout(e.target.value || "08:00");
  };
  const changeLastTruck = (e) => {
    setlastTruckout(e.target.value || "08:00");
  };

  return (
    <div>
      <div id="bulkPage">
        <form onSubmit={Submit}>
          <Grid
            sx={{ px: 3, my: 2 }}
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid container alignItems={"center"}>
              <Typography my variant="h4" align="center">
                Bulk
              </Typography>
              <Tooltip title={fillingCaution}>
                <WarningAmberIcon style={{ color: "#f00" }} />
              </Tooltip>
            </Grid>
            {isSuperAdmin() && (
              <Grid item>
                <Button
                  component={Link}
                  to="/TankerTruckUpdate"
                  variant="contained"
                >
                  Update Bulk
                </Button>
              </Grid>
            )}
          </Grid>

          <div className="dateCate">
            <BasicDatePicker
              onChange={setDate}
              label="date"
              name="date"
              renderInput={(params) => <TextField {...params} />}
              value={data.date}
            />
          </div>

          <div className="ConsumptionContainer">
            <span>
              <p>first truck out</p>
              <TextField
                margin="dense"
                variant="standard"
                name="firstPackedTruckOut"
                value={data.firstPackedTruckOut}
                type="time"
                onChange={changeFirstTruck}
              />
            </span>

            <span>
              <p>last truck out</p>
              <TextField
                margin="dense"
                variant="standard"
                name="lastPackedTruckOut"
                value={data.lastPackedTruckOut}
                type="time"
                onChange={changeLastTruck}
              />
            </span>
          </div>

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="center"
            textAlign="center"
            justifyContent="space-around"
          >
            <InputField
              label="Bulk Received Dom"
              value={data.bulkReceivedDom}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="bulkReceivedDom"
            />
            <InputField
              label="Bulk Received Nd"
              value={data.bulkReceivedNd}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="bulkReceivedNd"
            />
            <InputField
              label="Tankers In Transit"
              value={data.tankersInTransit}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="tankersInTransit"
            />
            <InputField
              label="Tankers Waiting At Plant"
              value={data.tankersWaitingAtPlant}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="tankersWaitingAtPlant"
            />
            <InputField
              label="Bulk Physical Closing"
              value={data.bulkPhysicalClosing}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="bulkPhysicalClosing"
            />
            <InputField
              label="Trucks Inside Plant"
              value={data.trucksInsidePlant}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="trucksInsidePlant"
            />
            <InputField
              label="Total Indents Executed"
              value={data.totalIndentsExecuted}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="totalIndentsExecuted"
            />
            <InputField
              label="Pending Indents In Ims"
              value={data.pendingIndentsInIms}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="pendingIndentsInIms"
            />


            <InputField
              label="Residuval PRCN for 425"
              value={data.ResiduvalPRCNfor425}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="ResiduvalPRCNfor425"
            />
          </Grid>

          <Grid container sx={{ my: 2 }} justifyContent="space-around">
            <Button sx={{ mr: 2 }} onClick={preview} variant="outlined">
              Preview
            </Button>
          </Grid>
        </form>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box style={style}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  {Object.keys(previewData).map((key) => (
                    <TableRow
                      key={key}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {toTitleCase(key)}
                      </TableCell>
                      <TableCell align="right">{previewData[key]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="info" onClick={Submit}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={alert.serverity}
            sx={{ width: "100%" }}
            variant="filled"
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default TankersTruck;
