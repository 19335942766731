import { Outlet, Navigate } from 'react-router-dom'
import { decodeToken } from 'react-jwt';
const SuperAdminRoutes = () => {
    let auth = {'token':(localStorage.getItem('token'))}
    let role = decodeToken(auth.token).role;
    return(
        role === 'super-admin' ? <Outlet/> : <Navigate to="/forms"/>
    )
}

export default SuperAdminRoutes