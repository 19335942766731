import {
  Grid,
  MenuItem,
  InputLabel,
  Select,
  Box,
  FormControl,
  TextField,
} from "@mui/material";
import { useState } from "react";
import React from "react";
import InputField from "./Elements/InputField";
import { isSuperAdmin, toTitleCase } from "../../utils/utils";
import BasicDatePicker from "./Elements/datePicker";
import { Alert } from "@mui/material";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";


function Layout() {
  var myHeaders = new Headers({ authorization: process.env.REACT_APP_API_KEY });
  myHeaders.append("Content-Type", "application/json");
  
  const categories = [
    "14.2 kg",
    "19kg",
    "35kg",
    "47.5kg",
    "425kg",
    "5kg red",
    "5kg blue",
    "2kg blue",
  ];
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "ola",
    serverity: "success",
  });
  const handleAlertClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  
  function handleKeyPress(event) {
    if (event.key === "-" || event.key === "+") {
      event.preventDefault(); // prevent the minus symbol from being entered
    }
  }
  const currDate = new Date();
  const beforeDateStr = currDate.toISOString().slice(0, 10);

  const [data, setData] = useState({
    Timing: `${beforeDateStr}T00:00:00.000Z`,
    CATEGORY: "14.2 kg",
    atpDespBodyLeak: 0,
    atpDespColdRepair: 0,
    atpDespDamaged: 0,
    atpDespDamagedSpuriousOmc: 0,
    atpDespDpt: 0,
    atpDespHotRepair: 0,
    atpDespNeckLeak: 0,
    atpDespOmc: 0,
    atpDespScrap: 0,
    atpDespSpurious: 0,
    closingBodyLeak: 0,
    closingColdRepair: 0,
    closingDamaged: 0,
    closingDamagedSpuriousOmc: 0,
    closingDpt: 0,
    closingGoodEmptyStockOf: 0,
    closingHotRepair: 0,
    closingInventoryOf: 0,
    closingNeckLeak: 0,
    closingOmc: 0,
    closingPinLeak: 0,
    closingScrap: 0,
    closingSpurious: 0,
    closingStockFulls: 0,
    cylindersReceivedFromDistributor: 0,
    cylindersThroughTranshipmentReceipt: 0,
    damagedSpuriousOmc: 0,
    dayDespatches: 0,
    dayProduction: 0,
    generatedBodyLeak: 0,
    generatedColdRepair: 0,
    generatedDpt: 0,
    generatedHotRepair: 0,
    generatedNeckLeak: 0,
    generatedPinLeak: 0,
    newEmptyCylindersReceipt: 0,
    noOfSqc: 0,
    prcnIssuedUpdateOnlyFullPrcn: 0,
    prcnIssuedUpdatePartPrcnQty: 0,
    receivedDamaged: 0,
    receivedOmc: 0,
    receivedScrap: 0,
    receivedSpurious: 0,
    rejectedCylsInSqc: 0,
    sqcInTermsOfCylinders: 0,
    valveChanging: 0,
  });
  const [previewData, setPreviewData] = useState({});

  const Submit = async (e) => {
    e.preventDefault();
    // console.log(previewData);
    fetch(`${process.env.REACT_APP_BASE_API_URL}/filling`, {
      method: "PUT",
      body: JSON.stringify(previewData),
      headers: myHeaders,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.err) {
          if (data.err.code === "ER_DUP_ENTRY") {
            setAlert({
              open: true,
              message: "Duplicate Entry",
              serverity: "warning",
            });
          } else {
            setAlert({
              open: true,
              message: "Error while insertion",
              serverity: "warning",
            });
          }
        } else {
          setAlert({
            open: true,
            message: "Data uploaded successfully",
            serverity: "success",
          });
          setOpen(false);
          // console.log(data);
        }

      })
      .catch((err) => {
        setAlert({
          open: true,
          message: "Unable to insert data. Try again!",
          serverity: "error",
        });
      });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    background: "#fff",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
    height: "80%",
    padding: "2rem",
    borderRadius: ".75rem",
  };

  // sending data to backend SQL..
  const preview = async (e) => {
    // console.log(data);
    e.preventDefault();
    // get preview data
    var requestOptions = {
      method: "POST",
      body: JSON.stringify(data),
      headers: myHeaders,
    };
    try {
      const result = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/filling/preview`,
        requestOptions
      ).then((result) => result.json());
      if (result.result) {
        setPreviewData({
          ...result.result,
        });
        handleOpen();
      } else {
        setAlert({
          open: true,
          message: "No data",
          serverity: "warning",
        });
      }
      // console.log(result);
    } catch (err) {
      setAlert({
        open: true,
        message: "An error occured while fetching preview",
        serverity: "error",
      });
      // console.log(err);
    }
  };
  //  updating data after input
  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({
      ...data,
      [name]: value,
    });
  };
  // set date
  const setDate = (e) => {
    setData({
      ...data,
      Timing: new Date(e).toISOString(),
    });
  };
  return (
    <div>
      <div id="homePage">
        <form>
          <Grid
            sx={{ px: 3, my: 2 }}
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid container alignItems={'center'}>
              <Typography my variant="h4" align="center">
                Filling
              </Typography>
            </Grid>
            {isSuperAdmin() && (
              <Grid item>
                <Button
                  component={Link}
                  to="/fillingUpdate"
                  variant="contained"
                >
                  Update Filling
                </Button>
              </Grid>
            )}
          </Grid>
          <div className="dateCate">
            <BasicDatePicker
              onChange={setDate}
              label="Date"
              renderInput={(params) => <TextField {...params} />}
              value={data.Timing}
            />
            <Box sx={{ minWidth: 300 }}>
              <FormControl fullWidth>
                <InputLabel>Category</InputLabel>
                <Select
                  value={data.CATEGORY}
                  label="Category"
                  onChange={handleInputChange}
                  name="CATEGORY"
                >
                  {categories.map((category) => {
                    return (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>

          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="center"
            textAlign="center"
            justifyContent="space-around"
          >
            {/* {Object.keys(data).map((key) => {
              console.log(
                `<InputField label="${toTitleCase(key)}" value={data.${key}} inputChange={handleInputChange} keyPress={handleKeyPress} name="${key}"  />`
              );
              if (key === "CATEGORY" || key === "Timing") return "";
              return (
                <InputField
                  label={toTitleCase(key)}
                  value={data[key]}
                  inputChange={handleInputChange}
                  keyPress={handleKeyPress}
                  name={key}
                />
              );
            })} */}
            <InputField
              label="Day Dispatches"
              value={data.dayDespatches}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="dayDespatches"
            />
            <InputField
              label="Prcn Issued Update Only Full Prcn"
              value={data.prcnIssuedUpdateOnlyFullPrcn}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="prcnIssuedUpdateOnlyFullPrcn"
            />
            <InputField
              label="Prcn Issued Update Part Prcn Qty"
              value={data.prcnIssuedUpdatePartPrcnQty}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="prcnIssuedUpdatePartPrcnQty"
            />
            <InputField
              label="Closing Stock Fulls"
              value={data.closingStockFulls}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="closingStockFulls"
            />
            <InputField
              label="Valve Changing"
              value={data.valveChanging}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="valveChanging"
            />
            <InputField
              label="New Empty Cylinders Receipt"
              value={data.newEmptyCylindersReceipt}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="newEmptyCylindersReceipt"
            />
            <InputField
              label="Cylinders Through Transhipment Receipt"
              value={data.cylindersThroughTranshipmentReceipt}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="cylindersThroughTranshipmentReceipt"
            />
            <InputField
              label="Cylinders Received From Distributor"
              value={data.cylindersReceivedFromDistributor}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="cylindersReceivedFromDistributor"
            />
            <InputField
              label="Generated Dpt"
              value={data.generatedDpt}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="generatedDpt"
            />
            <InputField
              label="Generated Hot Repair"
              value={data.generatedHotRepair}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="generatedHotRepair"
            />
            <InputField
              label="Generated Cold Repair"
              value={data.generatedColdRepair}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="generatedColdRepair"
            />
            <InputField
              label="Received Damaged"
              value={data.receivedDamaged}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="receivedDamaged"
            />
            <InputField
              label="Received Spurious"
              value={data.receivedSpurious}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="receivedSpurious"
            />
            <InputField
              label="Atp Dispatches Damaged Spurious Omc"
              value={data.atpDespDamagedSpuriousOmc}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="atpDespDamagedSpuriousOmc"
            />
            <InputField
              label="Atp Dispatches Scrap"
              value={data.atpDespScrap}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="atpDespScrap"
            />
            <InputField
              label="Generated Neck Leak"
              value={data.generatedNeckLeak}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="generatedNeckLeak"
            />
            <InputField
              label="Generated Pin Leak"
              value={data.generatedPinLeak}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="generatedPinLeak"
            />
            <InputField
              label="Generated Body Leak"
              value={data.generatedBodyLeak}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="generatedBodyLeak"
            />
            <InputField
              label="Atp Dispatches Dpt"
              value={data.atpDespDpt}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="atpDespDpt"
            />
            <InputField
              label="Atp Dispatches Body Leak"
              value={data.atpDespBodyLeak}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="atpDespBodyLeak"
            />
            <InputField
              label="Atp Dispatches Hot Repair"
              value={data.atpDespHotRepair}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="atpDespHotRepair"
            />
            <InputField
              label="Atp Dispatches Cold Repair"
              value={data.atpDespColdRepair}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="atpDespColdRepair"
            />
            <InputField
              label="Atp Dispatches Damaged"
              value={data.atpDespDamaged}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="atpDespDamaged"
            />
            <InputField
              label="Atp Dispatches Spurious"
              value={data.atpDespSpurious}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="atpDespSpurious"
            />
            <InputField
              label="Damaged Spurious Omc"
              value={data.damagedSpuriousOmc}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="damagedSpuriousOmc"
            />
            <InputField
              label="Atp Dispatches Omc"
              value={data.atpDespOmc}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="atpDespOmc"
            />
            <InputField
              label="Received Scrap"
              value={data.receivedScrap}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="receivedScrap"
            />
            <InputField
              label="Atp Dispatches Neck Leak"
              value={data.atpDespNeckLeak}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="atpDespNeckLeak"
            />

            {/* ============================================ */}

            {/* <InputField
              label="Closing Body Leak"
              value={data.closingBodyLeak}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="closingBodyLeak"
            /> */}
            {/* <InputField
              label="Closing Cold Repair"
              value={data.closingColdRepair}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="closingColdRepair"
            /> */}
            {/* <InputField
              label="Closing Damaged"
              value={data.closingDamaged}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="closingDamaged"
            /> */}
            {/* <InputField
              label="Closing Damaged Spurious Omc"
              value={data.closingDamagedSpuriousOmc}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="closingDamagedSpuriousOmc"
            /> */}
            {/* <InputField
              label="Closing Dpt"
              value={data.closingDpt}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="closingDpt"
            /> */}
            {/* <InputField
              label="Closing Good Empty Stock"
              value={data.closingGoodEmptyStockOf}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="closingGoodEmptyStockOf"
            /> */}
            {/* <InputField
              label="Closing Hot Repair"
              value={data.closingHotRepair}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="closingHotRepair"
            /> */}
            {/* <InputField
              label="Closing Inventory"
              value={data.closingInventoryOf}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="closingInventoryOf"
            /> */}
            {/* <InputField
              label="Closing Neck Leak"
              value={data.closingNeckLeak}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="closingNeckLeak"
            /> */}
            {/* <InputField
              label="Closing Omc"
              value={data.closingOmc}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="closingOmc"
            /> */}
            {/* <InputField
              label="Closing Pin Leak"
              value={data.closingPinLeak}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="closingPinLeak"
            /> */}
            {/* <InputField
              label="Closing Scrap"
              value={data.closingScrap}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="closingScrap"
            /> */}
            {/* <InputField
              label="Closing Spurious"
              value={data.closingSpurious}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="closingSpurious"
            /> */}

            {/* <InputField
              label="Day Production"
              value={data.dayProduction}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="dayProduction"
            /> */}

            <InputField
              label="No of Sqc"
              value={data.noOfSqc}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="noOfSqc"
            />

            <InputField
              label="Received Omc"
              value={data.receivedOmc}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="receivedOmc"
            />

            <InputField
              label="Rejected Cyls In Sqc"
              value={data.rejectedCylsInSqc}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="rejectedCylsInSqc"
            />
            {/* <InputField
              label="Sqc In Terms of Cylinders"
              value={data.sqcInTermsOfCylinders}
              inputChange={handleInputChange}
              keyPress={handleKeyPress}
              name="sqcInTermsOfCylinders"
            /> */}
          </Grid>

          <Grid container sx={{ my: 2 }} justifyContent="space-around">
            <Button sx={{ mr: 2 }} onClick={preview} variant="outlined">
              Preview
            </Button>
          </Grid>
        </form>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box style={style}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  {Object.keys(previewData).map((key) => (
                    <TableRow
                      key={key}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {toTitleCase(key)}
                      </TableCell>
                      <TableCell align="right">{previewData[key]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid container sx={{ my: 2 }} spacing={2} justifyContent="center">
              <Grid item>
                <Button
                  variant="outlined"
                  color="warning"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="success" onClick={Submit}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={alert.serverity}
            sx={{ width: "100%" }}
            variant="filled"
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default Layout;
