import { Outlet, Navigate } from 'react-router-dom'
import {decodeToken} from 'react-jwt'
const AdminRoutes = () => {
    let auth = {'token':(localStorage.getItem('token'))}
    let role = decodeToken(auth.token).role;
    return(
        role === 'admin' || role === 'super-admin' ? <Outlet/> : <Navigate to="/"/>
    )
}

export default AdminRoutes