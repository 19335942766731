import React, { useEffect, useState } from "react";
import "../../styling/licensePreview.css";
import Logoimg from "../../Images/logo.png";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import Modal from "@mui/material/Modal";
import crossImage from "../../Images/crossImage.svg";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import deleteimg from "../../Images/delete.svg";

function License() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    // minWidth: 400,
    bgcolor: "#fff",
    boxShadow: 24,
    // p: 4,
    outline: "none",
    borderRadius: "5px ",
  };

  const [alert, setAlert] = useState({
    open: false,
    message: "ola",
    serverity: "success",
  });

  const handleAlertClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const [deleteDocumentName, setdeleteDocumentName] = useState("");
  const [documentData, setDocumentData] = useState([]);

  const [currentModal, setCurrentModal] = useState(null);
  // const [imageSelected, setimageSelected] = useState(null);
  const [showImage, setShowImage] = useState(Logoimg);

  const handleClose = () => {
    setCurrentModal(null);
  };

  const FetchLicense = async (e) => {
    // e.preventDefault();

    await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/license`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        let fetchDocuments = data.result;
        // console.log(fetchDocuments.length);
        if (fetchDocuments.length === 0) {
          setAlert({
            open: true,
            message: "License Count Empty upload Some.",
            severity: "success",
          });
          setDocumentCount(0);
        } else {
          // console.log(fetchDocuments);

          fetchDocuments.sort(
            (a, b) =>
              new Date(a.document_end_date) - new Date(b.document_end_date)
          );
          // console.log(fetchDocuments);

          setDocumentData(fetchDocuments);
          setDocumentCount(fetchDocuments.length);
          // window.alert("no documents");
        }
      })
      .catch((err) => {/*console.log(err)*/});
  };

  useEffect(() => {
    FetchLicense();
  }, []);

  let date = "2023-08-02";
  // console.log(new Date(date).toDateString());

  const DeleteDoucment_license = async (e) => {
    await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/license`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          document_name: deleteDocumentName,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if (data.message === "success") {
          setAlert({
            open: true,
            message: ` ${deleteDocumentName} License Deleted Successfully`,
            serverity: "success",
          });
          handleClose();
          FetchLicense();
        } else {
          setAlert({
            open: true,
            message: "Failed! License Not Deleted .",
            serverity: "error",
          });
        }
      })
      .catch((err) => {/*console.log(err)*/});
  };

  const [documentCount, setDocumentCount] = useState(0);

  // getting number days of the license to expire..

  function expiryDate(expiryDate) {
    let currDate = new Date().toISOString();
    // console.log(expiryDate);

    expiryDate = expiryDate.slice(0, 10);

    // console.log(expiryDate);

    let oneDay = 24 * 60 * 60 * 1000;

    let diffInMs = new Date(expiryDate) - new Date(currDate); // calculating number of minutes to expire..

    let diffInDays = Math.floor(diffInMs / oneDay); // calculating number of days
    diffInDays += 1;

    return diffInDays;
  }

  return (
    <>
      <section className="licensePreview">
        {documentCount === 0 ? (
          <>
            <h1 className="No-Documents">No License to Show Upload Few  📄 & Reload .</h1>
          </>
        ) : (
          <>
            <div className="document-Cards">
              {documentData.map((data, index) => {
                return (
                  <>
                    <div className="document-Card" key={index + 1}>
                      <div className="license-content">
                        <div className="license-document-content">
                          <div>
                            <p className="document-key">Document Name</p>
                            <span className="dots">:</span>{" "}
                            <h1 className="document-Value">
                              {data.document_name}
                            </h1>
                          </div>
                          <div>
                            <p className="document-key">Document Start Date</p>{" "}
                            <span className="dots">:</span>{" "}
                            <h1 className="document-Value">
                              {new Date(
                                data.document_start_date
                              ).toDateString()}
                            </h1>
                          </div>
                          <div>
                            <p className="document-key">Document End Date</p>{" "}
                            <span className="dots">:</span>{" "}
                            <h1 className="document-Value">
                              {new Date(data.document_end_date).toDateString()}
                            </h1>
                          </div>

                          {expiryDate(data.document_end_date) <= 0 && (
                            <>
                              <div className="expriry-date-content expiry-content-animation">
                                <p>
                                  <span>
                                    <NotificationsActiveIcon />
                                  </span>{" "}
                                  LICENSE EXPIRED
                                </p>
                              </div>
                            </>
                          )}

                          {expiryDate(data.document_end_date) <= 29 &&
                            expiryDate(data.document_end_date) >= 1 && (
                              <>
                                <div className="expriry-date-content">
                                  <p>
                                    *License expires in{" "}
                                    {expiryDate(data.document_end_date)} days
                                  </p>
                                </div>
                              </>
                            )}

                          {/* {console.log(expiryDate(data.document_end_date))} */}
                        </div>
                      </div>

                      <div className="card-img">
                        <div
                          className="delete-icon"
                          onClick={() => {
                            setCurrentModal("delete-document");
                            setdeleteDocumentName(data.document_name);
                          }}
                        >
                          <img src={deleteimg} alt="error" />
                        </div>
                        <div
                          className="document-img"
                          onClick={() => {
                            setCurrentModal("show-document-license");
                            setShowImage(data.document_URL);
                          }}
                        >
                          <img src={data.document_URL} alt="" />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </>
        )}
      </section>

      <Modal
        open={currentModal === "delete-document"}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backdropFilter: "blur(2px)" }}
        className="new-user-pop-up user-pop "
      >
        <Box sx={style}>
          <section className="Delete-confirmation">
            <div className="nav" onClick={handleClose}>
              <img src={crossImage} alt="" />
            </div>
            <div className="delete-info">
              <p>
                Delete <h1>{deleteDocumentName}</h1> License ?
              </p>
              <div className="buttons">
                <button className="Delete" onClick={DeleteDoucment_license}>
                  Delete
                </button>
                <button className="Cancel" onClick={handleClose}>
                  Cancel
                </button>
              </div>
            </div>
          </section>
        </Box>
      </Modal>

      <Modal
        open={currentModal === "show-document-license"}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backdropFilter: "blur(2px)" }}
        className="new-user-pop-up user-pop"
      >
        <Box sx={style}>
          <section className="document-image-shower">
            <div className="nav" onClick={handleClose}>
              <img src={crossImage} alt="" />
            </div>
            <div className="document-modal-images">
              <img src={showImage} alt="" />
            </div>
          </section>
        </Box>
      </Modal>

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alert.serverity}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default License;
