import React from 'react'
import { useState } from 'react'

function MaintenanceUpd() {
    const [value , setValue] = useState("value")
  
    const handleChange = (e)=>{
        e.preventDefault();
        setValue(e.target.value)
    }

const submit=(e)=>{
    // console.log(value)
}
  return (
    <div>
      <input type="text" value={value} onChange={handleChange} />
      <button onClick={submit}>submit</button>
    </div>
  )
}

export default MaintenanceUpd
