import "./App.css";
import { Routes, Route } from "react-router-dom";
import Forms from "./Components/Forms/Forms";
import Login from "./Components/Login";
// import DownloadSheet from "./Components/DownloadSheet";
import DashBoard from "./Components/DashBoard";
import PrivateRoutes from "./utils/ProtectedRoutes";
import AdminRoutes from "./utils/AdminRoutes";
import FillingUpdate from "./Components/UpdateComponents/FillingUpdate";
import ConsumablesUpdate from "./Components/UpdateComponents/ConsumablesUpdate";
import TankerTruckUpd from "./Components/UpdateComponents/TankerTruckUpd";
import PowerCompUPD from "./Components/UpdateComponents/PowerCompUPD";
import MaintenanceUpd from "./Components/UpdateComponents/MaintenanceUpd";
import BatteryDeisel from "./Components/Forms/BatteryDeisel";
import Maintenance from "./Components/Forms/Maintenance";
import ValvesRegComs from "./Components/Forms/ValvesRegComs";
import PowerConsumption from "./Components/Forms/PowerConsumption";
import TankersTruck from "./Components/Forms/TankersTruck";
import SuperAdminRoutes from "./utils/SuperAdminRoutes";
function App() {
  return (
    <>
      <Routes>
        {/* <Route path="/login" element={<Login />} /> */}
        {/* <Route path="/home" element={<HomeDashboard />} /> */}
        {/* <Route path="/other" element={<OthersGph />} /> */}
        {/* <Route path="/categoriesGraph" element={<CategoriesGph />} /> */}
        {/* <Route path="/ConsumableGraph" element={<ConsumablesGph />} /> */}
        {/* <Route path="/BulkGraph" element={<BulkGph />} /> */}
        {/* <Route path="/YearlyGraph" element={<YearlyGraph />} /> */}
        {/* <Route path="/MonthlyGraph" element={<MonthlyGraph />} /> */}
        {/* <Route path="/WeeklyGraph" element={<WeeklyGraph />} /> */}
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<DashBoard />} />
          <Route element={<AdminRoutes />}>
            <Route path="/forms" element={<Forms />} />
            <Route path="/battery" element={<BatteryDeisel />} />
            <Route path="/maintenance" element={<Maintenance />} />
            <Route path="/valves" element={<ValvesRegComs />} />
            <Route path="/power" element={<PowerConsumption />} />
            <Route path="/tanker" element={<TankersTruck />} />

            <Route path="*" element={<DashBoard />} />
            {/* <Route path="/downloadSheet" element={<DownloadSheet />} /> */}
          </Route>
          <Route element={<SuperAdminRoutes/>}>
            <Route path="/fillingUpdate" element={<FillingUpdate />} />
            <Route path="/consumablesUpdate" element={<ConsumablesUpdate />} />
            <Route path="/TankerTruckUpdate" element={<TankerTruckUpd />} />
            <Route path="/PowerConsumpUpdate" element={<PowerCompUPD />} />
            <Route path="/maintenanceUpd" element={<MaintenanceUpd />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" to="/" element={<DashBoard />} />
      </Routes>
    </>
  );
}

export default App;
