import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Alert,
  Snackbar,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
// import {HorizontalBar} from 'react-chartjs-2';
import BasicDatePicker from "../Forms/Elements/datePicker";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const Pieoptions = {};

function ConsumablesGph() {
  var myHeaders = new Headers({ authorization: process.env.REACT_APP_API_KEY });
  myHeaders.append("Content-Type", "application/json");
  let closingStockArr = [];
  // let body = { year: year, month: month -1  , week: undefined, day: 1 };

  let ClosingValuesOBJ = {
    closingGoodValves: null,
    closingGoodRegulators: null,
    closingOrings: null,
    closingPvcSeal: null,
    closingScCaps: null,
  };

  const [alert, setAlert] = useState({
    open: false,
    message: "ola",
    serverity: "success",
  });
  const handleAlertClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const labels = ["Valves", "Orings", "PVS Seals", "Caps"];
  const [DateWise, setDateWise] = useState(true);
  const [DayWise, setDayWise] = useState(true);
  const [WeekWise, setWeekWise] = useState(false);
  const [MonthlyData, setMonthlyData] = useState(false);
  const [year, setYear] = useState("2021");
  const [month, setMonth] = useState("1");
  const [consumableClosing, setconsumableClosing] = useState([0, 0, 0]);
  const [tilldates, setTilldates] = useState([0, 0, 0, 0]);
  const [TillDateGraph, setTillDateGraph] = useState(true);
  const [closingValues, setClosingValues] = useState(ClosingValuesOBJ);
  const [ClosingGoodEmptyStock, setClosingGoodEmptyStock] =
    useState(closingStockArr);
  const [Week, setWeek] = useState("1");
  let body = { year: year, month: month - 1, week: undefined, day: 1 };
  const [Thershold, setThershold] = useState([
    {
      oringsThreshold: null,
      pvcThreshold: null,
      scCapsThreshold: null,
      valvesThreshold: null,
      regulatorThreshold: null,
    },
  ]);

  const categories = [
    `14.2 kg`,
    `19kg`,
    `2kg blue`,
    `35kg`,
    `425kg`,
    `47.5kg`,
    `5kg blue`,
    `5kg red`,
  ];
  function getColors(n) {
    var colors = ["#FE5010", "#771AA7"];
    var hue = 16;
    var saturation = 95;
    var lightness = 50;
    for (var i = 0; i < n; i++) {
      colors.push(`hsl(${hue},${saturation}%,${lightness}%)`);
      hue += 16; //difference between two colors
      if (hue >= 360) {
        hue = 360 - hue;
      }
    }
    // console.log(colors);
    return colors;
  }
  // console.log(getColors(7));

  const closingStockData = {
    labels: categories,
    datasets: [
      {
        data: ClosingGoodEmptyStock,

        backgroundColor: getColors(7),
      },
    ],
  };

  const GraphTillDateData = {
    labels: labels,
    datasets: [
      {
        axis: "x",
        label: "Till Dates",
        data: tilldates,
        fill: false,
        backgroundColor: ["#FE5010", "#fe5f10", "#feab10", "#febb10"],
      },
    ],
    options: {
      indexAxis: "y",
    },
  };

  const Horioptions = {
    indexAxis: "y",
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "No. of  Days",
          color: "black",
          font: {
            weight: 700,
          },
        },
      },
    },
  };

  const GraphConsumptionData = {
    labels: ["Orings", "PVC Seals", "Caps"],
    datasets: [
      {
        data: consumableClosing,
        backgroundColor: [
          "#771AA7",
          "#FF762D",
          "#F39200",
          "#F6C605",
          "#FCB247",
        ],
      },
    ],
  };
  const options = {};

  const currDate = new Date();
  let currDateIso = currDate.toISOString().slice(0, 10);

  const [date, setDate] = useState({
    Timing: "2022-05-25T00:00:00.000Z",
  });
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/consumables/ConsumablesLastUpdDate`,
      {
        method: "POST",
        body: JSON.stringify({
          date: `${currDateIso}T00:00:00.000Z`,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log("last update date");
        let value = data.response[0].previous_last_updated_date + "";
        // setLastUpdDate(value);
        // console.log(data.response[0].previous_last_updated_date);
        setDate({
          ...data,
          Timing: value,
        });
      })
      .catch((err) => {
        // window.alert("error fetching in  last update date");
        setAlert({
          open: true,
          message: "Error Fetching in last Updated Date",
          serverity: "warning",
        });
        // console.log(err);
      });
  }, []);

  const DateChange = (e) => {
    setDate({
      ...date,
      Timing: new Date(e).toISOString(),
    });
  };

  let myArr = [];

  const FetchValues = async (e) => {
    e.preventDefault();
    // consumanles closing values here...
    if (MonthlyData === true) {
      //month wise
      // window.alert("month wise")
      await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/consumables/consumableGraphMonthly`,
        {
          method: "POST",
          body: JSON.stringify({
            year: year,
            month: month,
          }),
          headers: myHeaders,
        }
      )
        .then((res) => res.json())
        .then((data) => {
          let returnedValue = Object.values(data.results[0]);
          // console.log(returnedValue);
          if (returnedValue[0] === null) {
            setconsumableClosing([0, 0, 0]);
            setAlert({
              open: true,
              message: "Consumables data not present",
              serverity: "error",
            });
          } else {
            let obj = data.results[0];
            // console.log(obj);
            myArr.push(Object.values(obj));
            let finalarr = myArr[0];
            // console.log(finalarr);
            // console.log(data.results[0]);
            setconsumableClosing(finalarr);
            return data;
          }
        })
        .catch((err) => {
          setAlert({
            open: true,
            message: "Error while fetching data",
            serverity: "error",
          });
          // console.log(err)
        });
    } else if (WeekWise === true) {
      // week wise
      await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/consumables/consumableGraphWeekly`,
        {
          method: "POST",
          body: JSON.stringify({
            startDay: new Date(year, month, weeks[Week].start)
              .toISOString()
              .slice(0, 10),
            endDay: new Date(year, month, weeks[Week].end)
              .toISOString()
              .slice(0, 10),
          }),
          headers: myHeaders,
        }
      )
        .then((res) => res.json())
        .then((data) => {
          let returnedValue = Object.values(data.results[0]);
          if (returnedValue[0] === null) {
            // window.alert("Consumables data not present");
            setconsumableClosing([0, 0, 0]);
            setAlert({
              open: true,
              message: "Consumables data not present",
              serverity: "error",
            });
          } else {
            // console.log("he");
            let obj = data.results[0];
            // console.log(obj);
            myArr.push(Object.values(obj));
            let finalarr = myArr[0];
            // console.log(finalarr);
            // console.log(data.results[0]);
            setconsumableClosing(finalarr);
            return data;
          }
        })
        .catch((err) => {
          setAlert({
            open: true,
            message: "Error while fetching data",
            serverity: "error",
          });
          // console.log(err);
        });
    } else {
      // day wise
      await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/consumables/consumableGraph`,
        {
          method: "POST",
          body: JSON.stringify({
            Timing: date.Timing,
          }),
          headers: myHeaders,
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.results[0]) {
            let obj = data.results[0];
            // console.log(obj);
            myArr.push(Object.values(obj));
            let finalarr = myArr[0];
            // console.log(finalarr);

            // console.log(data.results[0]);
            setconsumableClosing(finalarr);
            return data;
          } else {
            // window.alert("Consumables data not present");
            setconsumableClosing([0, 0, 0]);
            setAlert({
              open: true,
              message: "Consumables data not present",
              serverity: "error",
            });
          }
        })
        .catch((err) => {/*console.log(err)*/});

      await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/consumables/closingGoodValues`,
        {
          method: "POST",
          body: JSON.stringify({
            Timing: date.Timing,
          }),
          headers: myHeaders,
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.results[0]) {
            // console.log(data.results);
            let final = data.results[0];
            setClosingValues(final);
            // console.log(final);
            return data.results;
          } else {
            // window.alert("No Closing Data");
            setAlert({
              open: true,
              message: "Error while fetching data",
              serverity: "error",
            });
            setClosingValues(ClosingValuesOBJ);
          }
        })
        .catch((err) => {/*console.log(err)*/});
    }
    // console.log(consumblesUpd);

    let UpdClosingStockArr = [];
    await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/categoriesGraph`,
      {
        method: "POST",
        body: JSON.stringify({
          Timing: date.Timing,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        let obj = data.results;
        if (data.results.length > 0) {
          obj.forEach((data) => {
            // console.log(data.closingGoodEmptyStockOf);
            UpdClosingStockArr.push(data.closingGoodEmptyStockOf);
          });

          setClosingGoodEmptyStock(UpdClosingStockArr);
          return data;
        } else {
          setAlert({
            open: true,
            message: "No Data Found",
            serverity: "warning",
          });
          // window.alert("no data for Closing goodEmpty stock");
          setClosingGoodEmptyStock([]);
        }
        // console.log(data);
      })
      .catch((err) => {/*console.log(err)*/});

    // till date  values here....
    const updtillDateArr = [];
    await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/consumables/tilldate`,
      {
        method: "POST",
        body: JSON.stringify({
          Timing: date.Timing,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data)
        var valves = data.result.goodvalvestilldates.value;
        var orings = data.result.oringstilldates.value;
        var pvcSeals = data.result.pvcsealstilldates.value;
        var scCaps = data.result.sccapstilldates.value;
        // console.log(valves);
        var regulators = 5;
        updtillDateArr.push(valves, orings, pvcSeals, scCaps, regulators);
        // console.log(updtillDateArr);
        setTilldates(updtillDateArr);
        return data;
      })
      .catch((err) => {
        setTilldates([0, 0, 0, 0]);
        // console.log(err);
      });

    await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/consumables/Therhold`,
      {
        method: "POST",
        body: JSON.stringify({
          Timing: date.Timing,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log("thersold values");
        // console.log(data);
        if (data.results[0]) {
          // console.log(data.results);
          setThershold(data.results);
        }
        return data;
      })
      .catch((err) => {/*console.log(err)*/});
  };
  // console.log(myArr);
  const [select, setSelect] = useState("Day");
  const selectChange = (e) => {
    setSelect(e.target.value);
  };
  const HandleYearChange = (e) => {
    setYear(e.target.value);
  };

  const HandleWeekChange = (e) => {
    setWeek(e.target.value);
  };

  const HandleMonthChange = (e) => {
    setMonth(e.target.value);
  };

  const ShowDayValues = () => {
    setMonthlyData(false);
    setDayWise(true);
    setWeekWise(false);
    setTillDateGraph(true);
  };
  const ShowMonthValues = () => {
    setMonthlyData(true);
    setDayWise(false);
    setWeekWise(false);
    setTillDateGraph(false);
  };
  const ShowWeekValues = () => {
    setMonthlyData(false);
    setDayWise(false);
    setWeekWise(true);
    setTillDateGraph(false);
  };

  function getWeekDates() {
    // console.log(body);
    let weeks = [],
      firstDate = new Date(body.year, body.month, 1);
    let lastDate = new Date(body.year, parseInt(body.month) + 1, 0);
    let numDays = lastDate.getDate();
    let start = 1;
    let end = endFirstWeek(firstDate, 1);
    while (start < numDays) {
      weeks.push({ start: start, end: end });
      start = end + 1;
      end = end + 7;
      end = start === 1 && end === 8 ? 1 : end;
      if (end > numDays) {
        end = numDays;
      }
    }
    // console.log(weeks);
    return weeks;
  }
  const weeks = getWeekDates();

  function endFirstWeek(firstDate, firstDay) {
    if (!firstDay) {
      return 7 - firstDate.getDay();
    }
    if (firstDate.getDay() < firstDay) {
      return firstDay - firstDate.getDay();
    } else {
      return 7 - firstDate.getDay() + firstDay;
    }
  }

  return (
    <>
      <div className="Graphheading">
        <div className="GraphDate">
          {DateWise && (
            <div style={{ marginRight: "1vw" }}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Day</InputLabel>
                <Select
                  style={{ backgroundColor: "whitesmoke" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={select}
                  label="Day"
                  onChange={selectChange}
                >
                  <MenuItem value="Day" onClick={ShowDayValues}>
                    Day
                  </MenuItem>
                  <MenuItem value="Weekly" onClick={ShowWeekValues}>
                    Weekly
                  </MenuItem>
                  <MenuItem value="Monthly" onClick={ShowMonthValues}>
                    Monthly
                  </MenuItem>
                </Select>
              </FormControl>{" "}
            </div>
          )}
          {/*  year and monthly wise data shown  */}
          {MonthlyData && (
            <>
              <div className="year">
                <Box sx={{ minWidth: 40, marginRight: "1vw" }}>
                  <FormControl fullWidth>
                    <InputLabel>Year</InputLabel>
                    <Select
                      value={year}
                      label="Year"
                      onChange={HandleYearChange}
                      name="Select Year"
                    >
                      <MenuItem key="2021" value="2021">
                        2021
                      </MenuItem>
                      <MenuItem key="2022" value="2022">
                        2022
                      </MenuItem>
                      <MenuItem key="2023" value="2023">
                        2023
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>

              <div className="month">
                <Box sx={{ minWidth: 200 }}>
                  <FormControl fullWidth>
                    <InputLabel>Month</InputLabel>
                    <Select
                      value={month}
                      label="Month"
                      onChange={HandleMonthChange}
                      name="Select Months"
                    >
                      <MenuItem key="january" value="1">
                        january
                      </MenuItem>
                      <MenuItem key="Febrauary" value="2">
                        Febrauary
                      </MenuItem>
                      <MenuItem key="March" value="3">
                        March
                      </MenuItem>{" "}
                      <MenuItem key="April" value="4">
                        April
                      </MenuItem>{" "}
                      <MenuItem key="May" value="5">
                        May
                      </MenuItem>{" "}
                      <MenuItem key="June" value="6">
                        June
                      </MenuItem>{" "}
                      <MenuItem key="July" value="7">
                        July
                      </MenuItem>{" "}
                      <MenuItem key="August" value="8">
                        August
                      </MenuItem>
                      <MenuItem key="September" value="9">
                        September
                      </MenuItem>
                      <MenuItem key="October" value="10">
                        October
                      </MenuItem>{" "}
                      <MenuItem key="November" value="11">
                        November
                      </MenuItem>{" "}
                      <MenuItem key="December" value="12">
                        December
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </>
          )}
          {WeekWise && (
            <>
              <div className="year">
                <Box sx={{ minWidth: 40, marginRight: "1vw" }}>
                  <FormControl fullWidth>
                    <InputLabel>Year</InputLabel>
                    <Select
                      value={year}
                      label="Year"
                      onChange={HandleYearChange}
                      name="Select Year"
                    >
                      <MenuItem key="2021" value="2021">
                        2021
                      </MenuItem>
                      <MenuItem key="2022" value="2022">
                        2022
                      </MenuItem>
                      <MenuItem key="2023" value="2023">
                        2023
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className="Weeks" style={{ marginRight: "1vw" }}>
                <Box sx={{ minWidth: 150 }}>
                  <FormControl fullWidth>
                    <InputLabel>Month</InputLabel>
                    <Select
                      value={month}
                      label="Month"
                      onChange={HandleMonthChange}
                      name="Select Months"
                    >
                      <MenuItem key="january" value="1">
                        january
                      </MenuItem>
                      <MenuItem key="Febrauary" value="2">
                        Febrauary
                      </MenuItem>
                      <MenuItem key="March" value="3">
                        March
                      </MenuItem>{" "}
                      <MenuItem key="April" value="4">
                        April
                      </MenuItem>{" "}
                      <MenuItem key="May" value="5">
                        May
                      </MenuItem>{" "}
                      <MenuItem key="June" value="6">
                        June
                      </MenuItem>{" "}
                      <MenuItem key="July" value="7">
                        July
                      </MenuItem>{" "}
                      <MenuItem key="August" value="8">
                        August
                      </MenuItem>
                      <MenuItem key="September" value="9">
                        September
                      </MenuItem>
                      <MenuItem key="October" value="10">
                        October
                      </MenuItem>{" "}
                      <MenuItem key="November" value="11">
                        November
                      </MenuItem>{" "}
                      <MenuItem key="December" value="12">
                        December
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>

              <div className="year">
                <Box sx={{ minWidth: 40, marginRight: "1vw" }}>
                  <FormControl fullWidth>
                    <InputLabel>Weeks</InputLabel>
                    <Select
                      value={Week}
                      label="Year"
                      onChange={HandleWeekChange}
                      name="Select Week"
                    >
                      {weeks.map((week, i) => {
                        return (
                          <MenuItem key={i} value={i}>
                            {/* {week.start} - {week.end} */}
                            {new Date(
                              body.year,
                              body.month,
                              week.start
                            ).toDateString()}{" "}
                            -{" "}
                            {new Date(
                              body.year,
                              body.month,
                              week.end
                            ).toDateString()}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </>
          )}
          {DayWise && (
            <BasicDatePicker
              onChange={DateChange}
              label="Timing"
              renderInput={(params) => <TextField {...params} />}
              value={date.Timing}
            />
          )}
          <button className="Go" onClick={FetchValues}>
            Go
          </button>
        </div>

        {/* <div className="notification">
          <NotificationsIcon className="notification-icon" />
          <span className="badge">5</span>

          <div className="closing-alerts-drop-down">
            {Thershold[0].closingOrings && (
              <p>
                {" "}
                <span>
                  {" "}
                  <ReportGmailerrorredIcon className="less-than" />
                </span>
                ORings less than :{" "}
                <span className="closing-value">
                  {Thershold[0].closingOrings}
                </span>
              </p>
            )}

            <p>
              {" "}
              <span>
                {" "}
                <ReportGmailerrorredIcon className="less-than" />
              </span>
              SC Caps less than :{" "}
              <span className="closing-value">
                {Thershold[0].closingScCaps}
              </span>
            </p>
            <p>
              {" "}
              <span>
                {" "}
                <ReportGmailerrorredIcon className="less-than" />
              </span>
              PVC Seals less than :{" "}
              <span className="closing-value">
                {" "}
                {Thershold[0].closingPvcSeal}
              </span>
            </p>
            <p>
              {" "}
              <span>
                {" "}
                <ReportGmailerrorredIcon className="less-than" />
              </span>
              Regulators less than :{" "}
              <span className="closing-value">
                {Thershold[0].closingGoodRegulators}
              </span>
            </p>
            <p>
              {" "}
              <span>
                {" "}
                <ReportGmailerrorredIcon className="less-than" />
              </span>
              Good Valves less than :{" "}
              <span className="closing-value">
                {Thershold[0].closingGoodValves}
              </span>
            </p>
          </div>

        </div> */}
      </div>

      <section className="consumablesWrapper">
        <section className="ConsumablesGraph">
          <div className="PieChart">
            <div className="PieChartDonut">
              <h1>Consumable's Consumption</h1>
              <Doughnut
                data={GraphConsumptionData}
                options={options}
              ></Doughnut>
            </div>
            {TillDateGraph && (
              <>
                {/* <p className="divider"></p> */}
                <div className="alertsHeading">
                  <h1>Closing Alerts</h1>
                  <div className="alerts">
                    <div>
                      <header>
                        <p>ORings </p>
                        <div className="infoIcon">
                          <InfoOutlinedIcon />{" "}
                          <section className="oRings">
                            <p>
                              <CheckCircleOutlineOutlinedIcon className="TickIcon" />{" "}
                              more than 1500
                            </p>
                            <p>
                              <WarningAmberIcon className="alert" /> less than
                              1500
                            </p>
                            <p>
                              <ReportGmailerrorredIcon className="moreAlert" />
                              less than {Thershold[0].oringsThreshold}
                            </p>
                          </section>
                        </div>
                      </header>
                      <h1>
                        <p>
                          {/* <CheckCircleOutlineOutlinedIcon className="TickIcon" />
                        <WarningAmberIcon className="alert" />
                        <ReportGmailerrorredIcon className="moreAlert" /> */}

                          {closingValues.closingOrings >= 1500 && (
                            <>
                              <CheckCircleOutlineOutlinedIcon className="TickIcon" />
                            </>
                          )}

                          {closingValues.closingOrings < 1500 &&
                            closingValues.closingOrings >
                              Thershold[0].oringsThreshold && (
                              <>
                                <WarningAmberIcon className="alert" />
                              </>
                            )}

                          {closingValues.closingOrings <
                            Thershold[0].oringsThreshold && (
                            <>
                              <ReportGmailerrorredIcon className="moreAlert" />
                            </>
                          )}
                        </p>

                        {/* {closingValues.closingOrings} */}
                        <div className="Closing-Alert-Value">
                          <h1>{closingValues.closingOrings}</h1>
                        </div>
                      </h1>
                    </div>
                    <div>
                      <header>
                        <p>PVC Seals </p>
                        <div className="infoIcon">
                          <InfoOutlinedIcon />{" "}
                          <section className="oRings">
                            <p>
                              <CheckCircleOutlineOutlinedIcon className="TickIcon" />{" "}
                              more than 50000
                            </p>
                            <p>
                              <WarningAmberIcon className="alert" /> less than
                              50000
                            </p>
                            <p>
                              <ReportGmailerrorredIcon className="moreAlert" />
                              less than {Thershold[0].pvcThreshold}
                            </p>
                          </section>
                        </div>
                      </header>

                      <h1>
                        <p>
                          {closingValues.closingPvcSeal >= 50000 && (
                            <>
                              <CheckCircleOutlineOutlinedIcon className="TickIcon" />
                            </>
                          )}

                          {closingValues.closingPvcSeal < 50000 &&
                            closingValues.closingPvcSeal >
                              Thershold[0].pvcThreshold && (
                              <>
                                <WarningAmberIcon className="alert" />
                              </>
                            )}

                          {closingValues.closingPvcSeal <
                            Thershold[0].pvcThreshold && (
                            <>
                              <ReportGmailerrorredIcon className="moreAlert" />
                            </>
                          )}
                        </p>
                        {/* {closingValues.closingPvcSeal} */}
                        <div className="Closing-Alert-Value">
                          <h1>{closingValues.closingPvcSeal}</h1>
                        </div>
                      </h1>
                    </div>
                    <div>
                      <header>
                        <p>SC Caps </p>
                        <div className="infoIcon">
                          <InfoOutlinedIcon />{" "}
                          <section className="oRings">
                            <p>
                              <CheckCircleOutlineOutlinedIcon className="TickIcon" />{" "}
                              more than 2500
                            </p>
                            <p>
                              <WarningAmberIcon className="alert" /> less than
                              2500
                            </p>
                            <p>
                              <ReportGmailerrorredIcon className="moreAlert" />
                              less than {Thershold[0].scCapsThreshold}
                            </p>
                          </section>
                        </div>
                      </header>

                      {/* <h1>
                        <p>
                          <CheckCircleOutlineOutlinedIcon className="TickIcon" />
                        </p>
                        {Thershold[0].scCapsThreshold}
                      </h1> */}

                      <h1>
                        <p>
                          {closingValues.closingScCaps >= 2500 && (
                            <>
                              <CheckCircleOutlineOutlinedIcon className="TickIcon" />
                            </>
                          )}

                          {closingValues.closingScCaps < 2500 &&
                            closingValues.closingScCaps >
                              Thershold[0].scCapsThreshold && (
                              <>
                                <WarningAmberIcon className="alert" />
                              </>
                            )}

                          {closingValues.closingScCaps <
                            Thershold[0].scCapsThreshold && (
                            <>
                              <ReportGmailerrorredIcon className="moreAlert" />
                            </>
                          )}
                        </p>
                        {/* {closingValues.closingScCaps} */}
                        <div className="Closing-Alert-Value">
                          <h1>{closingValues.closingScCaps}</h1>
                        </div>
                      </h1>
                    </div>
                    <div>
                      <header>
                        <p>Good Valves </p>
                        <div className="infoIcon">
                          <InfoOutlinedIcon />{" "}
                          <section className="oRings">
                            <p>
                              <CheckCircleOutlineOutlinedIcon className="TickIcon" />{" "}
                              more than 800
                            </p>
                            <p>
                              <WarningAmberIcon className="alert" /> less than
                              800
                            </p>
                            <p>
                              <ReportGmailerrorredIcon className="moreAlert" />
                              less than {Thershold[0].valvesThreshold}
                            </p>
                          </section>
                        </div>
                      </header>

                      {/* <h1>
                        <p>
                          <CheckCircleOutlineOutlinedIcon className="TickIcon" />
                        </p>
                        {Thershold[0].valvesThreshold}
                      </h1> */}
                      <h1>
                        <p>
                          {closingValues.closingGoodValves >= 800 && (
                            <>
                              <CheckCircleOutlineOutlinedIcon className="TickIcon" />
                            </>
                          )}

                          {closingValues.closingGoodValves < 800 &&
                            closingValues.closingGoodValves >
                              Thershold[0].valvesThreshold && (
                              <>
                                <WarningAmberIcon className="alert" />
                              </>
                            )}

                          {closingValues.closingGoodValves <
                            Thershold[0].valvesThreshold && (
                            <>
                              <ReportGmailerrorredIcon className="moreAlert" />
                            </>
                          )}
                        </p>
                        <div className="Closing-Alert-Value">
                          <h1>{closingValues.closingGoodValves}</h1>
                        </div>
                        {/* {closingValues.closingGoodValves} */}
                      </h1>
                    </div>
                    <div>
                      <header>
                        <p>Regulators </p>
                        <div className="infoIcon">
                          <InfoOutlinedIcon />{" "}
                          <section className="oRings">
                            <p>
                              <CheckCircleOutlineOutlinedIcon className="TickIcon" />{" "}
                              more than 500
                            </p>
                            <p>
                              <WarningAmberIcon className="alert" /> less than
                              500
                            </p>
                            <p>
                              <ReportGmailerrorredIcon className="moreAlert" />
                              less than {Thershold[0].regulatorThreshold}
                            </p>
                          </section>
                        </div>
                      </header>

                      {/* <h1>
                        <p>
                          <CheckCircleOutlineOutlinedIcon className="TickIcon" />
                        </p>
                        {Thershold[0].regulatorThreshold}
                        {Math.round(Thershold[0].regulatorThreshold)}
                      </h1> */}

                      <h1>
                        <p>
                          {closingValues.closingGoodRegulators >= 500 && (
                            <>
                              <CheckCircleOutlineOutlinedIcon className="TickIcon" />
                            </>
                          )}

                          {closingValues.closingGoodRegulators < 500 &&
                            closingValues.closingGoodRegulators >
                              Thershold[0].regulatorThreshold && (
                              <>
                                <WarningAmberIcon className="alert" />
                              </>
                            )}

                          {closingValues.closingGoodRegulators <
                            Thershold[0].regulatorThreshold && (
                            <>
                              <ReportGmailerrorredIcon className="moreAlert" />
                            </>
                          )}
                        </p>
                        <div className="Closing-Alert-Value">
                          <h1>{closingValues.closingGoodRegulators}</h1>
                        </div>
                      </h1>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>

        {TillDateGraph && (
          <>
            <section className="closingGraph">
              <div className="ClosingEmptyGood">
                <h1>Closing Good Empty Stocks</h1>
                <Pie data={closingStockData} options={Pieoptions} />
              </div>
              <div className="tillDate">
                <h1> Consumables Till Dates</h1>
                <Bar data={GraphTillDateData} options={Horioptions} />
              </div>
            </section>{" "}
          </>
        )}
      </section>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alert.serverity}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ConsumablesGph;
