import React, { useEffect, useState } from "react";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import BasicDatePicker from "../Forms/Elements/datePicker";
import {
  TextField,
  ThemeProvider,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { theme } from "../theme";
import "../../styling/LoginNavbar.css";
import "../../styling/dashboard.css";

function HomeDashboard() {
  var myHeaders = new Headers({ authorization: process.env.REACT_APP_API_KEY });
  myHeaders.append("Content-Type", "application/json");
  const [alert, setAlert] = useState({
    open: false,
    message: "ola",
    serverity: "success",
  });
  const handleAlertClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const [Thershold, setThershold] = useState([
    {
      oringsThreshold: null,
      pvcThreshold: null,
      scCapsThreshold: null,
      valvesThreshold: null,
      regulatorThreshold: null,
    },
  ]);

  const [tankertansist, setTankertansist] = useState(0);
  const [tankerplant, settankerplant] = useState(0);
  const [consumedValue, setConsumedValue] = useState(0);
  const [deiselUsed, setdeiselUsed] = useState(0);

  // const [badgevalue, setBadgeValue] = useState(0);
  const [badgeArr, setBadgeArr] = useState([]);

  const currDate = new Date();
  let currDateIso = currDate.toISOString().slice(0, 10);

  const [data, setData] = useState({
    // below giving the dummy date can keep empty string also....
    Timing: "2022-05-25T00:00:00.000Z",
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_API_URL}/bulk/TankersLastUpdDate`, {
      method: "POST",
      body: JSON.stringify({
        date: `${currDateIso}T00:00:00.000Z`,
      }),
      headers: myHeaders,
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("last update date");
        let value = data.response[0].previous_last_updated_date + "";
        // setLastUpdDate(value);
        // console.log(data.response[0].previous_last_updated_date);
        setData({
          ...data,
          Timing: value,
        });
      })
      .catch((err) => {
        setAlert({
          open: true,
          message: "Error while fetching date",
          serverity: "error",
        });
        // console.log(err);
      });
  }, []);
  // set date
  const setDate = (e) => {
    setData({
      ...data,
      Timing: new Date(e).toISOString(),
    });
    // FetchValues();
  };
  const FetchValues = async (e) => {
    e.preventDefault();
    await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/bulk/tankersAndTransist`,
      {
        method: "POST",
        body: JSON.stringify({
          date: data.Timing,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.response[0]) {
          let response = data.response[0];
          let tankerTransist = response.tankersInTransit;
          let tankerPlant = response.tankersWaitingAtPlant;
          setTankertansist(tankerTransist);
          settankerplant(tankerPlant);
        } else {
          setAlert({
            open: true,
            serverity: "warning",
            message: "Unable to fetch data",
          });
          setTankertansist(tankertansist);
          settankerplant(tankerplant);
          setConsumedValue(consumedValue);
          setdeiselUsed(deiselUsed);
        }
      })
      .catch((err) => {
        setAlert({
          open: true,
          serverity: "error",
          message: "Error while fetching",
        });
        setTankertansist(0);
        settankerplant(0);
        setConsumedValue(0);
        setdeiselUsed(0);
      });

    await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/consumables/Therhold`,
      {
        method: "POST",
        body: JSON.stringify({
          Timing: data.Timing,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log("thersold values");
        if (data.results[0]) {
          // console.log(data.results[0]);
          let badgeValue = Object.values(data.results[0]);
          let val = [];
          badgeValue.map((data, index) => {
            if (data > 0) {
              val.push(data);
            }
          });
          setBadgeArr(val);
          setThershold(data.results);
        }
        return data;
      })
    .catch((err) => {/*console.log(err)*/});

    await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/power_consumption/consumedValue`,
      {
        method: "POST",
        body: JSON.stringify({
          date: data.Timing,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.response[0]) {
          // console.log(data.response[0]);
          let Value = Object.values(data.response[0]);
          setConsumedValue(Value);
        } else {
          setAlert({
            open: true,
            serverity: "warninig",
            message: "Unable to fetch data",
          });
          // setTankertansist(0);
          // settankerplant(0);
          // setConsumedValue(0);
          // setdeiselUsed(0);
        }
        return data;
      })
      .catch((err) => {
        setAlert({
          open: true,
          serverity: "error",
          message: "Error while fetching",
        });
        // setTankertansist(0);
        // settankerplant(0);
        // setConsumedValue(0);
        // setdeiselUsed(0);
      });

    const deiselUsed = await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/battery_diesel/dieselUsed`,
      {
        method: "POST",
        body: JSON.stringify({
          date: data.Timing,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.response[0]) {
          // console.log(data.response[0]);
          let Value = Object.values(data.response[0]);
          // console.log(Value);
          setdeiselUsed(Value);
        } else {
          setAlert({
            open: true,
            serverity: "warning",
            message: "Unable to fetch data",
          });
          // setdeiselUsed(0);
        }
        return data;
      })
      .catch((err) => {
        setAlert({
          open: true,
          serverity: "error",
          message: "Error while fetching",
        });
        // setdeiselUsed(0);
      });

    // console.log(response);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <section className="DashBoard">
          <section className="insights notification-settler">
            <div>
              <BasicDatePicker
                onChange={setDate}
                label="Timing"
                renderInput={(params) => <TextField {...params} />}
                value={data.Timing}
              />
              <Button
                className="GoBTN"
                sx={{ ml: 2 }}
                color="primary"
                variant="contained"
                onClick={FetchValues}
              >
                Go
              </Button>
            </div>

            <div className="notification">
              <NotificationsIcon className="notification-icon" />
              {/* bell icon */}

              <span className="badge">{badgeArr.length}</span>
              <div className="closing-alerts-drop-down">
                {/* {Thershold[0].closingOrings && ( */}
                {badgeArr.length === 0 ? (
                  <p className="zero-Noti-Container">
                    <span className="zero-Notification">
                      No Notification Yet.
                    </span>
                  </p>
                ) : (
                  <>
                    {Thershold[0].oringsThreshold > 0 && (
                      <>
                        <div className="drop-down-row">
                          <p className="icon">
                            <ReportGmailerrorredIcon className="less-than" />
                          </p>
                          <p className="drop-down-key">ORings less than</p>
                          <h2 className="space-divider">:</h2>
                          <h1 className="drop-down-value">
                            {Thershold[0].oringsThreshold}
                          </h1>
                        </div>
                      </>
                    )}

                    {Thershold[0].scCapsThreshold > 0 && (
                      <>
                        <div className="drop-down-row">
                          <p className="icon">
                            <ReportGmailerrorredIcon className="less-than" />
                          </p>
                          <p className="drop-down-key">SC Caps less than</p>
                          <h2 className="space-divider">:</h2>
                          <h1 className="drop-down-value">
                            {Thershold[0].scCapsThreshold}
                          </h1>
                        </div>
                      </>
                    )}

                    {Thershold[0].pvcThreshold > 0 && (
                      <>
                        <div className="drop-down-row">
                          <p className="icon">
                            <ReportGmailerrorredIcon className="less-than" />
                          </p>
                          <p className="drop-down-key">PVC Seals less than</p>
                          <h2 className="space-divider">:</h2>
                          <h1 className="drop-down-value">
                            {Thershold[0].pvcThreshold}
                          </h1>
                        </div>
                      </>
                    )}

                    {Thershold[0].regulatorThreshold > 0 && (
                      <>
                        <div className="drop-down-row">
                          <p className="icon">
                            <ReportGmailerrorredIcon className="less-than" />
                          </p>
                          <p className="drop-down-key">Regulators less than</p>
                          <h2 className="space-divider">:</h2>
                          <h1 className="drop-down-value">
                            {Thershold[0].regulatorThreshold}
                          </h1>
                        </div>
                      </>
                    )}

                    {Thershold[0].valvesThreshold > 0 && (
                      <>
                        <div className="drop-down-row">
                          <p className="icon">
                            <ReportGmailerrorredIcon className="less-than" />
                          </p>
                          <p className="drop-down-key">Good Valves less than</p>
                          <h2 className="space-divider">:</h2>
                          <h1 className="drop-down-value">
                            {Thershold[0].oringsThreshold}
                          </h1>
                        </div>
                      </>
                    )}
                  </>
                )}
                {/* <p>
                  <span className="closing-value">No Notifications Yet.</span>
                </p> */}
              </div>
            </div>
          </section>

          <div className="content">
            <header>
              <div className="heading">
                <h1>
                  Welcome To <br />{" "}
                  <span style={{ color: "#FF762D" }}>LPG Bottling</span>{" "}
                  Dashboard{" "}
                </h1>
              </div>
            </header>{" "}
            <div className="cards">
              <div>
                {" "}
                <h1>Tankers In Plant</h1>{" "}
                <span>
                  {" "}
                  <CheckCircleOutlinedIcon className="alertIcon" />{" "}
                  {tankerplant}
                </span>
              </div>
              {/* <div>
                {" "}
                <h1>Power Consumed</h1>{" "}
                <span>
                  {" "}
                  <CheckCircleOutlinedIcon className="alertIcon" />{" "}
                  {consumedValue}
                </span>
              </div> */}
              <div>
                {" "}
                <h1>Tankers In Transit</h1>{" "}
                <span>
                  {" "}
                  <CheckCircleOutlinedIcon className="alertIcon" />{" "}
                  {tankertansist}
                </span>
              </div>
            </div>
          </div>
        </section>
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={alert.serverity}
            sx={{ width: "100%" }}
            variant="filled"
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </>
  );
}

export default HomeDashboard;
