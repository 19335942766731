import {
  Grid,
  Box,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { toTitleCase } from "../../utils/utils";
import BasicDatePicker from "../Forms/Elements/datePicker";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

function Preview() {
  var myHeaders = new Headers({"authorization":
      process.env.REACT_APP_API_KEY})
  myHeaders.append("Content-Type", "application/json");
  //   const date = props.date;
  const currDate = new Date();
  let currDateIso = currDate.toISOString().slice(0, 10);
  const [alert, setAlert] = useState({
    open: false,
    message: "ola",
    serverity: "success",
  });
  const handleAlertClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const [date, setDate] = useState({
    Timing: new Date().toISOString().slice(0,10),
  });
  
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/bulk/TankersLastUpdDate`,
      {
        method: "POST",
        body: JSON.stringify({
          date: currDateIso,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log("last update date");
        let value = data.response[0].previous_last_updated_date + "";
        // setLastUpdDate(value);
        // console.log(data.response[0].previous_last_updated_date);
        setDate({
          ...data,
          Timing: value,
        });
      })
      .catch((err) => {
        setAlert({
          open  :true,
          message : 'Error while fetching data',
          serverity : 'error'
        })
        // console.log(err);
      });
  }, []);

  let dataObject = {
    nlsaSalesExEpplDom: 0,
    nlsaSalesExEpplNd: 0,
    bulkReceivedDom: 0,
    bulkReceivedNd: 0,
    tankersInTransit: 0,
    tankersWaitingAtPlant: 0,
    tankersWaitingIntransit: 0,
    bulkOpeningDom: 0,
    bulkOpeningNd: 0,
    bulkClosingDom: 0,
    bulkClosingNd: 0,
    gainLoss: 0,
    bulkPhysicalClosing: 0,
    firstPackedTruckOut: "00:00:00",
    lastPackedTruckOut: "00:00:00",
    trucksInsidePlant: 0,
    totalIndentsExecuted: 0,
    pendingIndentsInIms: 0,
    ResiduvalPRCNfor425: 0,
  };
  const [category, setCategory] = useState("14.2 kg");
  const [data, setdata] = useState(dataObject);

  // fetching categories data
  const fetchFilling = () => {
    // myHeaders.append("Content-Type", "application/json");
    var options = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        date: date.Timing.slice(0,10),
        category: category,
      }),
    };
    fetch(`${process.env.REACT_APP_BASE_API_URL}/bulk`, options)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.response[0]) {
          let sorted = Object.fromEntries(
            Object.entries(result.response[0]).sort((b, a) => a[1] - b[1])
          );
          setdata(sorted);
        } else {
          setAlert({
            open: true,
            message: "no data found",
            serverity: "error",
          });          setdata(dataObject);
        }
        // console.log(result.response[0],category)
      })
      .catch((error) => {/*console.log("error", error)*/});
  };
  const DateChange = (e) => {
    setDate({
      ...date,
      Timing: new Date(e).toISOString(),
    });
  };
  useEffect(fetchFilling, [date]);
  // fetchFilling();
  return (
    <>
      <Box sx={{ py: 2, px: 2 }}>
        <Grid container sx={{ py: 3 }}>
          <Grid>
            <BasicDatePicker
              onChange={DateChange}
              label="Date"
              renderInput={(params) => <TextField {...params} />}
              value={date.Timing}
            />
          </Grid>
        </Grid>
        {
          <TableContainer component={Paper} sx={{ px: 2, py: 2 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {/* {Object.keys(data).map((k) => {
                  console.log(
                    `<TableRow
                         key='${k}'
                         sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                       >
                         <TableCell component="th" scope="row">
                           ${toTitleCase(k)}
                         </TableCell>
                       </TableRow>`
                  );
                  if (k === "date") return;
                  return (
                    <TableRow
                      key={k}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {toTitleCase(k)}
                      </TableCell>
                      <TableCell align="right">{data[k]}</TableCell>
                    </TableRow>
                  );
                })} */}

                <TableRow
                  key="tankersWaitingIntransit"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Tankers Waiting Intransit
                  </TableCell>
                  <TableCell align="right">
                    {data.tankersWaitingIntransit} MT
                  </TableCell>
                </TableRow>

                <TableRow
                  key="bulkReceivedDom"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Bulk Received Dom
                  </TableCell>
                  <TableCell align="right">{data.bulkReceivedDom} MT</TableCell>
                </TableRow>
                <TableRow
                  key="nlsaSalesExEpplDom"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Nlsa Sales Ex Eppl Dom
                  </TableCell>
                  <TableCell align="right">
                    {data.nlsaSalesExEpplDom} MT
                  </TableCell>
                </TableRow>
                <TableRow
                  key="bulkPhysicalClosing"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Bulk Physical Closing
                  </TableCell>
                  <TableCell align="right">
                    {data.bulkPhysicalClosing} MT
                  </TableCell>
                </TableRow>
                <TableRow
                  key="bulkClosingDom"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Bulk Closing Dom
                  </TableCell>
                  <TableCell align="right">{data.bulkClosingDom} MT</TableCell>
                </TableRow>
                <TableRow
                  key="bulkOpeningDom"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Bulk Opening Dom
                  </TableCell>
                  <TableCell align="right">{data.bulkOpeningDom} MT</TableCell>
                </TableRow>
                <TableRow
                  key="tankersInTransit"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Tankers In Transit
                  </TableCell>
                  <TableCell align="right">
                    {data.tankersInTransit} nos
                  </TableCell>
                </TableRow>
                <TableRow
                  key="bulkOpeningNd"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Bulk Opening Nd
                  </TableCell>
                  <TableCell align="right">{data.bulkOpeningNd} MT</TableCell>
                </TableRow>
                <TableRow
                  key="tankersWaitingAtPlant"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Tankers Waiting At Plant
                  </TableCell>
                  <TableCell align="right">
                    {data.tankersWaitingAtPlant} nos
                  </TableCell>
                </TableRow>
                <TableRow
                  key="nlsaSalesExEpplNd"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Nlsa Sales Ex Eppl Nd
                  </TableCell>
                  <TableCell align="right">
                    {data.nlsaSalesExEpplNd} MT
                  </TableCell>
                </TableRow>
                <TableRow
                  key="totalIndentsExecuted"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Total Indents Executed
                  </TableCell>
                  <TableCell align="right">
                    {data.totalIndentsExecuted} nos
                  </TableCell>
                </TableRow>
                <TableRow
                  key="bulkClosingNd"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Bulk Closing Nd
                  </TableCell>
                  <TableCell align="right">{data.bulkClosingNd} MT</TableCell>
                </TableRow>
                <TableRow
                  key="pendingIndentsInIms"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Pending Indents In Ims
                  </TableCell>
                  <TableCell align="right">
                    {data.pendingIndentsInIms} nos
                  </TableCell>
                </TableRow>
             <TableRow
                  key="ResiduvalPRCNfor425"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Residuval PRCN for 425
                  </TableCell>
                  <TableCell align="right">
                    {data.ResiduvalPRCNfor425} MT
                  </TableCell>
                </TableRow>
                <TableRow
                  key="gainLoss"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Gain Loss
                  </TableCell>
                  <TableCell align="right">{data.gainLoss} MT</TableCell>
                </TableRow>
                <TableRow
                  key="bulkReceivedNd"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Bulk Received Nd
                  </TableCell>
                  <TableCell align="right">{data.bulkReceivedNd} MT</TableCell>
                </TableRow>
                <TableRow
                  key="firstPackedTruckOut"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    First Packed Truck Out
                  </TableCell>
                  <TableCell align="right">
                    {data.firstPackedTruckOut}
                  </TableCell>
                </TableRow>
                <TableRow
                  key="lastPackedTruckOut"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Last Packed Truck Out
                  </TableCell>
                  <TableCell align="right">{data.lastPackedTruckOut}</TableCell>
                </TableRow>
                <TableRow
                  key="trucksInsidePlant"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Trucks Inside Plant
                  </TableCell>
                  <TableCell align="right">
                    {data.trucksInsidePlant} nos
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        }
         <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={alert.serverity}
            sx={{ width: "100%" }}
            variant="filled"
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
}

export default Preview;
