import { decodeToken } from "react-jwt";
export function toTitleCase(name) {
  if (name.toUpperCase() === name) return name;
  const result = name.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

export const isSuperAdmin = () => {
  var token = localStorage.getItem("token");
  var role = decodeToken(token).role;
  return role === "super-admin";
};

export const isAdmin = () => {
  var token = localStorage.getItem("token");
  var role = decodeToken(token).role;
  return role === "admin" || role === "super-admin";
};

export const userEmail = () =>{
  var token = localStorage.getItem("token");
  var email = decodeToken(token).email;
  return email 
}