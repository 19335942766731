import "./uploadSheets.scss";
import BasicDatePicker from "../Elements/datePicker";
import {
  TextField,
  Button,
  Alert,
  Snackbar,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useEffect, useState } from "react";
export default function UploadSheets() {
  const [date, setDate] = useState(new Date());
  const [sheet, setSheet] = useState(null);
  const [formError, setFormError] = useState(null);
  const [fileUploadMode, setFileUploadMode] = useState("date");
  const [curMonth,setCurMonth] = useState(0);
  const [curYear,setCurYear] = useState(2023);
  const [years,setYears] = useState([]);
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    serverity: "success",
  });
  const handleAlertClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };
  const uploadSheet = (e) => {
    const formData = new FormData();
    if (!sheet) {
      setFormError("please upload a sheet");
      return;
    }
    setFormError(null);
    e.target.textContent = "sending...";
    if( fileUploadMode === 'date')
      formData.append("date", date.toISOString().slice(0, 10));
    else{
      formData.append("year",curYear);
      formData.append("month",curMonth);
    }
    formData.append("file", sheet);
    var requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_API_URL}/sheets${fileUploadMode === 'month' ? '/month' : ''}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.message) {
          setAlert({
            open: true,
            message: result.message,
            serverity:
              result.message === "Upload Successful" ? "success" : "warning",
          });
          document.querySelector('#file-input').value = '';
          setSheet(null);
          e.target.textContent = "send";
        }
      })
      .catch((error) => {
        e.target.textContent = "send";
        // console.log("error", error);
        setAlert({
          open: true,
          message: "Error occured. Try again",
          serverity: "error",
        });
      });
  };
  useEffect(()=>{
    var curYear = new Date().getFullYear();
    var temp = [];
    for(var i = 2020; i <= curYear; i++){
      temp.push(i);
    }
    setYears(temp);
  },[]);
  useEffect(()=>{
    document.querySelector('#file-input').value = '';
    setSheet(null);
  },[fileUploadMode])
  return (
    <>
      <div className="upload-sheets">
        <div className="title">
          <h1>Upload Sheets</h1>
        </div>
        <div className="input-field-container">
          <p>Mode:</p>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="date"
            name="radio-buttons-group"
            className="radio-btn-group"
            onChange={(e) => {
              setFileUploadMode(e.target.value);
            }}
          >
            <FormControlLabel value="date" control={<Radio />} label="Date" />
            <FormControlLabel
              value="month"
              control={<Radio />}
              label="Month"
            />
          </RadioGroup>
        </div>
        <div className="form">
          {fileUploadMode === "date" ? (
            <div className="input-field-container">
              <BasicDatePicker
                onChange={setDate}
                label="Date"
                renderInput={(params) => <TextField {...params} />}
                value={date}
              />
            </div>
          ) : (
            <div className="input-field-container">
              <FormControl className="select-el">
                <InputLabel id="month">Month</InputLabel>
                <Select
                  name="Month"
                  labelId="month"
                  value={curMonth}
                  label="Month"
                  onChange={(e) => {setCurMonth(e.target.value)}}
                >
                  {monthNames.map((month, i) => {
                    return (
                      <MenuItem key={month} value={i}>
                        {month}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl className="select-el">
                <InputLabel id="Year">Year</InputLabel>
                <Select
                  name="Year"
                  labelId="Year"
                  value={curYear}
                  label="Year"
                  onChange={(e) => {setCurYear(e.target.value)}}
                >
                  {years.map((year) => {
                    return (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          )}
          <div className="input-field-container">
            <TextField
              name="file-upload"
              id="file-input"
              accept=".xlsx"
              onChange={(e) => {
                setSheet(e.target.files[0]);
              }}
              type="file"
              variant="outlined"
              className="sheet"
              inputProps={{ accept: ".xlsx" }}
            />
          </div>
        </div>
        <div className="error">
          <p>{formError ? `*${formError}` : ""}</p>
        </div>
        <div className="submit">
          <Button variant="contained" onClick={uploadSheet}>
            Submit
          </Button>
        </div>
      </div>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alert.serverity}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
}
