import React from "react";
import { useState } from "react";
import { Navigate } from "react-router";
import { Button, TextField, ThemeProvider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { theme } from "./theme";
function Protected() {
  const [loggedIn, setLoggedState] = useState(false);

  const navigate = useNavigate();

  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const changeUser = (e) => {
    setUsername(e.target.value);
  };

  const changePassword = (e) => {
    setPassword(e.target.value);
  };

  const Submit = (e) => {
    // e.preverntDefault()
    var body = {
      email: userName,
      password: password,
    };
    // console.log(body);
    var myHeaders = new Headers({"authorization":
      process.env.REACT_APP_API_KEY})
    myHeaders.append("Content-Type", "application/json");
    var options = {
      method: "POST",
      body: JSON.stringify(body),
      headers: myHeaders,
    };
    //call end point for login
    fetch(`${process.env.REACT_APP_BASE_API_URL}/auth/login`, options)
      .then((res) => {
        if (res.status !== 200) {
          alert("invalid credentials");
          navigate("/login");
          return;
        } else {
          return res.json();
        }
      })
      .then((data) => {
        // console.log(data);
        if (data.user.email) {
          localStorage.setItem("token", data.user.token);
          setLoggedState(true)
          navigate("/");
        } else {
          window.alert("invalid credentails");
        }
      })
      .catch((err) => {
        // console.log(err);
        alert("invalid credentials");
        // alert error
      });
  };
  document.onkeydown = function () {
    if (window.event.keyCode === "13") {
      Submit();
    }
  };

 
  return loggedIn ? (
    <Navigate to="/" />
  ) : (
    <>
      <ThemeProvider theme={theme}>
        {" "}
        <>
          <section className="login">
            <div className="LoginFormHeading">
              <h1>Dashboard Login</h1>
            </div>
            <form>
              <div className="loginContainer">
                <div>
                  <TextField
                    margin="dense"
                    variant="standard"
                    label="Email"
                    type="text"
                    onChange={changeUser}
                    value={userName}
                  />
                </div>
                <div>
                  <TextField
                    type="password"
                    onChange={changePassword}
                    value={password}
                    margin="dense"
                    variant="standard"
                    label="Password"
                    id="password"
                  />
                </div>
                <div>
                  <Button variant="contained" onClick={Submit}>
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          </section>
        </>
      </ThemeProvider>
    </>
  );
}

export default Protected;
