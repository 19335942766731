import React from "react";
import { useState } from "react";
import {
  Grid,
  TextField,
} from "@mui/material";
import BasicDatePicker from "./Elements/datePicker";
import Button from "@mui/material/Button";
import InputField from "./Elements/InputField";
import {Alert,Snackbar} from "@mui/material";

function BatteryDeisel() {
  var myHeaders = new Headers({"authorization":
      process.env.REACT_APP_API_KEY})
  myHeaders.append("Content-Type", "application/json");
  const [alert, setAlert] = useState({
    open: false,
    message: "ola",
    serverity: "success",
  });
  const handleAlertClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };
  function handleKeyPress(event) {
    if (event.key === "-" || event.key === "+") {
      event.preventDefault(); // prevent the minus symbol from being entered
    }
  }
  const [data, setData] = useState({
    date: `${new Date().toISOString()}`,
    engineTempC: 0,
    engineTempF: 0,
    engineOilLevel: 0,
    rpm: 0,
    frequencyH2: 0,
    coollantOilLevel: 0,
    batteryCondition: 0,
    dieselLevel: 0,
    engineTimeStart: '10:00',
    engineTimeStop: 0,
    outputVoltageRY: 0,
    outputVoltageYB: 0,
    outputVoltageBR: 0,
    runningMins: 0,
    remarks: 'NA',
  });
  const [previewData, setPreviewData] = useState({});

  // update function is here....
  const Submit = async (e) => {
    e.preventDefault();
    // console.log(data);
    await fetch(`${process.env.REACT_APP_BASE_API_URL}/battery_diesel`, {
      method: "PUT",
      // body: JSON.stringify(data),
      body: JSON.stringify(previewData),
      headers: myHeaders,
    })
      .then((res) => {
        if(res.status === 200){
          return res.json()
        }
      })
      .then((data) => {
        // console.log(data);
        setAlert({
          open : true,
          message : 'Added data successfully',
          severity : 'success'
        });
      }).catch(err=>{
        // console.log(err);
        setAlert({
          open : true,
          message : 'Error while adding data',
          severity : 'error'
        });
      });
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({
      ...data,
      [name]: value,
    });
  };
  const setDate = (e) => {
    setData({
      ...data,
      date: new Date(e).toISOString(),
    });
  };

  return (
    <>
    {/* <Navbar/> */}
      <form onSubmit={Submit}>
        <div className="heading">
          <p>Battery & Diesel</p>
        </div>
        <div className="dateCate">
          <BasicDatePicker
            onChange={setDate}
            label="date"
            renderInput={(params) => <TextField {...params} />}
            value={data.date}
          />
        </div>

        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="center"
          textAlign="center"
          justifyContent="space-around"
        >
          {/* {Object.keys(data).map((key) => {
             console.log(
              `<InputField label="${toTitleCase(key)}" value={data.${key}} inputChange={handleInputChange} keyPress={handleKeyPress} name="${key}"  />`
            );
            if (key === "CATEGORY" || key =="date") return "";
            return (
              <InputField
                label={toTitleCase(key)}
                value={data[key]}
                inputChange={handleInputChange}
                keyPress={handleKeyPress}
                name={key}
              />
            );
          })} */}
          {/* <InputField
            label="Date"
            value={data.date}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="date"
          /> */}
          <InputField
            label="Engine Temperature In celsius"
            value={data.engineTempC}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="engineTempC"
          />
          <InputField
            label="Engine Temperature In Fahrenheit"
            value={data.engineTempF}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="engineTempF"
          />
          <InputField
            label="Engine Oil Level"
            value={data.engineOilLevel}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="engineOilLevel"
          />
          <InputField
            label="Rpm"
            value={data.rpm}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="rpm"
          />
          <InputField
            label="Frequency H2"
            value={data.frequencyH2}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="frequencyH2"
          />
          <InputField
            label="Coolant Oil Level"
            value={data.coollantOilLevel}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="coollantOilLevel"
          />
          <InputField
            label="Battery Condition"
            value={data.batteryCondition}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="batteryCondition"
          />
          <InputField
            label="Diesel Level"
            value={data.dieselLevel}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="dieselLevel"
          />
          <InputField
            label="Engine Time Start"
            value={data.engineTimeStart}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="engineTimeStart"
          />
          <InputField
            label="Engine Time Stop"
            value={data.engineTimeStop}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="engineTimeStop"
          />
          <InputField
            label="Output Voltage R Y"
            value={data.outputVoltageRY}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="outputVoltageRY"
          />
          <InputField
            label="Output Voltage Y B"
            value={data.outputVoltageYB}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="outputVoltageYB"
          />
          <InputField
            label="Output Voltage B R"
            value={data.outputVoltageBR}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="outputVoltageBR"
          />
          <InputField
            label="Running Mins"
            value={data.runningMins}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="runningMins"
          />
          <InputField
            label="Remarks"
            value={data.remarks}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="remarks"
          />
        </Grid>

        <div className="SubmitInp">
          <Button onClick={Submit} variant="contained">
            Submit
          </Button>
        </div>
      </form>
      <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={alert.serverity}
            sx={{ width: "100%" }}
            variant="filled"
          >
            {alert.message}
          </Alert>
        </Snackbar>
    </>
  );
}

export default BatteryDeisel;
