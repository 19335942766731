import React from "react";
import { Link } from "react-router-dom";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  ThemeProvider,
  Grid,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import { useNavigate, Navigate } from "react-router-dom";
import { Box } from "@mui/system";
import { useState } from "react";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { theme } from "../theme";
import energonLogo from "../../Images/logo.png";
import logoIcon from "../../Images/favicon.png";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import BasicDatePicker from "../Forms/Elements/datePicker";
import Filling from "./filling";
import Maintainance from "./Maintenance";
import Trucks from "./TankersTruck";
import PowerConsumption from "./PowerConsumption";
import Valves from "./ValvesRegComs";
import BatteryDeisel from "./BatteryDeisel";
import ValvesRegComs from "./ValvesRegComs";
import TankersTruck from "./TankersTruck";
import UploadFormlicese from "./UploadFormlicese";
import UploadSheets from "./UplaodSheets/UploadSheet";
function Navbar() {
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const [dateError, setDateError] = useState(null);
  const [currentForm, setCurrentForm] = useState("filling");
  const [mblFilling, setmblFilling] = useState(true);
  const [mblConsumables, setmblConsumables] = useState(false);
  const [mblMaintainence, setmblMaintainence] = useState(false);
  const [mblPower, setmblPower] = useState(false);
  const [mblbatterydeisel, setmblbatterydeisel] = useState(false);
  const [mblBulk, setmblBulk] = useState(false);
  const [mblLicense, setmblLicense] = useState(false);
  const [mbSheets, setMbSheets] = useState(false);

  const handleDownloadDailogClose = () => {
    setDownloadDailog(false);
  };
  const showSheets = () => {
    setMbSheets(true);
    setmblFilling(false);
    setmblConsumables(false);
    setmblMaintainence(false);
    setmblPower(false);
    setmblLicense(false);

    setmblbatterydeisel(false);
    setmblBulk(false);
    document.querySelector("#menuToggle input").checked = false;
  };
  const ShowFilling = (e) => {
    setMbSheets(false);
    setmblFilling(true);
    setmblConsumables(false);
    setmblMaintainence(false);
    setmblPower(false);
    setmblLicense(false);

    setmblbatterydeisel(false);
    setmblBulk(false);
    document.querySelector("#menuToggle input").checked = false;
  };
  const ShowBatterDiesel = (e) => {
    setMbSheets(false);
    setmblFilling(false);
    setmblConsumables(false);
    setmblMaintainence(false);
    setmblLicense(false);

    setmblPower(false);
    setmblbatterydeisel(true);
    setmblBulk(false);
    document.querySelector("#menuToggle input").checked = false;
  };
  const ShowMaintenance = (e) => {
    setMbSheets(false);
    setmblFilling(false);
    setmblConsumables(false);
    setmblMaintainence(true);
    setmblPower(false);
    setmblLicense(false);

    setmblbatterydeisel(false);
    setmblBulk(false);
    document.querySelector("#menuToggle input").checked = false;
  };
  const ShowPower = (e) => {
    setMbSheets(false);
    setmblFilling(false);
    setmblConsumables(false);
    setmblMaintainence(false);
    setmblPower(true);
    setmblbatterydeisel(false);
    setmblLicense(false);

    setmblBulk(false);
    document.querySelector("#menuToggle input").checked = false;
  };

  const ShowConsumables = (e) => {
    setMbSheets(false);
    setmblFilling(false);
    setmblConsumables(true);
    setmblMaintainence(false);
    setmblPower(false);
    setmblbatterydeisel(false);
    setmblBulk(false);
    setmblLicense(false);

    document.querySelector("#menuToggle input").checked = false;
  };

  const ShowBulk = (e) => {
    setMbSheets(false);
    setmblFilling(false);
    setmblConsumables(false);
    setmblMaintainence(false);
    setmblPower(false);
    setmblbatterydeisel(false);
    setmblBulk(true);
    setmblLicense(false);

    document.querySelector("#menuToggle input").checked = false;
  };

  const showLicense = (e) => {
    setMbSheets(false);
    setmblFilling(false);
    setmblConsumables(false);
    setmblMaintainence(false);
    setmblPower(false);
    setmblbatterydeisel(false);
    setmblBulk(false);
    setmblLicense(true);
    document.querySelector("#menuToggle input").checked = false;
  };

  // code for closing side nav bar on clicking anywhere
  document.addEventListener("click", function (event) {
    var ignoreElement = document.querySelector("#menuToggle");
    // console.log(ignoreElement)
    if (ignoreElement !== null) {
      var clickElement = ignoreElement.contains(event.target);
      if (!clickElement) {
        document.querySelector("#menuToggle input").checked = false;
      }
    }
  });

  const handleDateChange = (e) => {
    setDate(e);
    setLinkLoading(true);
    setDownloadLink(null);
    // console.log(e.toISOString().slice(0, 10));
    // fetch download link
    var myHeaders = new Headers({
      authorization: process.env.REACT_APP_API_KEY,
    });
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      date: e.toISOString().slice(0, 10),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_API_URL}/download`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.file) {
          setDownloadLink(result.file.Location);
          setLinkLoading(false);
          setDateError(null);
        } else {
          setDateError("An error occured. Try again");
          setLinkLoading(false);
        }
      })
      .catch((error) => {
        setDateError("An error occured. Try again");
        // console.log("error", error);
      });
  };
  const [downloadDailog, setDownloadDailog] = useState(false);
  const [downloadLink, setDownloadLink] = useState(null);
  const [linkLoading, setLinkLoading] = useState(false);
  const hadleDownloadDailogOpen = () => {
    setDownloadDailog(true);
    setLinkLoading(false);
    setDownloadLink(false);
  };
  const handleFormChange = (e) => {
    setCurrentForm(e.target.value);
  };
  const logout = () => {
    localStorage.clear("");
    navigate("/login");
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <>
          <div className="Navbar">
            <Link to="/">
              <div className="logo">
                <img src={energonLogo} alt="" />
              </div>
            </Link>
            <Grid container justifyContent="end" alignItems="center">
              <Grid item xs={3} sx={{ px: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="Forms">Forms</InputLabel>
                  <Select
                    name="CATEGORY"
                    labelId="Forms"
                    value={currentForm}
                    onChange={handleFormChange}
                    label="Category"
                  >
                    <MenuItem key="Filling" value="filling">
                      {" "}
                      Filling
                    </MenuItem>

                    <MenuItem key="Maintainance" value="maintainance">
                      {" "}
                      Maintainance
                    </MenuItem>

                    <MenuItem key="Battery" value="battery">
                      {" "}
                      Battery & Diesel
                    </MenuItem>

                    <MenuItem key="Valves" value="valves">
                      {" "}
                      Consumables
                    </MenuItem>

                    <MenuItem key="Power" value="power">
                      {" "}
                      Power
                    </MenuItem>

                    <MenuItem key="Tanker Trucks" value="trucks">
                      {" "}
                      Bulk
                    </MenuItem>

                    <MenuItem key="upload license" value="license">
                      {" "}
                      Upload License
                    </MenuItem>

                    <MenuItem key="upload sheets" value="sheets">
                      {" "}
                      Upload Sheets
                    </MenuItem>

                    {/* <Link className="link" to="/downloadSheet">
                      <MenuItem key="Download Sheet" value="Download Sheet">
                        {" "}
                        Download Sheet
                      </MenuItem>
                    </Link> */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={hadleDownloadDailogOpen}
                  startIcon={<CloudDownloadIcon />}
                  sx={{ mr: 2 }}
                >
                  Download
                </Button>
              </Grid>
              <Grid item>
                {/* logout btn below */}
                <Button
                  color="primary"
                  onClick={logout}
                  startIcon={<PowerSettingsNewIcon />}
                ></Button>
              </Grid>
            </Grid>
          </div>
          {/* Mobile Navbar from here.. */}
          <main className="mobileView">
            <header>
              <nav>
                <div id="menuToggle">
                  <input type="checkbox" />
                  <span></span>
                  <span></span>
                  <span></span>
                  <ul id="menu">
                    <a href="#">
                      <li onClick={ShowFilling}>Filling</li>
                    </a>
                    <a>
                      <li onClick={ShowBatterDiesel}>Battery and Diesel</li>
                    </a>
                    <a href="#">
                      <li onClick={ShowMaintenance}>Maintenance</li>
                    </a>
                    <a href="#">
                      <li onClick={ShowPower}>Power</li>
                    </a>
                    <a href="#">
                      <li onClick={ShowConsumables}>Consumables</li>
                    </a>

                    <a href="#">
                      <li onClick={ShowBulk}>Bulk</li>
                    </a>

                    <a href="#">
                      <li onClick={showLicense}>License</li>
                    </a>

                    <a href="#">
                      <li onClick={showSheets}>Upload Sheet</li>
                    </a>

                    <div className="downloadBtn">
                      <Link to="/">
                        <button>Home</button>
                      </Link>
                      <button onClick={hadleDownloadDailogOpen}>
                        Download
                      </button>
                    </div>
                  </ul>
                </div>
                <div className="logo-title">
                  <div className="logo-mobile">
                    <div className="ImageHeading">
                      {/* <h2 className="title"> */}
                      <a href="/">
                        <img src={energonLogo} alt="not found" />
                      </a>
                      {/* </h2> */}
                    </div>
                    <div className="logOutBtn">
                      <Button
                        color="primary"
                        onClick={logout}
                        startIcon={<PowerSettingsNewIcon />}
                        title="Log Out"
                      ></Button>
                    </div>
                  </div>
                </div>
              </nav>
            </header>
            <div className="container">
              {mblFilling && (
                <>
                  <div className="MobileFilling">
                    <Filling />
                  </div>
                </>
              )}

              {mblConsumables && (
                <>
                  <div className="MobileConsumables">
                    <ValvesRegComs />
                  </div>
                </>
              )}

              {mblbatterydeisel && (
                <>
                  <div className="MobileBattery">
                    <BatteryDeisel />
                  </div>
                </>
              )}

              {mblMaintainence && (
                <>
                  <div className="MobileMaintainence">
                    <Maintainance />
                  </div>
                </>
              )}

              {mblBulk && (
                <>
                  <div className="MobileBulk">
                    <TankersTruck />
                  </div>
                </>
              )}

              {mblPower && (
                <>
                  <div className="MobilePower">
                    <PowerConsumption />
                  </div>
                </>
              )}

              {mblLicense && (
                <>
                  <div className="MobileLicense">
                    <UploadFormlicese />
                  </div>
                </>
              )}

              {mbSheets && (
                <>
                  <UploadSheets />
                </>
              )}
            </div>
          </main>
        </>
        <Dialog
          open={downloadDailog}
          onClose={handleDownloadDailogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Download WA Sheet</DialogTitle>
          <DialogContent>
            <Box mt={3}>
              <Grid
                container
                spacing={3}
                alignItems="center"
                flexDirection="column"
              >
                <Grid item>
                  <BasicDatePicker
                    onChange={handleDateChange}
                    label="Date"
                    renderInput={(params) => <TextField {...params} />}
                    value={date}
                  />
                </Grid>
                <Grid item alignItems="center">
                  {downloadLink && (
                    <Button
                      href={downloadLink}
                      variant="outlined"
                      startIcon={<CloudDownloadIcon />}
                    >
                      Download
                    </Button>
                  )}
                  {linkLoading && <CircularProgress />}
                  {dateError ? (
                    <span className="error">{dateError}</span>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </ThemeProvider>

      <div className="deskStopForm">
        {currentForm === "filling" && <Filling />}
        {currentForm === "maintainance" && <Maintainance />}
        {currentForm === "battery" && <BatteryDeisel />}
        {currentForm === "valves" && <Valves />}
        {currentForm === "power" && <PowerConsumption />}
        {currentForm === "trucks" && <Trucks />}
        {currentForm === "license" && <UploadFormlicese />}
        {currentForm === "sheets" && <UploadSheets />}
      </div>
    </>
  );
}

export default Navbar;
