import React from "react";
import { useState } from "react";
import CategoriesPreview from './categoriesPreview';
import MaintainancePreview from './maintainancePreview';
import PowerConsumptionPreview from './powerConsumption';
import ConsumablesPreview from './consumables';
import BatteryDieselPreview from './batteryDiesel';
import BulkPreview from './bulkPreview'
import {Box, MenuItem, Select, InputLabel, FormControl} from '@mui/material';
import License from "./License";

function Preview(){
    
    const [currentTable,setCurrentTable] = useState('filling');
    const changeTable = (e)=>{
        setCurrentTable(e.target.value)
    }
    return(
        <>
            <Box sx={{py : 3,px : 4}}>
                <FormControl>
                <InputLabel id="demo-simple-select-label" className="demoLabel">Table</InputLabel>
            <Select
                  style={{ backgroundColor: "whitesmoke"}}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currentTable}
                  label="Table"
                  onChange={changeTable}
                  className="PreviewSelectionMobile"
                >
                  <MenuItem value="filling">
                    Filling
                  </MenuItem>
                  <MenuItem value="consumables" >
                    Consumables
                  </MenuItem>
                  <MenuItem value="power">
                    Power Consumption
                  </MenuItem>
                  <MenuItem value="maintainance" >
                    Maintainance
                  </MenuItem>
                  <MenuItem value="battery" >
                    Battery Diesel
                  </MenuItem>
                  <MenuItem value="bulk" >
                    Bulk
                  </MenuItem>
                  <MenuItem value="license" >
                    License
                  </MenuItem>


                </Select>
                </FormControl>
                {
                    (currentTable === 'filling' && <CategoriesPreview/>) ||
                    (currentTable === 'maintainance' && <MaintainancePreview/>) ||
                    (currentTable === 'power' && <PowerConsumptionPreview/>) ||
                    (currentTable === 'consumables' && <ConsumablesPreview/>) ||
                    (currentTable === 'battery' && <BatteryDieselPreview/>) ||
                    (currentTable === 'bulk' && <BulkPreview/>) ||
                    (currentTable === 'license' &&  <License/> )
                }
            </Box>
        </>
    )
}

export default Preview;