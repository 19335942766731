import React from "react";
import { useState } from "react";
import {
  Grid,
  MenuItem,
  InputLabel,
  Select,
  Box,
  FormControl,
  TextField,
  Input,
  Alert,
  Typography,
  Tooltip,
} from "@mui/material";
// import InputField from "./Elements/InputField";
import { isSuperAdmin, toTitleCase } from "../../utils/utils";
import BasicDatePicker from "./Elements/datePicker";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputField from "./Elements/InputField";
import { Link } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Navbar from "../Navbar";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

function ValvesRegComs() {
  var myHeaders = new Headers({ authorization: process.env.REACT_APP_API_KEY });
  myHeaders.append("Content-Type", "application/json");
  const fillingCaution = `
    Fill all categories of cylinder filling before entering  data
  `;
  const categories = [
    "14.2 kg",
    "19kg",
    "35kg",
    "47.5kg",
    "425kg",
    "5kg red",
    "5kg blue",
    "2kg",
  ];
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "ola",
    serverity: "success",
  });
  const handleAlertClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  function handleKeyPress(event) {
    if (event.key === "-" || event.key === "+") {
      event.preventDefault(); // prevent the minus symbol from being entered
    }
  }
  const currDate = new Date();
  const beforeDateStr = currDate.toISOString().slice(0, 10);

  // console.log(`${beforeDateStr}T00:00:00.000Z`);

  const [data, setData] = useState({
    date: `${beforeDateStr}T00:00:00.000Z`,
    newValvesReceiptThroughTranshipment: 0,
    newValvesReceiptThroughParty: 0,
    defectiveValvesDespatchedToAnantapur: 0,
    closingGoodValves: 0,
    closingDefectiveValves: 0,
    newRegulatorsReceiptThroughTranshipment: 0,
    newRegulatorsReceiptThroughParty: 0,
    regulatorsIssuedToDistributors: 0,
    defectiveRegulatorsReceiptFromDistributors: 0,
    closingGoodRegulators: 0,
    closingDefectiveRegulators: 0,
    defectiveRegulatorsDespatchedToAnantapur: 0,
    oringsReceipt: 0,
    pvcSealReceipt: 0,
    scCapsReceipt: 0,
    oringsConsumption: 0,
    pvcSealConsumption: 0,
    scCapsConsumption: 0,
    closingOrings: 0,
    closingPvcSeal: 0,
    closingScCaps: 0,
  });
  const [previewData, setPreviewData] = useState({});

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    background: "#fff",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
    height: "80%",
    padding: "2rem",
    borderRadius: ".75rem",
  };

  const Submit = async (e) => {
    e.preventDefault();
    // console.log("clicked Valves");

    let CategoryValvesValues = await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/consumables`,
      {
        method: "PUT",
        // body: JSON.stringify(data),
        body: JSON.stringify(previewData),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if (data.response) {
          setAlert({
            open: true,
            message: "Successfully added data",
            serverity: "success",
          });
        } else {
          setAlert({
            open: true,
            message: "Duplicate entry!",
            serverity: "warning",
          });
        }
        return data;
      })
      .catch((err) => {
        setAlert({
          open: true,
          message: "Error adding data!",
          serverity: "error",
        });
      });
    // console.log(CategoryValvesValues);
  };
  const preview = async (e) => {
    e.preventDefault();
    // get preview data
    var requestOptions = {
      method: "POST",
      body: JSON.stringify(data),
      headers: myHeaders,
    };
    try {
      const result = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/consumables/preview`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: myHeaders,
        }
      ).then((result) => result.json());
      if (result.result) {
        setPreviewData({
          ...result.result,
        });
        setAlert({
          open: true,
          message: "Preview fetched successfully",
          serverity: "success",
        });
        handleOpen();
      } else {
        setAlert({
          open: true,
          message: "data not available",
          serverity: "error",
        });
      }
      // console.log(result);
    } catch (err) {
      // console.log(err);
      setAlert({
        open: true,
        message: "Error. Try again!",
        serverity: "error",
      });
    }
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({
      ...data,
      [name]: value,
    });
    // console.log(e.target.value);
  };
  const setDate = (e) => {
    setData({
      ...data,
      date: new Date(e).toISOString(),
    });
  };

  return (
    <>
      <form onSubmit={Submit}>
        <Grid
          sx={{ px: 3, my: 2 }}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid container alignItems={"center"}>
            <Typography my variant="h4" align="center">
              Consumables
            </Typography>
            <Tooltip title={fillingCaution}>
              <WarningAmberIcon style={{ color: "#f00" }} />
            </Tooltip>
          </Grid>
          {isSuperAdmin() && (
            <Grid item>
              <Button
                component={Link}
                to="/consumablesUpdate"
                variant="contained"
              >
                Update Consumables
              </Button>
            </Grid>
          )}
        </Grid>

        <div className="dateCate">
          <BasicDatePicker
            onChange={setDate}
            label="Timing"
            renderInput={(params) => <TextField {...params} />}
            value={data.date}
          />
        </div>

        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="center"
          textAlign="center"
          justifyContent="space-around"
        >
          <InputField
            label="New Valves Received Through Transhipment"
            value={data.newValvesReceiptThroughTranshipment}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="newValvesReceiptThroughTranshipment"
          />
          <InputField
            label="New Valves Received Through Party"
            value={data.newValvesReceiptThroughParty}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="newValvesReceiptThroughParty"
          />
          <InputField
            label="Defective Valves Dispatched To Anantapur"
            value={data.defectiveValvesDespatchedToAnantapur}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="defectiveValvesDespatchedToAnantapur"
          />

          <InputField
            label="New Regulators Received Through Transhipment"
            value={data.newRegulatorsReceiptThroughTranshipment}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="newRegulatorsReceiptThroughTranshipment"
          />
          <InputField
            label="New Regulators Received Through Party"
            value={data.newRegulatorsReceiptThroughParty}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="newRegulatorsReceiptThroughParty"
          />
          <InputField
            label="Regulators Issued To Distributors"
            value={data.regulatorsIssuedToDistributors}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="regulatorsIssuedToDistributors"
          />
          <InputField
            label="Defective Regulators Received From Distributors"
            value={data.defectiveRegulatorsReceiptFromDistributors}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="defectiveRegulatorsReceiptFromDistributors"
          />

          <InputField
            label="Defective Regulators Dispatched To Anantapur"
            value={data.defectiveRegulatorsDespatchedToAnantapur}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="defectiveRegulatorsDespatchedToAnantapur"
          />
          <InputField
            label="Orings Received"
            value={data.oringsReceipt}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="oringsReceipt"
          />
          <InputField
            label="Pvc Seal Received"
            value={data.pvcSealReceipt}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="pvcSealReceipt"
          />
          <InputField
            label="Sc Caps Received"
            value={data.scCapsReceipt}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="scCapsReceipt"
          />
          <InputField
            label="Orings Consumption"
            value={data.oringsConsumption}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="oringsConsumption"
          />
          <InputField
            label="Pvc Seal Consumption"
            value={data.pvcSealConsumption}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="pvcSealConsumption"
          />
          <InputField
            label="Sc Caps Consumption"
            value={data.scCapsConsumption}
            inputChange={handleInputChange}
            keyPress={handleKeyPress}
            name="scCapsConsumption"
          />
        </Grid>
        <div className="SubmitInp">
          <Button variant="outlined" onClick={preview}>
            Preview
          </Button>
        </div>
      </form>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {Object.keys(previewData).map((key) => (
                  <TableRow
                    key={key}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {toTitleCase(key)}
                    </TableCell>
                    <TableCell align="right">{previewData[key]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button variant="contained" color="warning" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="info" onClick={Submit}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alert.serverity}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ValvesRegComs;
