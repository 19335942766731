import { React, useState } from "react";
import { TextField } from "@mui/material";
import BasicDatePicker from "../Forms/Elements/datePicker";
import Button from "@mui/material/Button";
import Navbar from "../Navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import energonImg from "../../Images/logo.png";
import { Link } from "react-router-dom";
import {Snackbar} from "@mui/material";
import {Alert} from "@mui/material";

function PowerCompUPD() {
  var myHeaders = new Headers({"authorization":
      process.env.REACT_APP_API_KEY})
  myHeaders.append("Content-Type", "application/json");
  const [alert, setAlert] = useState({
    open: false,
    message: "ola",
    serverity: "success",
  });
  const handleAlertClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };
  const [data, setData] = useState({
    date: "2021-08-18T00:00:00.000Z",
    TIME: "",
    PF: 0,
    KWH: 0,
    KVAH: 0,
    R_voltage_Rv: 0,
    R_voltage_RA: 0,
    Y_voltage_yv: 0,
    Y_voltage_yA: 0,
    B_voltage_BV: 0,
    B_voltage_BA: 0,
    KVAH_Day: 0,
    KVAH_Night: 0,
    Remarks: "",
    Signature: "",
  });
  //   const changeTime = (e) => {
  //     setTime(e.target.value || "08:00");
  //   };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({
      ...data,
      [name]: value,
    });
    // console.log(e.target.value);
  };

  //   fetching values to show in update page
  const FetchValues = async (e) => {
    e.preventDefault();
    // console.log(data.date);
    let fetchedData = await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/power_consumption`,
      {
        method: "POST",
        body: JSON.stringify({
          date: data.date,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        let result = data.response[0];
        if(result){
          setData(result);
        }else{
          setAlert({
            open : true,
            serverity : 'warning',
            message : 'no data'
          })
        }
        // console.log(result);
        // setInputField(result);
        return data;
      })
      .catch((err) => {
        // console.log(err);
        setAlert({
          open : true,
          serverity : 'error',
          message : 'Error while fetching data'
        })
      });
    // console.log(fetchedData);
  };

  // update is done from here
  const UpdateCategory = async (e) => {
    e.preventDefault();
    const update = await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/power_consumption`,
      {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if( data.affectedRows > 1){
          setAlert({
            open: true,
            message: "Data updated successfully",
            serverity: "success",
          });
        }else{
          setAlert({
            open: true,
            message: "Unable to update data",
            serverity: "warning",
          })
        }

        return data;
      });
    // console.log(update);
    // window.alert("updated")
  };

  const setDate = (e) => {
    setData({
      ...data,
      date: new Date(e).toISOString(),
    });
  };
  return (
    <>
      <div className="updateNavbar">
        <div className="box">
          <Link to="/forms" title="Go Back">
            <ArrowBackIcon  className="backIcon" />
          </Link>
        </div>
        <div className="box">
          <img src={energonImg} alt="" />
        </div>
      </div>

      <div className="heading">
        <p>Update Power</p>
      </div>{" "}
      <form className="UpdateContainer" onSubmit={FetchValues}>
        <div className="dateCate">
          <BasicDatePicker
            onChange={setDate}
            label="date"
            renderInput={(params) => <TextField {...params} />}
            value={data.date}
          />
        <input className="SubmitBtn" type="submit" value="Fetch Details" />
        </div>

      </form>
      <form>
        {/* input feilds */}
        <div className="ConsumptionContainer">
          <TextField
            margin="dense"
            variant="standard"
            name="TIME"
            value={data.TIME}
            type="text"
            label="Time"
            onChange={handleInputChange}
          />
          <TextField
            label="PF"
            margin="dense"
            variant="standard"
            name="PF"
            value={data.PF}
            type="number"
            onChange={handleInputChange}
          />
          <TextField
            label="KVAH"
            margin="dense"
            variant="standard"
            name="KVAH"
            value={data.KVAH}
            type="number"
            onChange={handleInputChange}
          />
          <TextField
            label="R_voltage_Rv"
            margin="dense"
            variant="standard"
            name="R_voltage_Rv"
            value={data.R_voltage_Rv}
            type="number"
            onChange={handleInputChange}
          />{" "}
        </div>
        <div className="ConsumptionContainer">
          <TextField
            label="R_voltage_RA"
            margin="dense"
            variant="standard"
            name="R_voltage_RA"
            value={data.R_voltage_RA}
            type="number"
            onChange={handleInputChange}
          />
          <TextField
            label="Y_voltage_yv"
            margin="dense"
            variant="standard"
            name="Y_voltage_yv"
            value={data.Y_voltage_yv}
            type="number"
            onChange={handleInputChange}
          />
          <TextField
            label="Y_voltage_yA"
            margin="dense"
            variant="standard"
            name="Y_voltage_yA"
            value={data.Y_voltage_yA}
            type="number"
            onChange={handleInputChange}
          />
          <TextField
            label="B_voltage_BA"
            name="B_voltage_BA"
            margin="dense"
            variant="standard"
            value={data.B_voltage_BA}
            type="number"
            onChange={handleInputChange}
          />
        </div>
        <div className="ConsumptionContainer">
          <TextField
            label="B_voltage_BV"
            name="B_voltage_BV"
            margin="dense"
            variant="standard"
            value={data.B_voltage_BV}
            type="number"
            onChange={handleInputChange}
          />
          <TextField
            label="KVAH_Day"
            margin="dense"
            variant="standard"
            name="KVAH_Day"
            value={data.KVAH_Day}
            type="number"
            onChange={handleInputChange}
          />
          <TextField
            label="KVAH_Night"
            margin="dense"
            variant="standard"
            name="KVAH_Night"
            value={data.KVAH_Night}
            type="number"
            onChange={handleInputChange}
          />
          <TextField
            label="Remarks"
            name="Remarks"
            margin="dense"
            variant="standard"
            value={data.Remarks}
            type="text"
            onChange={handleInputChange}
          />
        </div>
        <div className="ConsumptionContainer">
          <TextField
            label="Signature"
            name="Signature"
            margin="dense"
            variant="standard"
            value={data.Signature}
            type="text"
            onChange={handleInputChange}
          />
        </div>
          <button className="SubmitBtn"  onClick={UpdateCategory}>
            Update
          </button>
      </form>
      <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={alert.serverity}
            sx={{ width: "100%" }}
            variant="filled"
          >
            {alert.message}
          </Alert>
        </Snackbar>
    </>
  );
}

export default PowerCompUPD;
