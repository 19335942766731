import React from "react";
import { useState } from "react";
import { Bar, Line, Pie } from "react-chartjs-2";
import BasicDatePicker from "../Forms/Elements/datePicker";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Alert,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  Tooltip,
  Title,
  Legend,
  LinearScale,
  ArcElement,
  LogarithmicScale,
  PointElement,
  LineElement,
} from "chart.js";
import { useEffect } from "react";

ChartJS.register(
  CategoryScale,
  BarElement,
  Tooltip,
  Title,
  Legend,
  LinearScale,
  ArcElement,
  LogarithmicScale,
  PointElement,
  LineElement
);

let options = {
  height: 2600,
  scales: {
    x: {
      display: true,
      title: {
        display: true,
        text: "Categories",
        color: "black",
        font: {
          weight: 700,
        },
      },
    },
    y: {
      display: true,
      ticks: {
        beginAtZero: true,
      },
      type: "logarithmic",
      title: {
        display: true,
        text: "No. of  Cylinders",
        color: "black",
        font: {
          weight: 700,
        },
      },
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || "";

          if (context.parsed.y !== null) {
            label += `:${parseInt(context.parsed.y)} Cyls`;
          }
          // console.log(context);
          return label;
        },
        afterBody: function (context) {
          var value = context[0].label.match(/[0-9.]+/i);
          return `${((value * context[0].parsed.y) / 1000).toFixed(2)} MT`;
        },
      },
    },
  },
};
let barOptions = {
  plugins: {
    tooltip: {
      callbacks: {
        label: (context) => {
          return `${context.raw} MT`;
        },
      },
    },
  },
};
let pieOptions  = {
  plugins: {
    tooltip: {
      callbacks: {
        label: (context) => {
          return `${parseInt(context.raw).toFixed(2)} MT`;
        },
      },
    },
  },
}
function CategoriesGph() {
  let ProductionArr = [];
  let expProductionArr = []; //exprected production
  let DespatchArr = [];
  let expDespatchesArr = []; //exprected Despatcehs arr...

  const [DateWise, setDateWise] = useState(true);
  const [DayWise, setDayWise] = useState(true);
  const [WeekWise, setWeekWise] = useState(false);
  const [DayWeekMonth, setDayWeekMonth] = useState(true);
  const [MonthlyData, setMonthlyData] = useState(false);
  const [yearWise, setyearWise] = useState(false);
  const [GraphYear, setGraphYear] = useState(false);
  const [year, setYear] = useState("2021");
  const [month, setMonth] = useState("1");
  const [Week, setWeek] = useState("1");
  const [closingND, setClosingND] = useState([0, 0]);
  const [closingDOM, setClosingDOM] = useState([0, 0]);
  const [closingbulkReceived, setclosingbulkReceived] = useState([0, 0]);
  const [dayProduction, setDayProduction] = useState(ProductionArr);
  const [ExpdayProduction, setExpDayProduction] = useState(expProductionArr);
  const [dayDespatches, setDayDespatches] = useState(DespatchArr);
  const [ExpdayDespatches, setExpDayDespactches] = useState(expDespatchesArr);
  const [categoryVal, setCategoryVal] = useState({
    CATEGORY: "",
  });
  const [years, setYears] = useState([]);
  const [gainLoss, setgainLoss] = useState({
    name: "Gain/Loss",
    value: "0",
    style: "LossRed",
  });
  let body = { year: year, month: month - 1, week: undefined, day: 1 };

  const [alert, setAlert] = useState({
    open: false,
    message: "ola",
    serverity: "success",
  });
  const handleAlertClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  var productionArr = [];
  const [yearlyProduction, setYearlyProduction] = useState(productionArr);
  const bulkNonDomestic = {
    labels: ["Opening", "Closing"],
    datasets: [
      {
        data: closingND,
        backgroundColor: ["#771AA7", "#FF762D"],
      },
    ],
  };
  const bulkDomestic = {
    labels: ["Opening", "Closing"],
    datasets: [
      {
        data: closingDOM,
        backgroundColor: ["#771AA7", "#FF762D"],
      },
    ],
  };
  const bulkReceived = {
    labels: ["DOM", "ND"],
    datasets: [
      {
        data: closingbulkReceived,
        backgroundColor: ["#771AA7", "#FF762D"],
      },
    ],
  };
  const categories = [
    `14.2 kg`,
    `19kg`,
    `2kg blue`,
    `35kg`,
    `425kg`,
    `47.5kg`,
    `5kg blue`,
    `5kg red`,
  ];
  const yeardata = {
    labels: [
      "January",
      "Febuary",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Yearly Graph",
        data: yearlyProduction,
        borderColor: "orange",
        backgroundColor: "orange",
        pointBorderColor: "black",
        fill: false,
        tension: 0,
      },
    ],
  };
  const Yearoptions = {
    plugins: {
      legend: true,
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Months",
          color: "black",
          font: {
            weight: 700,
            size: "16vw",
          },
        },
      },
      y: {
        display: true,
        type: "logarithmic",
        title: {
          display: true,
          text: "No. of  Cylinders",
          color: "black",
          font: {
            weight: 700,
            size: "16vw",
          },
        },
      },
    },
  };

  var myHeaders = new Headers({ authorization: process.env.REACT_APP_API_KEY });
  myHeaders.append("Content-Type", "application/json");
  const currDate = new Date();
  let currDateIso = currDate.toISOString().slice(0, 10);

  const [date, setDate] = useState({
    Timing: "2022-05-25T00:00:00.000Z",
  });
  useEffect(() => {
    var curYear = new Date().getFullYear();
    var yearList = [];
    for (var i = 2021; i <= curYear; i++) {
      yearList.push(i);
    }
    setYears(yearList);
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/filling/ProductionLastUpdDate`,
      {
        method: "POST",
        body: JSON.stringify({
          date: `${currDateIso}T00:00:00.000Z`,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log("last update date");
        let value = data.response[0].previous_last_updated_date + "";
        setDate({
          ...data,
          Timing: value,
        });
      })
      .catch((err) => {
        setAlert({
          open: true,
          message: "Error while fetching date",
          serverity: "error",
        });
        // console.log(err);
      });
  }, []);

  const DateChange = (e) => {
    setDate({
      ...date,
      Timing: new Date(e).toISOString(),
    });
  };

  const FetchValues = async () => {
    // e.preventDefault();
    // console.log("fetched");
    let UpdClosingStockArr = [];
    let NDarr = []; //non dom
    let DOMarr = []; //dom
    let bulkReceivedarr = []; // received

    // below pushing data monthly and day wise upon condition in same array

    // showing monthwise
    if (MonthlyData === true) {
      // console.log(myHeaders);
      await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/bulkGraph/BulkMonthly`,
        {
          method: "POST",
          body: JSON.stringify({
            year: year,
            month: month,
          }),
          headers: myHeaders,
        }
      )
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          if (data.results[0].BClosingDOM !== null) {
            // console.log(data.results[0]);
            let result = data.results[0];
            NDarr.push(result.BOpeningND, result.BClosingND);
            DOMarr.push(result.BOpeningDom, result.BClosingDOM);
            bulkReceivedarr.push(result.BReceivedDOM, result.BReceivedND);
            // console.log(bulkReceivedarr);
            setclosingbulkReceived(bulkReceivedarr);
            setClosingDOM(DOMarr);
            setClosingND(NDarr);
            // console.log(NDarr);
          } else {
            setclosingbulkReceived([0, 0]);
            setClosingDOM([0, 0]);
            setClosingND([0, 0]);
            setAlert({
              open: true,
              message: "data not found",
              serverity: "error",
            });
          }

          // console.log(NDarr);
          return data;
        })
        .catch((err) => {
          // console.log(err);
          setAlert({
            open: true,
            message: "Error while fetching data",
            serverity: "error",
          });
        });
      await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/filling/monthlyProduction`,
        {
          method: "POST",
          body: JSON.stringify({
            month: month,
            year: year,
          }),
          headers: myHeaders,
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.response) {
            const arr = data.response;
            arr.forEach((cate) => {
              ProductionArr.push(cate.MonthlyProduction);
            });
            setDayProduction(ProductionArr);
          } else {
            setAlert({
              open: true,
              message: "Unable to fetch data",
              severity: "warning",
            });
          }
        })
        .catch((err) => {
          setAlert({
            open: true,
            message: "Error while fetching data",
            serverity: "error",
          });
        });
      await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/filling/monthlyDespaches`,
        {
          method: "POST",
          body: JSON.stringify({
            month: month,
            year: year,
          }),
          headers: myHeaders,
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.response) {
            const arr = data.response;
            // console.log(arr);
            arr.forEach((cate) => {
              DespatchArr.push(cate.MonthlyDespaches);
            });
            setDayDespatches(DespatchArr);
          } else {
            setAlert({
              open: true,
              serverity: "warning",
              message: "Unable to fetch data",
            });
          }
        })
        .catch((err) => {
          setAlert({
            open: true,
            message: "Error while fetching data",
            serverity: "error",
          });
        });

      await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/categoriesGraph/expectedMonthlyProduction`,
        {
          method: "POST",
          body: JSON.stringify({
            month: month,
            year: year,
            days: new Date(year, month, 0).getDate().toLocaleString(),
          }),
          headers: myHeaders,
        }
      )
        .then((res) => res.json())
        .then((data) => {
          // console.log("expecteded monthly");
          // console.log(data.results);
          if (data.results) {
            let obj = data.results;
            obj.forEach((data) => {
              // console.log(data.monthly_Expected);
              expProductionArr.push(data.monthly_Expected);
            });
            setExpDayProduction(expProductionArr);
          } else {
            setAlert({
              open: true,
              serverity: "warning",
              message: "Unable to fetch data",
            });
            setExpDayProduction([0, 0, 0, 0, 0, 0, 0, 0, 0]);
          }
        })
        .catch((err) => {
          setAlert({
            open: true,
            message: "Error while fetching data",
            serverity: "error",
          });
        });

      await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/filling/expectedMonthlyDespatches`,
        {
          method: "POST",
          body: JSON.stringify({
            month: month,
            year: year,
            days: new Date(year, month, 0).getDate().toLocaleString(),
          }),
          headers: myHeaders,
        }
      )
        .then((res) => res.json())
        .then((data) => {
          // console.log(data.results);

          if (data.results) {
            let obj = data.results;
            obj.forEach((data) => {
              // console.log(data.monthlyDespatches);
              expDespatchesArr.push(data.monthlyDespatches);
            });
            setExpDayDespactches(expDespatchesArr);
          } else {
            setAlert({
              open: true,
              serverity: "warning",
              message: "Unable to fetch data",
            });
          }
        })
        .catch((err) => {
          setAlert({
            open: true,
            message: "Error while fetching data",
            serverity: "error",
          });
        });

      // showing Week Wise
    } else if (WeekWise === true) {
      await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/bulkGraph/BulkWeekly`,
        {
          method: "POST",
          body: JSON.stringify({
            startDay: new Date(year, month, weeks[Week].start)
              .toISOString()
              .slice(0, 10),
            endDay: new Date(year, month, weeks[Week].end)
              .toISOString()
              .slice(0, 10),
          }),
          headers: myHeaders,
        }
      )
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          if (data.results[0].BClosingND !== null) {
            // console.log(data.results[0]);
            let result = data.results[0];
            // console.log(result);
            NDarr.push(result.BOpeningND, result.BClosingND);
            DOMarr.push(result.BOpeningDom, result.BClosingDOM);
            bulkReceivedarr.push(result.BReceivedDOM, result.BReceivedND);
            // console.log(bulkReceivedarr);
            setclosingbulkReceived(bulkReceivedarr);
            setClosingDOM(DOMarr);
            setClosingND(NDarr);
            // console.log(NDarr);
          } else {
            setclosingbulkReceived([0, 0]);
            setClosingDOM([0, 0]);
            setClosingND([0, 0]);
            // window.alert("no bulk data");
            setAlert({
              open: true,
              serverity: "error",
              message: "data not found",
            });
          }
          return data;
        })
        .catch((err) => {
          setAlert({
            open: true,
            message: "Error while fetching data",
            serverity: "error",
          });
        });
      await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/categoriesGraph/WeekProduction`,
        {
          method: "POST",
          body: JSON.stringify({
            startDay: new Date(year, month - 1, weeks[Week].start)
              .toISOString()
              .slice(0, 10),
            endDay: new Date(year, month - 1, weeks[Week].end)
              .toISOString()
              .slice(0, 10),
          }),
          headers: myHeaders,
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.results) {
            const arr = data.results;
            // console.log(arr);
            arr.forEach((cate) => {
              let weekProduction = cate.WeekProduction;
              let weekDespatches = cate.WeekDespaches;
              ProductionArr.push(weekProduction);
              DespatchArr.push(weekDespatches);
            });
            setDayProduction(ProductionArr);
            setDayDespatches(DespatchArr);
          } else {
            setAlert({
              open: true,
              serverity: "warning",
              message: "Unable to fetch data",
            });
          }
        })
        .catch((err) => {
          setAlert({
            open: true,
            message: "Error while fetching data",
            serverity: "error",
          });
        });

      await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/categoriesGraph/expectedWeeklyProduction`,
        {
          method: "POST",
          body: JSON.stringify({
            WeekStartDay: new Date(year, month, weeks[Week].start)
              .toISOString()
              .slice(0, 10),
            WeekEndDay: new Date(year, month, weeks[Week].end)
              .toISOString()
              .slice(0, 10),
          }),
          headers: myHeaders,
        }
      )
        .then((res) => res.json())
        .then((data) => {
          const arr = data.results;
          // console.log("here")
          // console.log(arr)
          // console.log(arr);
          // let expWeekProducionArr = []
          arr.forEach((cate) => {
            // console.log(cate.ExpWeekProduction);
            expProductionArr.push(cate.ExpWeekProduction);
          });
          setExpDayProduction(expProductionArr);
        });
      await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/categoriesGraph/expectedWeeklyDespaches`,
        {
          method: "POST",
          body: JSON.stringify({
            WeekStartDay: new Date(year, month, weeks[Week].start)
              .toISOString()
              .slice(0, 10),
            WeekEndDay: new Date(year, month, weeks[Week].end)
              .toISOString()
              .slice(0, 10),
          }),
          headers: myHeaders,
        }
      )
        .then((res) => res.json())
        .then((data) => {
          const arr = data.results;
          // console.log(arr);
          // let expWeekProducionArr = []
          arr.forEach((cate) => {
            // console.log(cate);
            expDespatchesArr.push(cate.ExpWeekDespatches);
          });
          setExpDayDespactches(expDespatchesArr);
        })
        .catch((err) => {
          // console.log(err);
          setAlert({
            open: true,
            message: "Error while fetching data",
            serverity: "error",
          });
        });

      //showing day wise...
    } else if (yearWise === true) {
      if (categoryVal.CATEGORY === "") {
        window.alert("Select a  Category");
      } else {
        await fetch(
          `${process.env.REACT_APP_BASE_API_URL}/filling/yearlyProduction`,
          {
            method: "POST",
            body: JSON.stringify({
              year: year,
              category: categoryVal.CATEGORY,
            }),
            headers: myHeaders,
          }
        )
          .then((res) => res.json())
          .then((data) => {
            let arr = data.response;
            // console.log(arr);

            for (var i = 0; i < 12; i++) {
              productionArr[i] = null;
            }
            arr.forEach((el) => {
              productionArr[el.month - 1] = el.total;
            });
            // console.log(productionArr);
            setYearlyProduction(productionArr);
          })
          .catch((err) => {
            setAlert({
              open: true,
              serverity: "error",
              message: "Error while fetching data",
            });
          });
      }
    } else {
      await fetch(`${process.env.REACT_APP_BASE_API_URL}/bulkGraph`, {
        method: "POST",
        body: JSON.stringify({
          Timing: date.Timing,
        }),
        headers: myHeaders,
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          // console.log(data.results);
          if (data.results.length <= 0) {
            // console.log("yes ");
            setAlert({
              open: true,
              message: "data not found",
              serverity: "error",
            });
            setgainLoss({
              name: "Gain",
              value: 0,
              style: "GainGreen",
            });
            setclosingbulkReceived([0, 0]);
            setClosingDOM([0, 0]);
            setClosingND([0, 0]);
          } else {
            let result = data.results[0];
            let gainLossVal = result.gainLoss.toFixed(2);
            if (gainLossVal > 0) {
              setgainLoss({
                name: "Gain",
                value: gainLossVal,
                style: "GainGreen",
              });
            } else {
              setgainLoss({
                name: "Loss",
                value: gainLossVal,
                style: "LossRed",
              });
            }
            // console.log(result);
            NDarr.push(result.bulkOpeningNd, result.bulkClosingNd);
            DOMarr.push(result.bulkOpeningDom, result.bulkClosingDom);
            bulkReceivedarr.push(result.bulkReceivedDom, result.bulkReceivedNd);
            // console.log(bulkReceivedarr);
            setclosingbulkReceived(bulkReceivedarr);
            setClosingDOM(DOMarr);
            setClosingND(NDarr);
            // console.log(NDarr);
            return data;
          }
        })
        .catch((err) => {
          // console.log(err);
          setAlert({
            open: true,
            serverity: "error",
            message: "Error while fetching data",
          });
        });
      await fetch(`${process.env.REACT_APP_BASE_API_URL}/categoriesGraph`, {
        method: "POST",
        body: JSON.stringify({
          Timing: date.Timing,
        }),
        headers: myHeaders,
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data.results);
          // console.log(data);
          let obj = data.results;
          // console.log(obj);
          obj.forEach((data) => {
            ProductionArr.push(data.dayProduction);
          });
          obj.forEach((data) => {
            DespatchArr.push(data.dayDespatches);
          });

          obj.forEach((data) => {
            // console.log(data.closingGoodEmptyStockOf);
            UpdClosingStockArr.push(data.closingGoodEmptyStockOf);
          });

          // console.log(x);
          // console.log(finalArr);
          setDayProduction(ProductionArr);
          setDayDespatches(DespatchArr);
          // setClosingGoodEmptyStock(UpdClosingStockArr);
          return data;
        })
        .catch((err) => {
          setAlert({
            open: true,
            serverity: "error",
            message: "Error while fetching data",
          });
          // console.log(err);
        });
    }

    // expected values here....
    await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/categoriesGraph/expectedProduction`,
      {
        method: "POST",
        body: JSON.stringify({
          Timing: date.Timing,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        let obj = data.results;
        // console.log(obj);
        obj.forEach((data) => {
          expProductionArr.push(data.expectedProduction);
        });
        obj.forEach((data) => {
          expDespatchesArr.push(data.expectedDespatches);
        });
        setExpDayProduction(expProductionArr);
        setExpDayDespactches(expDespatchesArr);
        // console.log(expProductionArr.length);
        // console.log(expDespatchesArr.length);

        // console.log(obj)
      })
      .catch((err) => {
        // console.log(err);
        setAlert({
          open: true,
          serverity: "error",
          message: "Error while fetching data",
        });
      });
  };
  useEffect(() => {
    FetchValues();
  }, []);

  const [select, setSelect] = useState("Day");
  const selectChange = (e) => {
    setSelect(e.target.value);
  };
  const HandleYearChange = (e) => {
    setYear(e.target.value);
  };
  const HandleWeekChange = (e) => {
    setWeek(e.target.value);
  };

  const HandleMonthChange = (e) => {
    setMonth(e.target.value);
  };

  const ShowDayValues = () => {
    setMonthlyData(false);
    setDayWise(true);
    setWeekWise(false);
    setyearWise(false);
    setDayWeekMonth(true);
    setGraphYear(false);
  };
  const ShowMonthValues = () => {
    setMonthlyData(true);
    setDayWise(false);
    setWeekWise(false);
    setyearWise(false);
    setDayWeekMonth(true);
    setGraphYear(false);
  };
  const ShowWeekValues = () => {
    setMonthlyData(false);
    setDayWise(false);
    setWeekWise(true);
    setyearWise(false);
    setDayWeekMonth(true);
    setGraphYear(false);
  };
  const ShowYearlyValues = () => {
    setMonthlyData(false);
    setDayWise(false);
    setWeekWise(false);
    setDayWeekMonth(false);
    setyearWise(true);
    setGraphYear(true);
    // setYearWise
  };
  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCategoryVal({
      ...categoryVal,
      [name]: value,
    });
    // console.log(e.target.value);
  };

  function getWeekDates() {
    // console.log(body);
    let weeks = [],
      firstDate = new Date(body.year, body.month, 1);
    let lastDate = new Date(body.year, parseInt(body.month) + 1, 0);
    let numDays = lastDate.getDate();
    let start = 1;
    let end = endFirstWeek(firstDate, 1);
    while (start < numDays) {
      weeks.push({ start: start, end: end });
      start = end + 1;
      end = end + 7;
      end = start === 1 && end === 8 ? 1 : end;
      if (end > numDays) {
        end = numDays;
      }
    }
    // console.log(weeks);
    return weeks;
  }
  const weeks = getWeekDates();

  function endFirstWeek(firstDate, firstDay) {
    if (!firstDay) {
      return 7 - firstDate.getDay();
    }
    if (firstDate.getDay() < firstDay) {
      return firstDay - firstDate.getDay();
    } else {
      return 7 - firstDate.getDay() + firstDay;
    }
  }

  return (
    <>
      <div className="Graphheading">
        <div className="GraphDate">
          {DateWise && (
            <div style={{ marginRight: "1vw" }}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Day</InputLabel>
                <Select
                  style={{ backgroundColor: "whitesmoke" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={select}
                  label="Day"
                  onChange={selectChange}
                >
                  <MenuItem value="Day" onClick={ShowDayValues}>
                    Day
                  </MenuItem>
                  <MenuItem value="Weekly" onClick={ShowWeekValues}>
                    Weekly
                  </MenuItem>
                  <MenuItem value="Monthly" onClick={ShowMonthValues}>
                    Monthly
                  </MenuItem>
                  <MenuItem value="Yealy" onClick={ShowYearlyValues}>
                    Yearly
                  </MenuItem>
                </Select>
              </FormControl>{" "}
            </div>
          )}
          {/*  year and monthly wise data shown  */}

          {WeekWise && (
            <>
              <div className="year">
                <Box sx={{ minWidth: 40, marginRight: "1vw" }}>
                  <FormControl fullWidth>
                    <InputLabel>Year</InputLabel>
                    <Select
                      value={year}
                      label="Year"
                      onChange={HandleYearChange}
                      name="Select Year"
                    >
                      {years.map((year) => {
                        return (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className="Weeks" style={{ marginRight: "1vw" }}>
                <Box sx={{ minWidth: 150 }}>
                  <FormControl fullWidth>
                    <InputLabel>Month</InputLabel>
                    <Select
                      value={month}
                      label="Month"
                      onChange={HandleMonthChange}
                      name="Select Months"
                    >
                      <MenuItem key="january" value="1">
                        January
                      </MenuItem>
                      <MenuItem key="Febrauary" value="2">
                        Febrauary
                      </MenuItem>
                      <MenuItem key="March" value="3">
                        March
                      </MenuItem>{" "}
                      <MenuItem key="April" value="4">
                        April
                      </MenuItem>{" "}
                      <MenuItem key="May" value="5">
                        May
                      </MenuItem>{" "}
                      <MenuItem key="June" value="6">
                        June
                      </MenuItem>{" "}
                      <MenuItem key="July" value="7">
                        July
                      </MenuItem>{" "}
                      <MenuItem key="August" value="8">
                        August
                      </MenuItem>
                      <MenuItem key="September" value="9">
                        September
                      </MenuItem>
                      <MenuItem key="October" value="10">
                        October
                      </MenuItem>{" "}
                      <MenuItem key="November" value="11">
                        November
                      </MenuItem>{" "}
                      <MenuItem key="December" value="12">
                        December
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>

              <div className="year">
                <Box sx={{ minWidth: 40, marginRight: "1vw" }}>
                  <FormControl fullWidth>
                    <InputLabel>Weeks</InputLabel>
                    <Select
                      value={Week}
                      label="Year"
                      onChange={HandleWeekChange}
                      name="Select Week"
                    >
                      {weeks.map((week, i) => {
                        return (
                          <MenuItem key={i} value={i}>
                            {new Date(
                              body.year,
                              body.month,
                              week.start
                            ).toDateString()}{" "}
                            -{" "}
                            {new Date(
                              body.year,
                              body.month,
                              week.end
                            ).toDateString()}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </>
          )}
          {MonthlyData && (
            <>
              <div className="year">
                <Box sx={{ minWidth: 40, marginRight: "1vw" }}>
                  <FormControl fullWidth>
                    <InputLabel>Year</InputLabel>
                    <Select
                      value={year}
                      label="Year"
                      onChange={HandleYearChange}
                      name="Select Year"
                    >
                      {years.map((year) => {
                        return (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </div>

              <div className="month">
                <Box sx={{ minWidth: 200 }}>
                  <FormControl fullWidth>
                    <InputLabel>Month</InputLabel>
                    <Select
                      value={month}
                      label="Month"
                      onChange={HandleMonthChange}
                      name="Select Months"
                    >
                      <MenuItem key="january" value="1">
                        january
                      </MenuItem>
                      <MenuItem key="Febrauary" value="2">
                        Febrauary
                      </MenuItem>
                      <MenuItem key="March" value="3">
                        March
                      </MenuItem>{" "}
                      <MenuItem key="April" value="4">
                        April
                      </MenuItem>{" "}
                      <MenuItem key="May" value="5">
                        May
                      </MenuItem>{" "}
                      <MenuItem key="June" value="6">
                        June
                      </MenuItem>{" "}
                      <MenuItem key="July" value="7">
                        July
                      </MenuItem>{" "}
                      <MenuItem key="August" value="8">
                        August
                      </MenuItem>
                      <MenuItem key="September" value="9">
                        September
                      </MenuItem>
                      <MenuItem key="October" value="10">
                        October
                      </MenuItem>{" "}
                      <MenuItem key="November" value="11">
                        November
                      </MenuItem>{" "}
                      <MenuItem key="December" value="12">
                        December
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </>
          )}
          {DayWise && (
            <BasicDatePicker
              onChange={DateChange}
              label="Timing"
              renderInput={(params) => <TextField {...params} />}
              value={date.Timing}
            />
          )}

          {yearWise && (
            <>
              <div className="year">
                <Box sx={{ minWidth: 40, marginRight: "1vw" }}>
                  <FormControl fullWidth>
                    <InputLabel>Year</InputLabel>
                    <Select
                      value={year}
                      label="Year"
                      onChange={HandleYearChange}
                      name="Select Year"
                    >
                      {years.map((year) => {
                        return (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className="month">
                <Box sx={{ minWidth: 150 }}>
                  <FormControl fullWidth>
                    <InputLabel>Category</InputLabel>
                    <Select
                      value={categoryVal.CATEGORY}
                      label="Category"
                      onChange={handleInputChange}
                      name="CATEGORY"
                    >
                      {categories.map((category) => {
                        return (
                          <MenuItem key={category} value={category}>
                            {category}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </>
          )}
          <button className="Go" onClick={FetchValues}>
            Go
          </button>
        </div>
        <section className="GainLoss">
          <div>
            <p style={{ color: "black !important" }}>Bulk {gainLoss.name} : </p>{" "}
            <h1 className={gainLoss.style}>{gainLoss.value}</h1>
          </div>
        </section>
      </div>

      {DayWeekMonth && (
        <>
          <section className="categoriesGraph">
            <div>
              <h1>
                Production <span>(In Metric Tonnes)</span>{" "}
              </h1>
              <Bar
                className="CanvasBar"
                data={{
                  labels: categories,
                  datasets: [
                    {
                      label: "Expected Production",
                      data: ExpdayProduction,
                      backgroundColor: "#771AA7",
                    },
                    {
                      label: "Current Production",
                      data: dayProduction,
                      backgroundColor: "#FF762D",
                    },
                  ],
                }}
                options={options}
              />
            </div>

            <div>
              <h1>
                Dispatch <span>(In Metric Tonnes)</span>{" "}
              </h1>

              <Bar
                className="CanvasBar"
                data={{
                  labels: categories,
                  datasets: [
                    {
                      label: ["Expected Dispatch"],
                      data: ExpdayDespatches,
                      backgroundColor: "#771AA7",
                    },
                    {
                      label: "Current Dispatch",
                      data: dayDespatches,
                      backgroundColor: "#FF762D",
                    },
                  ],
                }}
                options={options}
              />
            </div>
          </section>
          <section className="bulkGraph">
            <div>
              <h1>
                Bulk Non Domestic
                <span>(In Metric Tonnes)</span>{" "}
              </h1>
              <Pie data={bulkNonDomestic} options={pieOptions} />
            </div>
            <div>
              <h1>
                Bulk Domestic <span>(In Metric Tonnes)</span>{" "}
              </h1>
              <Pie data={bulkDomestic} options={pieOptions} />
            </div>
            <div>
              <h1>
                Bulk Received <span>(In Metric Tonnes)</span>{" "}
              </h1>
              <Pie data={bulkReceived} options={pieOptions} />
            </div>
          </section>
        </>
      )}

      {GraphYear && (
        <>
          <section className="categoriesGraph">
            <div className="yearlyGraph">
              <h1>Yearly Data</h1>
              <Line data={yeardata} options={Yearoptions}></Line>
            </div>
          </section>
        </>
      )}

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alert.serverity}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default CategoriesGph;
