import {
  Grid,
  Box,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { toTitleCase } from "../../utils/utils";
import BasicDatePicker from "../Forms/Elements/datePicker";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";



function CategoriesPreview() {
  var myHeaders = new Headers({"authorization":
      process.env.REACT_APP_API_KEY})
  myHeaders.append("Content-Type", "application/json");

  // console.log(`${beforeDateStr}T00:00:00.000Z`);
  const currDate = new Date();
  let currDateIso = currDate.toISOString().slice(0, 10);

  const [alert, setAlert] = useState({
    open: false,
    message: "ola",
    serverity: "success",
  });
  const handleAlertClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const [date, setDate] = useState({
    Timing: `2022-05-25T00:00:00.000Z`,
  });
  
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/filling/ProductionLastUpdDate`,
      {
        method: "POST",
        body: JSON.stringify({
          date: `${currDateIso}`,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log("last update date");
        let value = data.response[0].previous_last_updated_date + "";
        // setLastUpdDate(value);
        // console.log(data.response[0].previous_last_updated_date);
        setDate({
          ...data,
          Timing: value,
        });
      })
      .catch((err) => {
        setAlert({
          open  :true,
          message : 'Error while fetching data',
          serverity : 'error'
        })
        // console.log(err);
      });
  }, []);
  

  const [category, setCategory] = useState("14.2 kg");
  let dataObject = {
    dayDespatches: 0,
    cylindersReceivedFromDistributor: 0,
    dayProduction: 0,
    closingInventoryOf: 0,
    closingDpt: 0,
    closingStockFulls: 0,
    sqcInTermsOfCylinders: 0,
    generatedDpt: 0,
    closingHotRepair: 0,
    closingColdRepair: 0,
    closingGoodEmptyStockOf: 0,
    closingScrap: 0,
    closingOmc: 0,
    generatedColdRepair: 0,
    generatedHotRepair: 0,
    noOfSqc: 0,
    receivedScrap: 0,
    atpDespBodyLeak: 0,
    atpDespColdRepair: 0,
    atpDespDamaged: 0,
    atpDespDamagedSpuriousOmc: 0,
    atpDespDpt: 0,
    atpDespHotRepair: 0,
    atpDespNeckLeak: 0,
    atpDespOmc: 0,
    atpDespScrap: 0,
    atpDespSpurious: 0,
    closingBodyLeak: 0,
    closingDamaged: 0,
    closingDamagedSpuriousOmc: 0,
    closingNeckLeak: 0,
    closingPinLeak: 0,
    closingSpurious: 0,
    cylindersThroughTranshipmentReceipt: 0,
    damagedSpuriousOmc: 0,
    generatedBodyLeak: 0,
    generatedNeckLeak: 0,
    generatedPinLeak: 0,
    newEmptyCylindersReceipt: 0,
    prcnIssuedUpdateOnlyFullPrcn: 0,
    prcnIssuedUpdatePartPrcnQty: 0,
    receivedDamaged: 0,
    receivedOmc: 0,
    receivedSpurious: 0,
    rejectedCylsInSqc: 0,
    valveChanging: 0,
  }
  const [fillingData, setFillingData] = useState(dataObject);

  // const [fillingData , setFillingData] = useState({})
const DateChange = (e) => {
    setDate({
      ...date,
      Timing: new Date(e).toISOString(),
    });
  };
  const categories = [
    `14.2 kg`,
    `19kg`,
    `2kg blue`,
    `35kg`,
    `425kg`,
    `47.5kg`,
    `5kg blue`,
    `5kg red`,
  ];
  // fetching categories fillingData
  const fetchFilling = () => {
    // myHeaders.append("Content-Type",s "application/json");
    var options = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        date: date.Timing.slice(0,10),
        category: category,
      }),
    };
    fetch(`${process.env.REACT_APP_BASE_API_URL}/filling`, options)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.response[0]) {
          let sorted = Object.fromEntries(
            Object.entries(result.response[0]).sort((b, a) => a[1] - b[1])
          );
          // console.log(sorted);
          setFillingData(sorted);
        }else{
          setAlert({
            open: true,
            message: "no data found",
            serverity: "error",
          });
          setFillingData(dataObject)
        }
        // console.log(result.response[0],category)
      })
      .catch((error) => {/*console.log("error", error)*/});
  };
 
  useEffect(fetchFilling, [date, category]);
  // console.log(`date is :` , date)
  // fetchFilling();
  return (
    <>
      <Box sx={{ py: 2, px: 2 }}>
        <Grid container sx={{ py: 3 }}>
          <Grid>
            <BasicDatePicker
              onChange={DateChange}
              label="Date"
              renderInput={(params) => <TextField {...params} />}
              value={date.Timing}
            />
          </Grid>
          <Grid sx={{ px: 2 }}>
            <FormControl>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                style={{ backgroundColor: "whitesmoke" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Category"
                onChange={(e) => {
                  setCategory(e.target.value);
                  fetchFilling();
                }}
              >
                {categories.map((el) => {
                  return <MenuItem value={el}>{el}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {
          <TableContainer component={Paper} sx={{ px: 2, py: 2 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {/* {Object.keys(fillingData).map((k) => {
                  if (k === "Timing" || k === "CATEGORY") return;
                  console.log(
                   `<TableRow
                      key='${k}'
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        ${toTitleCase(k)}
                      </TableCell>
                    </TableRow>`
                  );
                  return (
                    <TableRow
                      key={k}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {toTitleCase(k)}
                        error
                      </TableCell>
                      <TableCell align="right">{fillingData[k]}</TableCell>
                    </TableRow>
                  );
                })} */}
                <>
                  <TableRow
                    key="Production"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Day Production
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.dayProduction} cyls
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="dispatches"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Day Dispatches
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.dayDespatches} cyls
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="PRCN"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      PRCN Issued Update Full PRCN
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.prcnIssuedUpdateOnlyFullPrcn} cyls
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="PRCN PART"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      PRCN Issued Update Part PRCN
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.prcnIssuedUpdatePartPrcnQty} kgs
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Closing Stock"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Closing Stock Fulls
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.closingStockFulls} cyls
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Valve Changing"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Valve Changing
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.valveChanging} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="New Empty Cylinders Received"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      New Empty Cylinders Received
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.newEmptyCylindersReceipt} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Cylinders Through Transhipment Received"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Cylinders Through Transhipment Received
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.cylindersThroughTranshipmentReceipt} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Cylinders Received from Distributors"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Cylinders Received from Distributors
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.cylindersReceivedFromDistributor} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Closing Good Empty Stock"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Closing Good Empty Stock
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.closingGoodEmptyStockOf} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Closing Inventory"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Closing Inventory
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.closingInventoryOf} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Generated DPT"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Generated DPT
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.generatedDpt} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Generated Hot Repair"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Generated Hot Repair
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.generatedHotRepair} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Generated Cold Repair"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Generated Cold Repair
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.generatedColdRepair} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Received Damaged"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Received Damaged
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.receivedDamaged} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Received Spurious"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Received Spurious
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.receivedSpurious} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Received OMC"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Received OMC
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.receivedOmc} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Received Scrape"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Received Scrap
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.receivedScrap} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Generated Neck Leak"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Generated Neck Leak
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.generatedNeckLeak} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Generated Pin Leak"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Generated Pin Leak
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.generatedPinLeak} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Generated Body Leak"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Generated Body Leak
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.generatedBodyLeak} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Atp Dispatch DPT"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Atp Dispatch DPT
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.atpDespDpt} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Atp Dispatch Hot Repair"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Atp Dispatch Hot Repair
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.atpDespHotRepair} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Atp Dispatch COld Repair"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Atp Dispatch Cold Repair
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.atpDespColdRepair} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Atp Dispatch Damaged"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Atp Dispatch Damaged
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.atpDespDamaged} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Atp Dispatch Spurious"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Atp Dispatch Spurious
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.atpDespSpurious} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Atp Dispatch OMC"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Atp Dispatch OMC
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.atpDespOmc} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Atp Dispatch scrap"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Atp Dispatch scrap
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.atpDespScrap} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Atp Dispatch Neck Leak"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Atp Dispatch Neck Leak
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.atpDespNeckLeak} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Atp Dispatch Body Leak"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Atp Dispatch Body Leak
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.atpDespBodyLeak} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Closing Dpt"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Closing Dpt
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.closingDpt} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Closing Hot Repair"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Closing Hot Repair
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.closingHotRepair} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Closing Cold Repair"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Closing Cold Repair
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.closingColdRepair} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Closing Damaged"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Closing Damaged
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.closingDamaged} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Closing Spurious"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Closing Spurious
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.closingSpurious} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Closing OMC"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Closing OMC
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.closingOmc} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Closing Scrap"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Closing Scrap
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.closingScrap} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Closing Neak Leak"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Closing Neck Leak
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.closingNeckLeak} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Closing Pin Leak"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Closing Pin Leak
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.closingPinLeak} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="Closing Body Leak"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Closing Body Leak
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.closingBodyLeak} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="No. of SQC"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      No. Of SQC
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.noOfSqc} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="sqc in terms of cylinders"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      SQC in terms of cylinders
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.sqcInTermsOfCylinders} cyls
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="rejected cylinders in Sqc"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Rejected Cylinders In SQC
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.rejectedCylsInSqc} cyls
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key="damagedSpurious omc"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Damaged Spurious OMC
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.damagedSpuriousOmc} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key=" ATp damagedSpurious omc"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Atp Dispatch Damaged Spurious OMC
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.damagedSpuriousOmc} nos
                    </TableCell>
                  </TableRow>

                  <TableRow
                    key=" Closing damaged Spurious omc"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Closing Damaged Spurious OMC
                    </TableCell>
                    <TableCell align="right">
                      {fillingData.atpDespDamagedSpuriousOmc} nos
                    </TableCell>
                  </TableRow>
                </>
              </TableBody>
            </Table>
          </TableContainer>
        }
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={alert.serverity}
            sx={{ width: "100%" }}
            variant="filled"
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
}

export default CategoriesPreview;
