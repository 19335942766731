import { React, useState } from "react";
import { Grid, TextField } from "@mui/material";
import BasicDatePicker from "../Forms/Elements/datePicker";
import Button from "@mui/material/Button";
import InputField from "./../Forms/Elements/InputField";
import { toTitleCase } from "../../utils/utils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import energonImg from "../../Images/logo.png";
import { Link } from "react-router-dom";
import {Alert,Snackbar} from '@mui/material'

function TankerTruckUpd() {
  var myHeaders = new Headers({"authorization":
      process.env.REACT_APP_API_KEY})
  myHeaders.append("Content-Type", "application/json");

  const [firstTruckout, setfirstTruckout] = useState("");
  const [lastTruckout, setlastTruckout] = useState("");
  const [alert, setAlert] = useState({
    open: false,
    message: "ola",
    serverity: "success",
  });
  const handleAlertClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };
  const [data, setData] = useState({
    date: "2021-08-18T00:00:00.000Z",
    nlsaSalesExEpplDom: 0,
    nlsaSalesExEpplNd: 0,
    bulkReceivedDom: 0,
    bulkReceivedNd: 0,
    tankersInTransit: 0,
    tankersWaitingAtPlant: 0,
    tankersWaitingIntransit: 0,
    bulkOpeningDom: 0,
    bulkOpeningNd: 0,
    bulkClosingDom: 0,
    bulkClosingNd: 0,
    gainLoss: 0,
    bulkPhysicalClosing: 0,
    firstPackedTruckOut: "00:00:00",
    lastPackedTruckOut: "00:00:00",
    trucksInsidePlant: 0,
    totalIndentsExecuted: 0,
    pendingIndentsInIms: 0,
    ResiduvalPRCNfor425: 0,
  });
  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({
      ...data,
      [name]: value,
    });
    // console.log(e.target.value);
  };
  const changeFirstTruck = (e) => {
    setfirstTruckout(e.target.value || "08:00");
  };
  const changeLastTruck = (e) => {
    setlastTruckout(e.target.value || "08:00");
  };

  //   fetching value for updation
  const FetchValues = async (e) => {
    e.preventDefault();
    // console.log(data.date);
    let fetchedData = await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/bulk`,
      {
        method: "POST",
        body: JSON.stringify({
          date: data.date.slice(0,10),
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        let result = data.response[0];
        if( result)
        setData(result);
        else{
          setAlert({
            open : true,
            message : 'no data found',
            serverity : 'warning'
          })
        }
        // console.log(result);
        // setInputField(result);
        return data;
      })
      .catch((err) => {
        // console.log(err);
        setAlert({
          open : true,
          message : 'error while fetching daat',
          serverity : 'error'
        })
      });
    // console.log(fetchedData);
  };

  // update is done from here
  const UpdateCategory = async (e) => {
    e.preventDefault();
    await fetch(`${process.env.REACT_APP_BASE_API_URL}/bulk`, {
      method: "PATCH",
      body: JSON.stringify({
        ...data,
        firstPackedTruckOut: firstTruckout,
        lastPackedTruckOut: lastTruckout,
      }),
      headers: myHeaders,
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if(data.results.affectedRows >= 1){
          setAlert({
            open : true,
            message : 'Update Successfull',
            serverity : 'success'
          })
        }else{
          setAlert({
            open : true,
            message : 'Update failed',
            serverity : 'warning'
          })
        }
      });
  };

  const setDate = (e) => {
    setData({
      ...data,
      date: new Date(e).toISOString().slice(0,10),
    });
  };

  return (
    <>
      <div className="updateNavbar">
        <div className="box">
          <Link to="/forms" title="Go Back">
            <ArrowBackIcon className="backIcon" />
          </Link>
        </div>
        <div className="box">
          <img src={energonImg} alt="" />
          {/* <h1>Energon</h1> */}
        </div>
      </div>
      <div className="heading">Update Bulk</div>
      <form className="UpdateContainer" onSubmit={FetchValues}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item sx={{ px: 2 }}>
            <BasicDatePicker
              onChange={setDate}
              label="date"
              renderInput={(params) => <TextField {...params} />}
              value={data.date}
            />
          </Grid>
          <Grid item></Grid>
          <Button onClick={FetchValues} variant="contained">
            Fetch Values
          </Button>
        </Grid>
      </form>
      <form onSubmit={UpdateCategory}>
        <div className="ConsumptionContainer">
          <span>
            <p>first truck out</p>
            <TextField
              margin="dense"
              variant="standard"
              name="firstPackedTruckOut"
              value={data.firstPackedTruckOut}
              type="time"
              onChange={changeFirstTruck}
            />
          </span>

          <span>
            <p>last truck out</p>
            <TextField
              margin="dense"
              variant="standard"
              name="lastPackedTruckOut"
              value={data.lastPackedTruckOut}
              type="time"
              onChange={changeLastTruck}
            />
          </span>
        </div>

        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="center"
          textAlign="center"
          justifyContent="space-around"
        >
          {Object.keys(data).map((key) => {
            if (key === "CATEGORY" || key === "date") return "";
            return (
              <InputField
                label={toTitleCase(key)}
                value={data[key]}
                inputChange={handleInputChange}
                name={key}
              />
            );
          })}
        </Grid>
        <div className="updatebtnContainer">
          <input className="SubmitBtn" type="submit" value="Update Details" />
        </div>
      </form>
      <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={alert.serverity}
            sx={{ width: "100%" }}
            variant="filled"
          >
            {alert.message}
          </Alert>
        </Snackbar>
    </>
  );
}

export default TankerTruckUpd;
