import {
  Grid,
  Box,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { toTitleCase } from "../../utils/utils";
import BasicDatePicker from "../Forms/Elements/datePicker";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

function Preview() {
  var myHeaders = new Headers({ authorization: process.env.REACT_APP_API_KEY });
  myHeaders.append("Content-Type", "application/json");
  //   const date = props.date;
  const currDate = new Date();
  let currDateIso = currDate.toISOString().slice(0, 10);

  const [alert, setAlert] = useState({
    open: false,
    message: "ola",
    serverity: "success",
  });
  const handleAlertClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const [date, setDate] = useState({
    Timing: new Date().toISOString().slice(0,10),
  });
  const [type, setType] = useState("type_1");

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/maintenance_${type}/maintenanceLastUpdDate`,
      {
        method: "POST",
        body: JSON.stringify({
          date: currDateIso,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log("last update date");
        let value = data.response[0].previous_last_updated_date + "";
        // setLastUpdDate(value);
        // console.log(data.response[0].previous_last_updated_date);
        setDate({
          ...data,
          Timing: value,
        });
      })
      .catch((err) => {
        // window.alert("error fetching in  last update date");
        setAlert({
          open: true,
          serverity: "error",
          message: "Error while fetching data",
        });
        // console.log(err);
      });
  }, [type]);

  const [data, setData] = useState({
    total: 0,
    mechanical: 0,
    electrical: 0,
    noDemand: 0,
    noTruck: 0,
    noProduct: 0,
    noFitCylinders: 0,
    others: 0,
    specifyInWordsIfOthers: "",
    Start: "00:00:00",
    End: "00:00:00",
    Description: "Discription",
    authorityName: " ",
  });
  // const handleDateChange = (e) => {
  //   const date = e.toISOString().slice(0, 10);
  //   setDate(date);
  // };
  const DateChange = (e) => {
    setDate({
      ...date,
      Timing: new Date(e).toISOString(),
    });
  };
  // fetching categories data
  const fetchData = () => {
    // myHeaders.append("Content-Type", "application/json");
    var options = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        date: date.Timing.slice(0,10),
      }),
    };
    fetch(`${process.env.REACT_APP_BASE_API_URL}/maintenance_${type}`, options)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);

        if (result.response[0]) {
          if (type === "type_2") setData(result.response);
          if (type === "type_1") setData(result.response[0]);
          // console.log(result);
        } else {
          setAlert({
            open: true,
            message: "no data found",
            serverity: "error",
          });
          setData({
            total: 0,
            mechanical: 0,
            electrical: 0,
            noDemand: 0,
            noTruck: 0,
            noProduct: 0,
            noFitCylinders: 0,
            others: 0,
            specifyInWordsIfOthers: "",
            Start: "00:00:00",
            End: "00:00:00",
            Description: "Discription",
            authorityName: " ",
          });
        }
        // console.log(result.response[0],category)
      })
      .catch((error) => {
        setAlert({
          open: true,
          message: "Error while fetching data",
          serverity: "error",
        });
      });
  };
  useEffect(fetchData, [date, type]);
  // fetchFilling();
  return (
    <>
      <Box sx={{ py: 2, px: 2 }}>
        <Grid container sx={{ py: 3 }}>
          <Grid sx={{ px: 2 }}>
            <FormControl>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                style={{ backgroundColor: "whitesmoke" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type}
                label="Category"
                onChange={(e) => {
                  // console.log(type);
                  setType(e.target.value);
                }}
              >
                <MenuItem value="type_1">Type 1</MenuItem>
                <MenuItem value="type_2">Type 2</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid>
            <BasicDatePicker
              onChange={DateChange}
              label="Date"
              renderInput={(params) => <TextField {...params} />}
              value={date.Timing}
            />
          </Grid>
        </Grid>
        { type === 'type_1' &&
          <TableContainer component={Paper} sx={{ px: 2, py: 2, my: 4 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                
                  <>
                    <TableRow
                      key="total"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Total
                      </TableCell>
                      <TableCell align="right">{data.total} min</TableCell>
                    </TableRow>

                    <TableRow
                      key="mechanical"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Mechanical
                      </TableCell>
                      <TableCell align="right">{data.mechanical} min</TableCell>
                    </TableRow>

                    <TableRow
                      key="electrical"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Electrical
                      </TableCell>
                      <TableCell align="right">{data.electrical} min</TableCell>
                    </TableRow>

                    <TableRow
                      key="noDemand"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        No Demand
                      </TableCell>
                      <TableCell align="right">{data.noDemand} min</TableCell>
                    </TableRow>
                    <TableRow
                      key="noTruck"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        No Truck
                      </TableCell>
                      <TableCell align="right">{data.noTruck} min</TableCell>
                    </TableRow>
                    <TableRow
                      key="noProduct"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        No Product
                      </TableCell>
                      <TableCell align="right">{data.noProduct} min</TableCell>
                    </TableRow>
                    <TableRow
                      key="noFitCylinders"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        No Fit Cylinders
                      </TableCell>
                      <TableCell align="right">
                        {data.noFitCylinders} min
                      </TableCell>
                    </TableRow>
                    <TableRow
                      key="others"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Others
                      </TableCell>
                      <TableCell align="right">{data.others} min</TableCell>
                    </TableRow>
                    <TableRow
                      key="specifyInWordsIfOthers"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Specify In Words If Others
                      </TableCell>
                      <TableCell align="right">
                        {data.specifyInWordsIfOthers}
                      </TableCell>
                    </TableRow>
                  </>
              </TableBody>
            </Table>
          </TableContainer>
        }
        {(type === "type_2" && data.length) && (
          data.map(item=>{
            return(<TableContainer component={Paper} sx={{ px: 2, py: 2, my: 4 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  <>
                    <TableRow
                      key="Start Time"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Start
                      </TableCell>
                      <TableCell align="right">{item.Start}</TableCell>
                    </TableRow>
  
                    <TableRow
                      key="End Time"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        End
                      </TableCell>
                      <TableCell align="right">{item.End}</TableCell>
                    </TableRow>
  
                    <TableRow
                      key="Despcription"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Despcription
                      </TableCell>
                      <TableCell align="right">{item.Description}</TableCell>
                    </TableRow>
  
                    <TableRow
                      key="Authority"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ marginBottom: "5vw !important" }}
                      >
                        Authority Name
                      </TableCell>
                      <TableCell align="right">{item.authorityName}</TableCell>
                    </TableRow>
                  </>
                </TableBody>
              </Table>
            </TableContainer>)
          })
        )}
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={alert.serverity}
            sx={{ width: "100%" }}
            variant="filled"
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
}

export default Preview;
