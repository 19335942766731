import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
export default function BasicDatePicker(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        name = {props.name}
        renderInput = {(params) => <TextField onKeyDown={(e)=>{e.preventDefault()}} {...params} />}
        inputFormat="DD/MM/YYYY"
        maxDate={new Date()}        
      />
    </LocalizationProvider>
  );
}
