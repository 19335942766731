// import "./styles.css"
import {
  Button,
  Container,
  Divider,
  Stack,
  Grid,
  MenuItem,
  InputLabel,
  Select,
  Box,
  FormControl,
  TextField,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import { toTitleCase } from "../../utils/utils";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import BasicDatePicker from "./Elements/datePicker";
import { InputRow } from "./Elements/InputRow";
import { Link } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

export default function Maintenance() {
  var myHeaders = new Headers({"authorization":
      process.env.REACT_APP_API_KEY})
  myHeaders.append("Content-Type", "application/json");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "ola",
    serverity: "success",
  });
  const handleAlertClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  // maintaince type one from  here
  function handleKeyPress(event) {
    if (event.key === "-" || event.key === "+") {
      event.preventDefault(); // prevent the minus symbol from being entered
    }
  }
  
  const [data, setData] = useState({
    total: 0,
    mechanical: 0,
    electrical: 0,
    noDemand: 0,
    noTruck: 0,
    noProduct: 0,
    noFitCylinders: 0,
    others: 0,
    specifyInWordsIfOthers: "",
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    background: "#fff",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
    height: "80%",
    padding: "2rem",
    borderRadius: ".75rem",
  };
  const [previewData, setPreviewData] = useState({});

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({
      ...data,
      [name]: value,
    });
    // console.log(e.target.value);
  };

  const [name, setName] = useState("");

  // handling date here
  const [date, setDate] = useState(new Date());

  const onChangeDate = (e) => {
    setDate(new Date(e).toISOString());
  };

  const [inputFields, setInputFields] = useState([
    {
      End: "",
      Start: "",
      Description: "",
      authorityName: "",
    },
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const values = [...inputFields];
    // console.log("momo", values);
  };

  const handleChange = (event, index) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
  };

  const handleName = (event) => {
    setName(event.target.value);
  };

  // adds new input
  const handleAdd = () => {
    setInputFields([
      ...inputFields,
      {
        endTime: "",
        startTime: "",
        Description: "",
        authorityName: "",
      },
    ]);
  };

  // removes input
  const handleRemove = (index) => {
    if (inputFields.length !== 1) {
      const values = [...inputFields];
      values.splice(index, 1);
      setInputFields(values);
    }
  };
  // sending data to backend SQL..
  const preview = async (e) => {
    e.preventDefault();
    // get preview data
    var requestOptions = {
      method: "POST",
      body: JSON.stringify({
        ...data,
        date,
      }),
      headers: myHeaders,
    };
    try {
      const result = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/maintenance_type_1/preview`,
        requestOptions
      ).then((result) => result.json());
      if (result.result) {
        setPreviewData({
          ...result.result,
        });
        setAlert({
          open: true,
          message: "Preview fetched successfully",
          serverity: "success",
        });
        handleOpen();
        // console.log(result);
      } else {
        setAlert({
          open: true,
          message: "Unable to fetch preview",
          serverity: "warning",
        });
      }
    } catch (err) {
      // console.log(err);
      setAlert({
        open: true,
        message: "Error occured while fetching preview",
        serverity: "error",
      });
    }
  };

  // data insertion is from here...
  const Submit = async (e) => {
    e.preventDefault();
    const insertData = await fetch(`${process.env.REACT_APP_BASE_API_URL}/maintenance_type_1`, {
      method: "PUT",
      body: JSON.stringify({
        // ...data,
        ...previewData,
        date,
      }),
      headers: myHeaders,
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if (data.response) {
          setAlert({
            open: true,
            message: "Inserted data successfully!",
            severity: "success",
          });
          handleClose();
        } else if (data.err.code === "ER_DUP_ENTRY") {
          setAlert({
            open: true,
            message: "Duplicate Entry",
            serverity: "warning",
          });
        }
        return data;
      })
      .catch((err) => {
        setAlert({
          open: true,
          message: "Error. Try Again!",
          serverity: "error",
        });
      });
    // console.log(insertData);
  };

  // data insertion type two is from here...
  const SubmitTypeTwo = async (e) => {
    e.preventDefault();
    const values = [...inputFields];
    let finalValue = values.map((val) => ({ ...val, date: date }));
    // console.log("finalvalue", finalValue);
    // console.log(values);
    const insertData = await fetch(`${process.env.REACT_APP_BASE_API_URL}/maintenance_type_2`, {
      method: "PUT",
      body: JSON.stringify(finalValue),
      headers: myHeaders,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.responses.length) {
          var dupFlag = false;
          data.responses.forEach((response) => {
            // console.log(typeof response);
            if( response.code){
              if( response.code === 'ER_DUP_ENTRY'){
                dupFlag = true;
              }
              setAlert({
                open : true,
                message : 'Error while adding data',
                serverity : 'error'
              });
            }
          });
          if (dupFlag) {
            setAlert({
              open: true,
              message: "Duplicate Entries",
              serverity: "warning",
            });
          } else {
            setAlert({
              open: true,
              message: "Added data successfully!",
              serverity: "success",
            });
          }
        }
        // console.log(data);
        return data;
      })
      .catch((err) => {
        // if (err) {
        //   window.alert("date already taken...");
        // }
        setAlert({
          open: true,
          message: "Error . Try again",
          serverity: "error",
        });
      });
    // console.log(insertData);
  };

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ px: 4 , my: 2}}
      >
        <div className="heading">Maintaince type One</div>
        {/* <Grid item>
          <Button component={Link} to="/maintenanceUpd" variant="contained">
            Update Maintainance
          </Button>
        </Grid> */}
      </Grid>

      <div className="dateCate">
        <BasicDatePicker
          onChange={onChangeDate}
          label="date"
          name="date"
          renderInput={(params) => <TextField {...params} />}
          value={date}
        />
      </div>
      <form>
        <div className="ConsumptionContainer">
          <TextField
            margin="dense"
            onKeyPress={handleKeyPress}
            variant="standard"
            name="total"
            value={data.total}
            type="number"
            onChange={handleInputChange}
            label="Total"
          />
          <TextField
            label="Mechanical"
            margin="dense"
            onKeyPress={handleKeyPress}
            variant="standard"
            name="mechanical"
            value={data.mechanical}
            type="number"
            onChange={handleInputChange}
          />
          <TextField
            label="Electrical"
            margin="dense"
            onKeyPress={handleKeyPress}
            variant="standard"
            name="electrical"
            value={data.electrical}
            type="number"
            onChange={handleInputChange}
          />
          <TextField
            label="No Demand"
            margin="dense"
            onKeyPress={handleKeyPress}
            variant="standard"
            name="noDemand"
            value={data.noDemand}
            type="number"
            onChange={handleInputChange}
          />{" "}
        </div>
        <div className="ConsumptionContainer">
          <TextField
            label="No Truck"
            margin="dense"
            onKeyPress={handleKeyPress}
            variant="standard"
            name="noTruck"
            value={data.noTruck}
            type="number"
            onChange={handleInputChange}
          />
          <TextField
            label="No Product"
            margin="dense"
            onKeyPress={handleKeyPress}
            variant="standard"
            name="noProduct"
            value={data.noProduct}
            type="number"
            onChange={handleInputChange}
          />
          <TextField
            label="No Fit Cylinders"
            margin="dense"
            onKeyPress={handleKeyPress}
            variant="standard"
            name="noFitCylinders"
            value={data.noFitCylinders}
            type="number"
            onChange={handleInputChange}
          />
          <TextField
            label="Others"
            name="others"
            margin="dense"
            onKeyPress={handleKeyPress}
            variant="standard"
            value={data.others}
            type="number"
            onChange={handleInputChange}
          />
        </div>
        <div className="ConsumptionContainer">
          <TextField
            label="Specify in words if Others"
            name="specifyInWordsIfOthers"
            margin="dense"
            onKeyPress={handleKeyPress}
            variant="standard"
            value={data.specifyInWordsIfOthers}
            type="text"
            onChange={handleInputChange}
          />
        </div>

        <div className="SubmitInp">
          <Button onClick={preview} variant="outlined">
            Preview Data
          </Button>
        </div>
      </form>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {Object.keys(previewData).map((key) => (
                  <TableRow
                    key={key}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {toTitleCase(key)}
                    </TableCell>
                    <TableCell align="right">{previewData[key]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button variant="contained" color="warning" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="info" onClick={Submit}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alert.serverity}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {alert.message}
        </Alert>
      </Snackbar>

      {/* type two maintainance */}

      <div className="heading">Maintainance type two</div>
      <Container maxWidth="xs">
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            {inputFields.map((item, index) => (
              <div key={index}>
                <InputRow
                  inputFields={inputFields}
                  index={index}
                  item={item}
                  handleChange={handleChange}
                  handleRemove={handleRemove}
                  handleAdd={handleAdd}
                />
                <Divider style={{ marginBottom: 10 }} />
              </div>
            ))}

            <div style={{ display: "flex", justifyContent: "space-around" }}>
              {" "}
              <Button
                type="submit"
                variant="contained"
                disableElevation
                onClick={SubmitTypeTwo}
              >
                Insert Data
              </Button>
              <Button variant="contained" disableElevation>
                Cancel
              </Button>
            </div>
          </Stack>
        </form>
      </Container>
      {/* <Link to="/maintenanceUpd" >maintenanceUpd</Link> */}
    </>
  );
}
