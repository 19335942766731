import React from "react";
import { useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import BasicDatePicker from "./Elements/datePicker";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Navbar from "../Navbar";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import { isSuperAdmin } from "../../utils/utils";

function PowerConsumption() {
  var myHeaders = new Headers({ authorization: process.env.REACT_APP_API_KEY });
  myHeaders.append("Content-Type", "application/json");
  const categories = [
    "14.2 kg",
    "19kg",
    "35kg",
    "47.5kg",
    "425kg",
    "5kg red",
    "5kg blue",
    "2kg",
  ];
  const [time, setTime] = useState("");
  const [alert, setAlert] = useState({
    open: false,
    message: "ola",
    serverity: "success",
  });

  function handleKeyPress(event) {
    if (event.key === "-" || event.key === "+") {
      event.preventDefault(); // prevent the minus symbol from being entered
    }
  }
  const handleAlertClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };
  const currDate = new Date();
  const beforeDateStr = currDate.toISOString().slice(0, 10);
  // console.log(`${beforeDateStr}T00:00:00.000Z`);

  const [data, setData] = useState({
    date: `${beforeDateStr}T00:00:00.000Z`,
    TIME: "",
    PF: 0,
    KWH: 0,
    KVAH: 0,
    R_voltage_Rv: 0,
    R_voltage_RA: 0,
    Y_voltage_yv: 0,
    Y_voltage_yA: 0,
    B_voltage_BV: 0,
    B_voltage_BA: 0,
    KVAH_Day: 0,
    KVAH_Night: 0,
    Remarks: "",
    Signature: "",
  });

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({
      ...data,
      [name]: value,
    });
    // console.log(e.target.value);
  };

  const setDate = (e) => {
    setData({
      ...data,
      date: new Date(e).toISOString(),
    });
  };

  // data insertion is from here...
  const Submit = async (e) => {
    e.preventDefault();
    // console.log("fetching");
    fetch(`${process.env.REACT_APP_BASE_API_URL}/power_consumption`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: myHeaders,
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.response) {
          setAlert({
            open: true,
            message: "Duplicate Entry",
            serverity: "warning",
          });
        } else {
          // console.log(data);
          setAlert({
            open: true,
            message: "Data Inserted Successfully",
            serverity: "success",
          });
          return data
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  return (
    <>
      <form>

        <Grid
          sx={{ px: 3, my: 2 }}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography my variant="h4" align="center">
              Power
            </Typography>
          </Grid>
          {
            isSuperAdmin() && <Grid item>
            <Button
              component={Link}
              to="/PowerConsumpUpdate"
              variant="contained"
            >
              Update Power
            </Button>
          </Grid>
          }
        </Grid>

        <div className="dateCate">
          <BasicDatePicker
            onChange={setDate}
            label="date"
            name="date"
            renderInput={(params) => <TextField {...params} />}
            value={data.date}
          />
        </div>

        <div className="ConsumptionContainer">
          <TextField
            label="Power Factor(PF)"
            margin="dense"
            onKeyPress={handleKeyPress}
            variant="standard"
            name="PF"
            value={data.PF}
            type="number"
            onChange={handleInputChange}
          />
          <TextField
            label="KVAH"
            margin="dense"
            onKeyPress={handleKeyPress}
            variant="standard"
            name="KVAH"
            value={data.KVAH}
            type="number"
            onChange={handleInputChange}
          />
          <TextField
            label="R voltage RV"
            margin="dense"
            onKeyPress={handleKeyPress}
            variant="standard"
            name="R_voltage_Rv"
            value={data.R_voltage_Rv}
            type="number"
            onChange={handleInputChange}
          />{" "}
          <TextField
            label="R Voltage RA"
            margin="dense"
            onKeyPress={handleKeyPress}
            variant="standard"
            name="R_voltage_RA"
            value={data.R_voltage_RA}
            type="number"
            onChange={handleInputChange}
          />
        </div>
        <div className="ConsumptionContainer">
          <TextField
            label="Y Voltage YV"
            margin="dense"
            onKeyPress={handleKeyPress}
            variant="standard"
            name="Y_voltage_yv"
            value={data.Y_voltage_yv}
            type="number"
            onChange={handleInputChange}
          />
          <TextField
            label="Y Voltage YA"
            margin="dense"
            onKeyPress={handleKeyPress}
            variant="standard"
            name="Y_voltage_yA"
            value={data.Y_voltage_yA}
            type="number"
            onChange={handleInputChange}
          />
          <TextField
            label="B Voltage BA"
            name="B_voltage_BA"
            margin="dense"
            onKeyPress={handleKeyPress}
            variant="standard"
            value={data.B_voltage_BA}
            type="number"
            onChange={handleInputChange}
          />
          <TextField
            label="B Voltage BV"
            name="B_voltage_BV"
            margin="dense"
            onKeyPress={handleKeyPress}
            variant="standard"
            value={data.B_voltage_BV}
            type="number"
            onChange={handleInputChange}
          />
        </div>
        <div className="ConsumptionContainer">
          <TextField
            label="KVAH Day"
            margin="dense"
            onKeyPress={handleKeyPress}
            variant="standard"
            name="KVAH_Day"
            value={data.KVAH_Day}
            type="number"
            onChange={handleInputChange}
          />
          <TextField
            label="KVAH Night"
            margin="dense"
            onKeyPress={handleKeyPress}
            variant="standard"
            name="KVAH_Night"
            value={data.KVAH_Night}
            type="number"
            onChange={handleInputChange}
          />
          <TextField
            label="Remarks"
            name="Remarks"
            margin="dense"
            onKeyPress={handleKeyPress}
            variant="standard"
            value={data.Remarks}
            type="text"
            onChange={handleInputChange}
          />
          <TextField
            label="Signature"
            name="Signature"
            margin="dense"
            onKeyPress={handleKeyPress}
            variant="standard"
            value={data.Signature}
            type="text"
            onChange={handleInputChange}
          />
        </div>
        <div className="SubmitInp">
          <Button onClick={Submit} variant="contained">
            Submit
          </Button>
        </div>
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={alert.serverity}
            sx={{ width: "100%" }}
            variant="filled"
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </form>
    </>
  );
}

export default PowerConsumption;
