import React, { useEffect, useState } from "react";
import "../../styling/uploadFormLicense.css";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import LicenseDatePicker from "./Elements/licenseDatePicker";
import { addDays } from "date-fns";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";

function UploadFormlicese() {
  var myHeaders = new Headers({ authorization: process.env.REACT_APP_API_KEY });
  myHeaders.append("Content-Type", "application/json");
  // setting up of alerts from here ..
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "ola",
    serverity: "success",
  });

  const handleAlertClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };


  const currDate = new Date();
  const beforeDateStr = currDate.toISOString().slice(0, 10);

  const [selectedFile, setSelectedFile] = useState({
    document_name: "",
    file: "",
  });

  const [document_start_date, setdocument_start_date] = useState(
    `${beforeDateStr}T00:00:00.000Z`
  );
  const [document_end_date, setdocument_end_date] = useState(
    `${beforeDateStr}T00:00:00.000Z`
  );

  const setStartdate = (e) => {
    setdocument_start_date(new Date(e).toISOString());
  };

  const changeEndDate = (e) => {
    setdocument_end_date(new Date(e).toISOString());
  };

  const handleLicenseChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setSelectedFile((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleKey = (e) => {
    // if you dont wanna enter any special character .. 
    
    if (e.key === "'") {
      e.preventDefault();
      // window.alert("not allowed");
      setAlert({
        message: "special Characters ` ' `  not allowed use ` - ` instead",
        open: true,
        serverity: "error",
      });
    }
  };

  const submitLicense = async (e) => {
    e.preventDefault();

    // console.log(selectedFile.file === '');

    if (selectedFile.file === "") {
      setAlert({
        open: true,
        serverity: "error",
        message: "Choose a File. File Not Found",
      });
    } else if (selectedFile.document_name.length === 0) {
      setAlert({
        open: true,
        message: "License Name Cannot be Empty",
        serverity: "error",
      });
    } else if (document_start_date === document_end_date) {
      setAlert({
        open: true,
        message: "License Start Date & End Date Cannot be Same .",
        serverity: "error",
      });
    } else if (document_start_date > document_end_date) {
      setAlert({
        open: true,
        message: "License Dates Are Not Valid .",
        serverity: "error",
      });
    } else {
      e.target.textContent = "sending...";
      var formdata = new FormData();
      formdata.append("document_name", selectedFile.document_name);
      formdata.append("document_start_date", document_start_date);
      formdata.append("document_end_date", document_end_date);
      formdata.append("file", selectedFile.file);


      fetch(`${process.env.REACT_APP_BASE_API_URL}/license`, {
        method: "PUT",
        body: formdata,
        // headers: {
        //   "Content-Type": "multipart/form-data",
        // },
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          // console.log(selectedFile.document_name.length);

          if (data.message === "Created successfully") {
            setSelectedFile({
              document_name: "",
              file: "",
            });
            document.getElementById("profile-picture-input").value = null; // or undefined
            setdocument_start_date(`${beforeDateStr}T00:00:00.000Z`);
            setdocument_end_date(`${beforeDateStr}T00:00:00.000Z`);
            setAlert({
              open: true,
              message: "License Uploaded SuccessFully.",
              serverity: "success",
            });
            e.target.textContent = "Sent";//why two variables simulataneously, need time gap to reflect change, async time variance should be used
            e.target.textContent = "Submit";
          } else if (data.err) {
            setAlert({
              open: true,
              message: "ERROR! License Not Uploaded. Reload and try again.",
              serverity: "error",
            });
          } else if (data.errors[0].code === "ER_DUP_ENTRY") {
            setAlert({
              open: true,
              message: "License name Used. Try using another name .",
              serverity: "error",
            });
          } else {
            setAlert({
              open: true,
              message: "error occured while uploading",
              serverity: "error",
            });
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };

  const handleProfileImageUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setSelectedFile({
      ...selectedFile,
      file,
    });
  };

  return (
    <>
      <section className="upload-form-license">
        <div className="heading">
          <h1>Upload License</h1>
        </div>

        <div className="license-input-feilds">
          <div className="row">
            <TextField
              margin="dense"
              variant="standard"
              name="document_name"
              type="text"
              label="License Name"
              className="license-input  license-value"
              value={selectedFile.document_name}
              onChange={handleLicenseChange}
              onKeyDown={handleKey}
            />

            <LicenseDatePicker
              onChange={setStartdate}
              label="License Start Date"
              className="license-input-date license-value"
              name="startDate"
              renderInput={(params) => <TextField {...params} />}
              value={document_start_date}
              minDate={new Date()} // Set the minimum date to today's date
              maxDate={addDays(new Date(), 365)} // Set the maximum date to one year from today
            />
          </div>

          <div className="row">
            <TextField
              name="file-upload"
              id="profile-picture-input"
              accept=".png , .svg , .jpeg , .jpg"
              InputProps={{ accept: ".png, .svg, .jpeg, .jpg" }}
              onChange={handleProfileImageUpload}
              type="file"
              variant="outlined"
              className="license-value"
              inputProps={{ accept: ".png, .svg, .jpeg, .jpg" }}
            />

            <LicenseDatePicker
              onChange={changeEndDate}
              label="License End Date"
              className="license-input-date license-value endDate"
              name="document_end_date"
              renderInput={(params) => <TextField {...params} />}
              value={document_end_date}
            />
          </div>
        </div>

        <div className="license-submit">
          <button
            className="button"
            onClick={(e) => {
              submitLicense(e);
            }}
          >
            Submit
          </button>
        </div>
      </section>

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alert.serverity}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default UploadFormlicese;
