import React from "react";
import { Grid, TextField } from "@mui/material";
// import InputFiled from './InputField';

function InputField(props) {
  return (
    <Grid item xs={8} md={3} lg={3}>
      <TextField
      sx={{width : '80%'}}
        width="70"
        margin="dense"
        type="number"
        label={props.label}
        value={props.value}
        // required
        variant="standard"
        onChange={props.inputChange}
        onKeyPress={props.keyPress}
        name={props.name}
        disabled={props.disabled}
      />
    </Grid>
  );
}

export default InputField;
