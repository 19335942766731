import {
  Grid,
  Box,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { toTitleCase } from "../../utils/utils";
import BasicDatePicker from "../Forms/Elements/datePicker";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

function CategoriesPreview() {
  var myHeaders = new Headers({ authorization: process.env.REACT_APP_API_KEY });
  myHeaders.append("Content-Type", "application/json");
  //   const date = props.date;
  const currDate = new Date();
  let currDateIso = currDate.toISOString().slice(0, 10);

  const [alert, setAlert] = useState({
    open: false,
    message: "ola",
    serverity: "success",
  });
  const handleAlertClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const [date, setDate] = useState({
    Timing: new Date().toISOString().slice(0,10),
  });

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/consumables/ConsumablesLastUpdDate`,
      {
        method: "POST",
        body: JSON.stringify({
          date: currDateIso,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log("last update date");
        let value = data.response[0].previous_last_updated_date + "";
        // setLastUpdDate(value);
        // console.log(data.response[0].previous_last_updated_date);
        setDate({
          ...data,
          Timing: value,
        });
      })
      .catch((err) => {
        setAlert({
          open: true,
          message: "Error while fetching data",
          serverity: "error",
        });
        // console.log(err);
      });
  }, []);

  const [category, setCategory] = useState("14.2 kg");
  let dataObject = {
    date: "2022-01-01T00:00:00.000Z",
    newValvesReceiptThroughTranshipment: 0,
    newValvesReceiptThroughParty: 0,
    defectiveValvesDespatchedToAnantapur: 0,
    closingGoodValves: 0,
    closingDefectiveValves: 0,
    newRegulatorsReceiptThroughTranshipment: 0,
    newRegulatorsReceiptThroughParty: 0,
    regulatorsIssuedToDistributors: 0,
    defectiveRegulatorsReceiptFromDistributors: 0,
    closingGoodRegulators: 0,
    closingDefectiveRegulators: 0,
    defectiveRegulatorsDespatchedToAnantapur: 0,
    oringsReceipt: 0,
    pvcSealReceipt: 0,
    scCapsReceipt: 0,
    oringsConsumption: 0,
    pvcSealConsumption: 0,
    scCapsConsumption: 0,
    closingOrings: 0,
    closingPvcSeal: 0,
    closingScCaps: 0,
  };

  const [data, setdata] = useState(dataObject);
  const DateChange = (e) => {
    setDate({
      ...date,
      Timing: new Date(e).toISOString(),
    });
  };
  // fetching categories data
  const fetchFilling = () => {
    // myHeaders.append("Content-Type", "application/json");
    var options = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        date: date.Timing.slice(0, 10),
        category: category,
      }),
    };
    fetch(`${process.env.REACT_APP_BASE_API_URL}/consumables`, options)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.response[0]) {
          let sorted = Object.fromEntries(
            Object.entries(result.response[0]).sort((b, a) => a[1] - b[1])
          );
          setdata(sorted);
        } else {
          setAlert({
            open: true,
            message: "no data found",
            serverity: "error",
          });
          setdata(dataObject);
        }
        // console.log(result.response[0],category)
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };
  useEffect(fetchFilling, [date]);
  // fetchFilling();
  return (
    <>
      <Box sx={{ py: 2, px: 2 }}>
        <Grid container sx={{ py: 3 }}>
          <Grid>
            <BasicDatePicker
              onChange={DateChange}
              label="Date"
              renderInput={(params) => <TextField {...params} />}
              value={date.Timing}
            />
          </Grid>
        </Grid>
        {
          <TableContainer component={Paper} sx={{ px: 2, py: 2 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                <TableRow
                  key="closingPvcSeal"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Closing Pvc Seal
                  </TableCell>
                  <TableCell align="right">{data.closingPvcSeal} nos</TableCell>
                </TableRow>
                <TableRow
                  key="pvcSealConsumption"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Pvc Seal Consumption
                  </TableCell>
                  <TableCell align="right">
                    {data.pvcSealConsumption} nos
                  </TableCell>
                </TableRow>
                <TableRow
                  key="closingGoodValves"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Closing Good Valves
                  </TableCell>
                  <TableCell align="right">
                    {data.closingGoodValves} nos
                  </TableCell>
                </TableRow>
                <TableRow
                  key="closingScCaps"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Closing Sc Caps
                  </TableCell>
                  <TableCell align="right">{data.closingScCaps} nos</TableCell>
                </TableRow>
                <TableRow
                  key="closingOrings"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Closing Orings
                  </TableCell>
                  <TableCell align="right">{data.closingOrings} nos</TableCell>
                </TableRow>
                <TableRow
                  key="closingGoodRegulators"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Closing Good Regulators
                  </TableCell>
                  <TableCell align="right">
                    {data.closingGoodRegulators} nos
                  </TableCell>
                </TableRow>
                <TableRow
                  key="scCapsConsumption"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Sc Caps Consumption
                  </TableCell>
                  <TableCell align="right">
                    {data.scCapsConsumption} nos
                  </TableCell>
                </TableRow>
                <TableRow
                  key="closingDefectiveValves"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Closing Defective Valves
                  </TableCell>
                  <TableCell align="right">
                    {data.closingDefectiveValves} nos
                  </TableCell>
                </TableRow>
                <TableRow
                  key="newValvesReceiptThroughTranshipment"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    New Valves Receipt Through Transhipment
                  </TableCell>
                  <TableCell align="right">
                    {data.newValvesReceiptThroughTranshipment} nos
                  </TableCell>
                </TableRow>
                <TableRow
                  key="newValvesReceiptThroughParty"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    New Valves Receipt Through Party
                  </TableCell>
                  <TableCell align="right">
                    {data.newValvesReceiptThroughParty} nos
                  </TableCell>
                </TableRow>
                <TableRow
                  key="defectiveValvesDespatchedToAnantapur"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Defective Valves Despatched To Anantapur
                  </TableCell>
                  <TableCell align="right">
                    {data.defectiveValvesDespatchedToAnantapur} nos
                  </TableCell>
                </TableRow>
                <TableRow
                  key="newRegulatorsReceiptThroughTranshipment"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    New Regulators Receipt Through Transhipment
                  </TableCell>
                  <TableCell align="right">
                    {data.newRegulatorsReceiptThroughTranshipment} nos
                  </TableCell>
                </TableRow>
                <TableRow
                  key="newRegulatorsReceiptThroughParty"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    New Regulators Receipt Through Party
                  </TableCell>
                  <TableCell align="right">
                    {data.newRegulatorsReceiptThroughParty} nos
                  </TableCell>
                </TableRow>
                <TableRow
                  key="regulatorsIssuedToDistributors"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Regulators Issued To Distributors
                  </TableCell>
                  <TableCell align="right">
                    {data.regulatorsIssuedToDistributors} nos
                  </TableCell>
                </TableRow>
                <TableRow
                  key="defectiveRegulatorsReceiptFromDistributors"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Defective Regulators Receipt From Distributors
                  </TableCell>
                  <TableCell align="right">
                    {data.defectiveRegulatorsReceiptFromDistributors} nos
                  </TableCell>
                </TableRow>
                <TableRow
                  key="closingDefectiveRegulators"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Closing Defective Regulators
                  </TableCell>
                  <TableCell align="right">
                    {data.closingDefectiveRegulators} nos
                  </TableCell>
                </TableRow>
                <TableRow
                  key="defectiveRegulatorsDespatchedToAnantapur"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Defective Regulators Despatched To Anantapur
                  </TableCell>
                  <TableCell align="right">
                    {data.defectiveRegulatorsDespatchedToAnantapur} nos
                  </TableCell>
                </TableRow>
                <TableRow
                  key="oringsReceipt"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Orings Receipt
                  </TableCell>
                  <TableCell align="right">{data.oringsReceipt} nos</TableCell>
                </TableRow>
                <TableRow
                  key="pvcSealReceipt"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Pvc Seal Receipt
                  </TableCell>
                  <TableCell align="right">{data.pvcSealReceipt} nos</TableCell>
                </TableRow>
                <TableRow
                  key="scCapsReceipt"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Sc Caps Receipt
                  </TableCell>
                  <TableCell align="right">{data.scCapsReceipt} nos</TableCell>
                </TableRow>
                <TableRow
                  key="oringsConsumption"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Orings Consumption
                  </TableCell>
                  <TableCell align="right">
                    {data.oringsConsumption} nos
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        }
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={alert.serverity}
            sx={{ width: "100%" }}
            variant="filled"
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
}

export default CategoriesPreview;
