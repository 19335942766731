import { React, useState } from "react";
import {
  Grid,
  MenuItem,
  InputLabel,
  Select,
  Box,
  FormControl,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
// import InputField from "./Elements/InputField";
import { toTitleCase } from "../../utils/utils";
import BasicDatePicker from "../Forms/Elements/datePicker";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import energonImg from "../../Images/logo.png";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputField from "../Forms/Elements/InputField";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
function FillingUpdate() {
  var myHeaders = new Headers({"authorization":
      process.env.REACT_APP_API_KEY})
  myHeaders.append("Content-Type", "application/json");
  const categories = [
    "14.2 kg",
    "19kg",
    "35kg",
    "47.5kg",
    "425kg",
    "5kg red",
    "5kg blue",
    "2kg",
  ];
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    background: "#fff",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
    height: "80%",
    padding: "2rem",
    borderRadius: ".75rem",
  };
  // pop up modal here... for preview
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "ola",
    serverity: "success",
  });
  const handleAlertClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };
  const [data, setData] = useState({
    Timing: "2021-08-18T00:00:00.000Z",
    CATEGORY: "14.2 kg",
    atpDespBodyLeak: 0,
    atpDespColdRepair: 0,
    atpDespDamaged: 0,
    atpDespDamagedSpuriousOmc: 0,
    atpDespDpt: 0,
    atpDespHotRepair: 0,
    atpDespNeckLeak: 0,
    atpDespOmc: 0,
    atpDespScrap: 0,
    atpDespSpurious: 0,
    closingBodyLeak: 0,
    closingColdRepair: 0,
    closingDamaged: 0,
    closingDamagedSpuriousOmc: 0,
    closingDpt: 0,
    closingGoodEmptyStockOf: 0,
    closingHotRepair: 0,
    closingInventoryOf: 0,
    closingNeckLeak: 0,
    closingOmc: 0,
    closingPinLeak: 0,
    closingScrap: 0,
    closingSpurious: 0,
    closingStockFulls: 0,
    cylindersReceivedFromDistributor: 0,
    cylindersThroughTranshipmentReceipt: 0,
    damagedSpuriousOmc: 0,
    dayDespatches: 0,
    dayProduction: 0,
    generatedBodyLeak: 0,
    generatedColdRepair: 0,
    generatedDpt: 0,
    generatedHotRepair: 0,
    generatedNeckLeak: 0,
    generatedPinLeak: 0,
    newEmptyCylindersReceipt: 0,
    noOfSqc: 0,
    prcnIssuedUpdateOnlyFullPrcn: 0,
    prcnIssuedUpdatePartPrcnQty: 0,
    receivedDamaged: 0,
    receivedOmc: 0,
    receivedScrap: 0,
    receivedSpurious: 0,
    rejectedCylsInSqc: 0,
    sqcInTermsOfCylinders: 0,
    valveChanging: 0,
  });
  const [previewData, setPreviewData] = useState({});

  const PreviewCate = async (e) => {
    e.preventDefault();
    var requestOptions = {
      method: "POST",
      body: JSON.stringify(data),
      headers: myHeaders,
    };
    try {
      const result = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/filling/preview`,
        requestOptions
      ).then((result) => result.json());
      if (result.result) {
        setPreviewData({
          ...result.result,
        });
        handleOpen();
        // console.log(result);
      } else {
        setAlert({
          open: true,
          message: "preview not found",
          serverity: "warning",
        });
      }
    } catch (err) {
      // console.log(err);
      setAlert({
        open: true,
        message: "Error Occured while generating preview",
        serverity: "error",
      });
    }
    // setPreviewData(data);
    // handleOpen();
  };
  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({
      ...data,
      [name]: value,
    });
    // console.log(e.target.value);
  };
  const FetchValues = async (e) => {
    e.preventDefault();
    await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/filling`,
      {
        method: "POST",
        body: JSON.stringify({
          date: data.Timing,
          category: data.CATEGORY,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        if (data.response.length) {
          setAlert({
            open: true,
            message: "Data fetched successfully",
            serverity: "success",
          });
          let result = data.response[0];
          setData(result);
          // console.log(result);
          // setInputField(result);
        } else {
          setAlert({
            open: true,
            message: "Data not found",
            serverity: "warning",
          });
        }
      })
      .catch((err) => {
        setAlert({
          open: true,
          message: "Error while fetching data",
          serverity: "error",
        });
        // console.log(err);
      });
  };

  const UpdateCategory = async (e) => {
    e.preventDefault();
    const update = await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/filling`,
      {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: myHeaders,
      }
    )
      .then((res) => {
        if (res.status === 500) {
          setAlert({
            open: true,
            message: "Unable to update data",
            serverity: "warning",
          });
        } else {
          return res.json();
        }
      })
      .then((data) => {
        if (data) {
          if( data.affectedRows > 1){
            setAlert({
              open: true,
              message: "Data updated successfully",
              serverity: "success",
            });
          }else{
            setAlert({
              open: true,
              message: "Unable to update data",
              serverity: "warning",
            })
          }
          
          handleClose();
          // console.log(data);
        }
      })
      .catch((err) => {
        setAlert({
          open: true,
          message: "Unable to update data. Try again!",
          serverity: "error",
        });
      });
    // console.log(update);
    // window.alert("updated")
  };
  const setDate = (e) => {
    setData({
      ...data,
      Timing: new Date(e).toISOString(),
    });
  };
  return (
    <>
      <div className="updateNavbar">
        <div className="box">
          <Link to="/forms" title="Go Back">
            <ArrowBackIcon className="backIcon" />
          </Link>
        </div>
        <div className="box">
          <img src={energonImg} alt="" />
          {/* <h1>Energon</h1> */}
        </div>
      </div>
      <div className="heading">
        <p>Update Filling</p>
      </div>
      {/* bellow getting the details for update... */}
      <form className="UpdateContainer" onSubmit={FetchValues}>
        <Grid container flexDirection="column" alignItems="center">
          <div className="dateCate">
            <BasicDatePicker
              onChange={setDate}
              label="Date"
              renderInput={(params) => <TextField {...params} />}
              value={data.Timing}
            />
            <Box sx={{ minWidth: 300, px: 2 }}>
              <FormControl fullWidth>
                <InputLabel>Category</InputLabel>
                <Select
                  value={data.CATEGORY}
                  label="Category"
                  onChange={handleInputChange}
                  name="CATEGORY"
                  dis
                >
                  {categories.map((category) => {
                    return (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Grid item>
              <Button variant="contained" type="submit">
                Fetch Details
              </Button>
            </Grid>
          </div>
        </Grid>
      </form>

      <form>
        <div className="dateCate"></div>

        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="center"
          textAlign="center"
          justifyContent="space-around"
        >
          {Object.keys(data).map((key) => {
            var disabled = false;
            if (key === "CATEGORY" || key === "Timing") return "";
            return (
              <InputField
                label={toTitleCase(key)}
                value={data[key]}
                inputChange={handleInputChange}
                name={key}
                disabled={disabled}
              />
            );
          })}
        </Grid>
        <Grid justifyContent="center" container sx={{ my: 2 }}>
          <Button variant="outlined" onClick={PreviewCate}>
            Preview
          </Button>
        </Grid>
      </form>

      {/* pop up modal here... */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={style}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {Object.keys(previewData).map((key) => (
                  <TableRow
                    key={key}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {toTitleCase(key)}
                    </TableCell>
                    <TableCell align="right">{data[key]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container sx={{ my: 2 }} spacing={2} justifyContent="center">
            <Grid item>
              <Button variant="outlined" color="warning" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="success"
                onClick={UpdateCategory}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alert.serverity}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default FillingUpdate;
