import { Box, FormControl, InputLabel, MenuItem, Select,Alert,Snackbar } from "@mui/material";
import React, { useState } from "react";
import { Bar, Pie } from "react-chartjs-2";

function OthersGph() {
  var myHeaders = new Headers({"authorization":
      process.env.REACT_APP_API_KEY})
  myHeaders.append("Content-Type", "application/json");

  var BulkReceiveMTArr = [];
  let TotalDefectiveArr = [];
  let powerConsumptionArr = [];
  const [mode, setMode] = useState("Monthly");
  const [year, setYear] = useState("2021");
  const [month, setMonth] = useState("1");
  const [bulkReceive, setBulkReceived] = useState(BulkReceiveMTArr);
  const [TotalDefecValue, setTotalDefecValue] = useState(TotalDefectiveArr);
  const [totalDefectiveAll, setTotalDefectiveAll] = useState([]);
  const [PowerConsumptionMT, setPowerConsumptionMT] =
    useState(powerConsumptionArr);
    const [alert, setAlert] = useState({
      open: false,
      message: "ola",
      serverity: "success",
    });
    const handleAlertClose = () => {
      setAlert({
        ...alert,
        open: false,
      });
    };
  
  

  function getColors(n) {
    var colors = ["#FE5010", "#771AA7"];
    var hue = 16;
    var saturation = 95;
    var lightness = 50;
    for (var i = 0; i < n; i++) {
      colors.push(`hsl(${hue},${saturation}%,${lightness}%)`);
      hue += 16; //difference between two colors
      if (hue >= 360) {
        hue = 360 - hue;
      }
    }
    // console.log(colors);
    return colors;
  }
  const HandleYearChange = (e) => {
    setYear(e.target.value);
  };

  const HandleMonthChange = (e) => {
    setMonth(e.target.value);
  };
  const handleModeChange = (e) => {
    setMode(e.target.value);
  };
  const categories = [
    `14.2 kg`,
    `19kg`,
    `2kg blue`,
    `35kg`,
    `425kg`,
    `47.5kg`,
    `5kg blue`,
    `5kg red`,
  ];
  const Pieoptions = {
    plugins: {
      tooltip: {
        callbacks: {
          afterBody: function (context) {
            let finalStr = ``;
            Object.keys(totalDefectiveAll[context[0].dataIndex]).forEach(
              (key) => {
                if (key === "totalValue" || key === "CATEGORY") return;
                finalStr += `\n${key} : ${
                  totalDefectiveAll[context[0].dataIndex][key]
                }`;
              }
            );
            return finalStr;
          },
          label: function (context) {
            return `Total : ${context.parsed}`;
          },
        },
      },
      legend: true,
    },
  };
  const closingStockData = {
    labels: categories,
    datasets: [
      {
        data: TotalDefecValue,
        backgroundColor: getColors(9),
      },
    ],
  };

  const Months = [
    "January",
    "Febuary",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September", 
    "October",
    "November",
    "December",
  ];
  const MTonParticularMonth = [];
  const [TotalMT, setTotalMT] = useState(MTonParticularMonth);
  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          title: function (context) {
            return `${context[0].label} MT : ${parseFloat(
              TotalMT[context[0].dataIndex]
            ).toFixed(2)}`;
          },
        },
      },
      legend: true,
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Months",
          color: "black",
          font: {
            weight: 700,
            size : '13.5vw'
          },
        },
      },
      y: {
        display: true,
        type: "logarithmic",
        title: {
          display: true,
          text: "MT (in metric tonnes)",
          color: "black",
          font: {
            weight: 700,
            size : '13.5vw'
          },
        },
      },
    },
  };
  const powerOptions = {
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Categories",
          color: "black",
          font: {
            weight: 700,
            size : '13vw'
          },
        },
      },
      y: {
        display: true,
        type: "logarithmic",
        title: {
          display: true,
          text: "In KWH",
          color: "black",
          font: {
            weight: 700,
            size : '13vw'
          },
        },
      },
    },
  };
  const FetchValues = async (e) => {
    e.preventDefault();
    await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/bulkGraph/BulkReceivedMT`,
      {
        method: "POST",
        body: JSON.stringify({
          year: year,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        let arr = data.results;
        // console.log(arr);

        for (var i = 0; i < 12; i++) {
          BulkReceiveMTArr[i] = null;
        }
        arr.forEach((el) => {
          BulkReceiveMTArr[el.month - 1] = el.BulkReceived;
        });
        setBulkReceived(BulkReceiveMTArr);
      }).catch(err=>{
        setAlert({
          open : true,
          message : 'Error while fetching data',
          serverity : 'error'
        })
      });

    // power consumption graph
    await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/bulkGraph/PreviousMonthKWH`,
      {
        method: "POST",
        body: JSON.stringify({
          year: year,
          month: month,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        let power = data.results;
        // console.log(power)
        if (!power[0].MaxKWH){
          setAlert({
            open: true,
            message: "power consumed data not found",
            serverity: "error",
          });
        }
        fetch(
          `${process.env.REACT_APP_BASE_API_URL}/bulkGraph/BulkReceivedTotalMT`,
          {
            method: "POST",
            body: JSON.stringify({
              year: year,
            }),
            headers: myHeaders,
          }
        )
          .then((res) => res.json())
          .then((data) => {
            // console.log(data.results);

            let arr = data.results;
            // console.log(arr)
            // console.log(arr);

            // getting total metric tone
            const sameMonthvalue = arr.filter((data) => {
              return data.monthValue == month;
            });
            // console.log(sameMonthvalue,month);

            let TotalMTValue = 0;
            sameMonthvalue.forEach((month) => {
              TotalMTValue += month.MT;
              // console.log(month)
            });
            // console.log(TotalMTValue);

            let categoryWise = arr.filter((data) => {
              return data.monthValue == month;
            });
            // console.log(categoryWise);
            // console.log(power[0].MaxKWH, month, year);
            let PowerConsumedCalValue = categoryWise.map((data) => {
              return (data.MT * power[0].MaxKWH) / TotalMTValue;
            });
            // console.log(PowerConsumedCalValue)
            if(PowerConsumedCalValue.length === 0){
              // window.alert("no Power Consumed data")
              setAlert({
                open: true,
                message: "Data not found",
                serverity: "error",
              });
              setPowerConsumptionMT([0,0,0,0,0,0,0,])
            }else{
              setPowerConsumptionMT(PowerConsumedCalValue); // value is over here
            }
          }).catch((err)=>{
            window.alert("error occured in fetching.")
            setAlert({
              open: true,
              serverity: "warning",
              message: "Unable to fetch data",
            });
          })
      });

    await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/bulkGraph/BulkReceivedTotalMT`,
      {
        method: "POST",
        body: JSON.stringify({
          year: year,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data.results);
        let arr = data.results;
        //  console.log(categoryWise)

        // const sumSimilar = arr => {
        const sumArr = [];
        for (let i = 0; i < arr.length; i++) {
          const ind = sumArr.findIndex(
            (el) => el.monthValue === arr[i].monthValue
          );
          if (ind === -1) {
            sumArr.push(arr[i]);
          } else {
            sumArr[ind].MT += arr[i].MT;
          }
          // };
          // return res;
        }
        // console.log(sumArr)
        // console.log(sumArr);

        let FinalTotalMT = [];

        for (var i = 0; i < 12; i++) {
          FinalTotalMT[i] = null;
        }

        sumArr.forEach((el) => {
          FinalTotalMT[el.monthValue - 1] = el.MT;
        });
        setTotalMT(FinalTotalMT);
        return data;
      });

    // Total Defective values
    await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/filling/TotalDefective`,
      {
        method: "POST",
        body: JSON.stringify({
          year: year,
          month: month,
        }),
        headers: myHeaders,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data)
        // console.log(data);
        let TotalValueArr = data.response;
        // console.log(TotalValueArr)
        if(TotalValueArr.length !== 0){

          setTotalDefectiveAll(data.response);
          TotalValueArr.forEach((data) => {
            TotalDefectiveArr.push(data.totalValue);
          });
          // console.log(TotalDefectiveArr);
          setTotalDefecValue(TotalDefectiveArr);
        }else{
          setAlert({
            open: true,
            message: "no defective  data found",
            serverity: "error",
          });
                    setTotalDefecValue([0,0,0,0,0,0,0,0,0,0])
        }

        return data;
      }).catch((err)=>{
        // console.log(err)
        setAlert({
          open: true,
          message: "Unable to fetch data",
          serverity: "warning",
        });
        
      })
  };

  return (
    <>
      <header className="dateSection">
        <div className="year">
          <Box sx={{ minWidth: 300 }}>
            <FormControl fullWidth>
              <InputLabel>Mode</InputLabel>
              <Select
                value={mode}
                label="Mode"
                onChange={handleModeChange}
                name="Mode"
              >
                <MenuItem key="Monthly" value="Monthly">
                  Monthly
                </MenuItem>
                <MenuItem key="Yearly" value="Yearly">
                  Yearly
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
        <div className="year">
          <Box sx={{ minWidth: 300 }}>
            <FormControl fullWidth>
              <InputLabel>Year</InputLabel>
              <Select
                value={year}
                label="Year"
                onChange={HandleYearChange}
                name="Select Year"
              >
                <MenuItem key="2021" value="2021">
                  2021
                </MenuItem>
                <MenuItem key="2022" value="2022">
                  2022
                </MenuItem>
                <MenuItem key="2023" value="2023">
                  2023
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
        {mode === "Monthly" && (
          <div className="month">
            <Box sx={{minWidth:300}}>
              <FormControl fullWidth>
                <InputLabel>Month</InputLabel>
                <Select
                  value={month}
                  label="Month"
                  onChange={HandleMonthChange}
                  name="Select Months"
                >
                  <MenuItem key="january" value="1">
                    january
                  </MenuItem>
                  <MenuItem key="Febrauary" value="2">
                    Febrauary
                  </MenuItem>
                  <MenuItem key="March" value="3">
                    March
                  </MenuItem>{" "}
                  <MenuItem key="April" value="4">
                    April
                  </MenuItem>{" "}
                  <MenuItem key="May" value="5">
                    May
                  </MenuItem>{" "}
                  <MenuItem key="June" value="6">
                    June
                  </MenuItem>{" "}
                  <MenuItem key="July" value="7">
                    July
                  </MenuItem>{" "}
                  <MenuItem key="August" value="8">
                    August
                  </MenuItem>
                  <MenuItem key="September" value="9">
                    September
                  </MenuItem>
                  <MenuItem key="October" value="10">
                    October
                  </MenuItem>{" "}
                  <MenuItem key="November" value="11">
                    November
                  </MenuItem>{" "}
                  <MenuItem key="December" value="12">
                    December
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        )}

        <div>
          <button className="Go OthersGo"  onClick={FetchValues}>
            Go
          </button>
        </div>
      </header>
      <section className="OthersGraph">
        {mode === "Yearly" && (
          <div className="Bulk">
            {/* <div> */}
            <h1>
              Bulk Received{" "}
              <span
                style={{ fontSize: "0.9vw", fontWeight: 400, color: "gray" }}
              >
                (In Metric Tonnes)
              </span>
            </h1>
            <Bar
              data={{
                labels: Months,
                datasets: [
                  {
                    label: `Bulk Received`,
                    data: bulkReceive,
                    backgroundColor: "orange",
                  },
                ],
              }}
              options={options}
            />
            {/* </div> */}
          </div>
        )}
        {mode === "Monthly" && (
          <div className="PowerConmpMT">
            {/* <div> */}
            <h1>
              Power Consumed{" "}
              <span>
                (In KWH)
              </span>
            </h1>
            <Bar
              data={{
                labels: categories,
                datasets: [
                  {
                    label: "Power Consumed MT",
                    data: PowerConsumptionMT,
                    backgroundColor: "#771AA7",
                  },
                ],
              }}
              options={powerOptions}
            />
            {/* </div> */}
          </div>
        )}
        {mode === "Monthly" && (
          <section className="Defective">
            <div className="ClosingEmptyGood">
              <h1>Total Defective </h1>
              <Pie data={closingStockData} options={Pieoptions} />
            </div>
          </section>
        )}
      </section>
      <Snackbar
            open={alert.open}
            autoHideDuration={6000}
            onClose={handleAlertClose}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <Alert
              onClose={handleAlertClose}
              severity={alert.serverity}
              sx={{ width: "100%" }}
              variant="filled"
            >
              {alert.message}
            </Alert>
          </Snackbar>
    </>
  );
}

export default OthersGph;
